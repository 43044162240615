import React, { useEffect, useMemo, useState, useCallback } from "react";
import { observer } from "mobx-react";
import ReactModal from "react-modal";

import { IoIosPin, IoIosContact, IoIosClose, IoMdCreate } from "react-icons/io";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

import { ReactComponent as Man } from "~/resourses/man.svg";
import { ReactComponent as Woman } from "~/resourses/woman.svg";

import useStores from "~/hooks";

import { Preloader } from "~/components/common";
import ActivitiesList from "../common/ActivitiesList";
import RadarChart from "../common/RadarChart";
import Archievements from "../common/Archievements";
import Statistics from "../common/Statistics";
import Promocodes from "../common/Promocodes";
import AthleteSearch from "./AthleteSearch";
import EditPhoto from "../common/EditPhoto";

ReactModal.setAppElement("#root");

const AthleteProfile = observer(
  ({ id, kind, currentSeasonId, showModal, isSettings }) => {
    const {
      athletesStore,
      rootStore,
      accountStore,
      seasonsStore,
    } = useStores();
    const { isAthlete, profile } = accountStore;
    const { currentSeason } = seasonsStore;
    const { selectedMonth } = currentSeason;
    const { type } = rootStore;

    if (!currentSeasonId) {
      return (
        <div className="challenges page">
          <div className="page-warning">
            <IoIosClose /> Нет доступных сезонов
          </div>
        </div>
      );
    }
    const isMe = id === "me";
    const iAmNotAthlete = !isAthlete && isMe;
    const from = selectedMonth.from;
    const to = selectedMonth.to;

    const athlete = useMemo(() => {
      return athletesStore.getAthlete(
        id,
        true,
        kind,
        currentSeasonId,
        from,
        to
      );
    }, [id, kind, currentSeasonId, selectedMonth]);

    const [isModalVisible, setModalVisible] = useState(showModal === "true");
    const [isEditPhoto, setEditPhoto] = useState(false);

    const onCloseModal = useCallback(() => {
      setModalVisible(false);
    }, [setModalVisible]);

    const onCloseEdit = useCallback(() => {
      setEditPhoto(false);
    }, [setEditPhoto]);

    const onOpenModal = useCallback(() => {
      setModalVisible(true);
    }, [setModalVisible]);

    const onOpenEdit = useCallback(() => {
      setEditPhoto(true);
    }, [setEditPhoto]);

    useEffect(() => {
      if (athlete && currentSeasonId && !!from && !!to) {
        athlete.getFullInfo(kind, currentSeasonId, from, to);
      }
    }, [currentSeasonId, selectedMonth, from, to, athlete, kind]);

    const pictureUrl = useMemo(() => {
      if (isEditPhoto) {
        return "";
      }
      if (isMe) {
        return profile && profile.photo;
      }
      return athlete && athlete.photo;
    }, [athlete && athlete.photo, profile && profile.photo, isEditPhoto]);

    if (!athlete) {
      return (
        <div className="challenges page">
          <div className="page-warning">
            <IoIosClose /> Нет доступной информации
          </div>
        </div>
      );
    }

    if (athlete && athlete.isPending) {
      return <Preloader />;
    }
    let image = athlete.isPendingImage ? <Preloader /> : null;
    if (!pictureUrl) {
      if (!iAmNotAthlete) {
        image = athlete.male ? <Man /> : <Woman />;
      } else {
        image = <IoIosContact className="unknown-sex" />;
      }
    }

    const activities = athlete.getActivities(id);
    const radarData = athlete.stats;
    const activitiesForCalendar = athlete.getActivitiesForCalendar(id);

    return (
      <div
        key={athlete.time}
        className={`athlete-profile  ${type} ${
          athlete.male ? "male" : "female"
        }`}
      >
        <div className={`photo ${type} border`}>
          <div
            className="pic"
            style={{
              backgroundImage: pictureUrl && `url(${pictureUrl})`,
            }}
          >
            {image}
          </div>
          {isMe && isAthlete && (
            <div id="hover-edit-pic" onClick={onOpenEdit} className="hover-edit">
              <IoMdCreate />
            </div>
          )}
        </div>

        <div className="text">
          <div className="name">{athlete.name}</div>
          <div className="company">
            {athlete.company && athlete.company.name}
          </div>
        </div>
        {!iAmNotAthlete && athlete.address && (
          <div className="address">
            <IoIosPin />
            {athlete.address}
          </div>
        )}
        {athlete.canMatchWithAthlete && isMe && (
          <div
            onClick={onOpenModal}
            className={`button gradient athlete-linker ${type}`}
          >
            Связать аккаунт атлета
          </div>
        )}
        <Promocodes athlete={athlete} />
        {!isSettings && <div className="name">Личная статистика</div>}
        {!isSettings && <Statistics athlete={athlete} />}
        {!isSettings && <RadarChart data={radarData} />}
        {!isSettings && <div className="name">Выполненные вызовы</div>}
        {!isSettings && <Archievements athlete={athlete} />}
        {!isSettings && <div className="name">Календарь активностей</div>}
        {!isSettings && (
          <ActivitiesList
            noDistance={true}
            athleteId={id}
            challenge={athlete}
            activitiesForCalendar={activitiesForCalendar}
            activities={activities}
            isPending={athlete.isPending}
          />
        )}
        <Tooltip
          anchorId="hover-edit-pic" 
          place="bottom" 
          style={{ zIndex: 5 }}
          content="Изменить"
        />
        <ReactModal
          isOpen={isEditPhoto}
          shouldCloseOnOverlayClick={true}
          onRequestClose={onCloseEdit}
          contentLabel="Change profile photo"
        >
          <div className={`main seasons-list  photo-edit ${type}`}>
            <div className={`modal-title gradient ${type}`}>
              Изменить фото
            </div>
            <EditPhoto onClose={onCloseEdit} id={id} />
          </div>
        </ReactModal>
        <ReactModal
          isOpen={isModalVisible && athlete.canMatchWithAthlete && isMe}
          shouldCloseOnOverlayClick={true}
          onRequestClose={onCloseModal}
          contentLabel="User select modal"
        >
          <div className={`main seasons-list ${type}`}>
            <div className={`modal-title gradient ${type}`}>
              Выберите атлета для связывания
            </div>
            <div className={`athletes ${type}`}>
              <AthleteSearch closeModal={onCloseModal} />
            </div>
            <div className="buttons">
              <div className={`button gradient ${type}`} onClick={onCloseModal}>
                Закрыть
              </div>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
);

export default AthleteProfile;
