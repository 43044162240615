import { observable, action, computed, toJS } from "mobx";
import moment from "moment";

import TeamTop from "./TeamTop";
import TopItem from "./TopItem";

export default class TeamChallenge {
  @observable
  store = null;
  @observable
  rootStore = null;
  @observable
  companiesStore = null;
  @observable
  id = null;
  @observable
  name = null; // название.
  @observable
  status = null; // название.

  @observable
  penalty = null;
  @observable
  reward = null;

  @observable
  start = null;
  @observable
  finish = null;

  @observable
  isCompetition = false;

  @observable
  isChampionship = false;

  @observable
  discipline = null;

  @observable
  filters = new Map();

  @observable
  conditions = new Map();

  @observable
  seasonId = null;

  @observable
  signing = false;

  @observable
  fullTops = null;

  @observable
  tops = null;

  @observable
  pagePending = false; 
  
  @observable
  pending = false; 
  
  @observable
  annotationPending = false; 
  
  @observable
  activitiesPending = false; 
  
  @observable
  nextPage = true;

  @observable
  progressMap = new Map();

  @observable
  activitiesMap = new Map();

  @observable
  numberOfTeams = null;

  @observable
  isTeam = true;
  
  @observable
  page = 1;
  @observable
  perPage = 20;

  @observable
  position = null;

  @observable
  printablesPending = false;

  @observable
  competitorDiploma=false;
  @observable
  competitorNumber=false;
  @observable
  prizewinnerDiploma=false;

  constructor(data, store) {
    this.store = store;
    this.season = this.store.season;
    this.rootStore = this.store.rootStore;
    this.companiesStore = this.rootStore.companiesStore;
    this.api = this.rootStore.api;
    this.seasonsStore = this.rootStore.seasonsStore;

    this.id = data.id;
    this.name = data.name;
    this.start = data.start ? moment(data.start, "YYYY-MM-DD HH:mm:ss").local()
      .utc(true) : null;
    this.finish = data.finish ? moment(data.finish, "YYYY-MM-DD HH:mm:ss").local()
      .utc(true) : null;
    this.discipline = data.discipline;
    this.status = (data.registration && data.registration.status) || null;
    this.seasonId = data.seasonId;
    this.penalty = data.penalty || null;
    this.reward = data.reward || null;
    this.numberOfTeams = data.numberOfTeams;
    this.isCompetition = data.isCompetition || false;
    this.isChampionship = data.isChampionship || false;
    this.position = data.position || 0;
    
    if (data.rules) {
      const { male, female } = data.rules;
      if (male) {
        const { conditions, filters } = male;
        this.conditions.set("males", conditions);
        this.filters.set("males", filters);
      }
      if (female) {
        const { conditions, filters } = female;
        this.conditions.set("females", conditions);
        this.filters.set("females", filters);
      }
    }
  }


  @action
  async getPrintables() {
    if (!this.isPrintablesPending) {
      this.setPrintablesPending(true);
      const printables = await this.api.getPrintables(this.id, "team-competitions");
      this.setPrintables(printables);
      this.setPrintablesPending();
    }
  }

  @action
  setPrintables({ competitorDiploma, competitorNumber, prizewinnerDiploma }) {
    this.competitorDiploma = competitorDiploma;
    this.competitorNumber = competitorNumber;
    this.prizewinnerDiploma = prizewinnerDiploma;
  }

  @action
  setPrintablesPending(pending = false) {
    this.printablesPending = pending;
  }  

  @action
  async signTo() {
    this.setSigning(true);
    const registration = await this.api.registerChallenge(this.id);
    this.setStatus(registration && registration.status);
    this.setSigning(false);
  }

  @action
  setSigning(signing = false) {
    this.signing = signing;
  }

  @action
  setFullTops(data) {
    const top = new TeamTop(data, this.store);
    this.fullTops = top;
  }

  @action
  setTops(data) {
    const top = new TeamTop(data, this.store);
    this.tops = top;
  }

  @action
  addFullTops(data) {
    const top = this.fullTops;
    if (!top) {
      this.setFullTops(data);
    } else {
      top.addData(data);
    }
  }

  @action
  setPending(pending = false) {
    this.pending = pending;
  }

  @action
  setPagePending(pending = false) {
    this.pagePending = pending;
  }

  @action
  getNextPage(companyId) {
    this.page = this.page + 1;
    this.getFullTops(companyId);
  }

  @action
  getPageInit(companyId) {
    this.page = 1;
    this.getFullTops(companyId);
  }

  @action
  async getFullTops(sex = "males") {
    const page = this.page;
    if (!this.isPending && !this.isPagePending) {
      this.setNextPage(true);
      if (page === 1) {
        this.setPending(true);
      } else {
        this.setPagePending(true);
      }

      const tops = await this.api.getTeamChallengeTops(
        this.id,
        sex[0].toUpperCase(),
        page,
        this.perPage
      );

      const topData = [];
      tops.forEach((data) => {
        const company = this.companiesStore.createCompany(data);
        const topItem = new TopItem({ 
          id: company.id, 
          ...data,
        });
        topData.push(topItem);
      });

      if (tops.length !== this.perPage) {
        this.setNextPage(false);
      }

      if (page === 1) {
        this.setFullTops(topData);
      } else {
        this.addFullTops(topData);
      }
      this.setPending();
      this.setPagePending();
    }
  }

  @action
  async getAnnotation(sex, companyId, amount) {
    if (!this.isAnnotationPending) {
      this.setAnnotationPending(true);
      const tops = await this.api.getTeamChallengeAnnotation(
        this.id,
        sex[0].toUpperCase(),
        companyId, 
        amount || 3,
      );

      const topData = [];
      tops.forEach((data) => {
        const company = this.companiesStore.createCompany(data);
        const topItem = new TopItem({ 
          id: company.id, 
          ...data,
        });
        topData.push(topItem);
      });
      this.setTops(topData);
      this.setAnnotationPending();
    }
  }

  @action
  async getProgressInfo(athleteId) {
    if (!this.isAnnotationPending && athleteId) {
      this.setAnnotationPending(true);
      try {
        const progress = await this.api.getProgress(
          this.id,
          athleteId,
        );
        if (progress.progress && progress.progress.length) {
          const progressObj = {};

          progress.progress.forEach((item) => {
            progressObj[item.conditionId] = item.percentage;
          });

          this.setProgress(athleteId, progressObj);
        }
      } catch (error) {
        console.warn("Couldn't load progress", error);
      }
      this.setAnnotationPending();
    }
  }

  @action
  setProgress(athleteId, progress) {
    this.progressMap.set(`${athleteId}`, progress);
  }

  @action
  getProgress(athleteId) {
    return this.progressMap.get(`${athleteId}`);
  }

  @action
  async getActivitiesInfo(athleteId) {
    if (!this.isActivitiesPending && athleteId) {
      this.setActivitiesPending(true);
      try {
        const activities = await this.api.getActivities(
          this.id,
          athleteId,
        );
        if (activities && activities.length) {
          this.setActivities(athleteId, activities);
        }
      } catch (error) {
        console.warn("Couldn't load athlete activities", error);
      }
      this.setActivitiesPending();
    }
  }

  @action
  setActivities(athleteId, activities) {
    this.activitiesMap.set(`${athleteId}`, activities);
  }

  @action
  getActivities(athleteId) {
    return this.activitiesMap.get(`${athleteId}`);
  }

  @action
  async setNextPage(nextPage = false) {
    this.nextPage = nextPage;
  }

  @action
  setStatus(status = null) {
    this.status = status;
  }

  @action
  setAnnotationPending(pending = false) {
    this.annotationPending = pending;
  }

  @action
  setActivitiesPending(pending = false) {
    this.activitiesPending = pending;
  }

  @computed
  get urlPart() {
    return "team-competitions";
  }

  @computed
  get fullTop() {
    return this.fullTops && this.fullTops.top || [];
  }

  @computed
  get top() {
    return this.tops && this.tops.top || [];
  }

  @computed
  get topCompanyId() {
    return this.season.topCompanyId;
  }

  @computed
  get isPending() {
    return this.pending;
  }

  @computed
  get isAnnotationPending() {
    return this.annotationPending;
  }

  @computed
  get isActivitiesPending() {
    return this.activitiesPending;
  }

  @computed
  get isPrintablesPending() {
    return this.printablesPending;
  }

  @computed
  get isPagePending() {
    return this.pagePending;
  }

  @computed
  get currentFullTop() {
    return toJS(this.fullTops.get(this.type));
  }

  @computed
  get hasNextPage() {
    return this.nextPage;
  }

  @computed
  get type() {
    return this.rootStore.kind;
  }

  @computed
  get canSign() {
    return false;// moment().isBetween(this.start, this.finish);
  }

  @computed
  get isSigning() {
    return this.signing;
  }

  @computed
  get ruleNames() {
    return toJS(this.conditions);
  }

  @computed
  get conditionNames() {
    return toJS(this.conditions);
  }
  @computed
  get filterNames() {
    return toJS(this.filters);
  }

  @computed
  get class() {
    return "team";
  }
}
