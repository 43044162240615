import { action, observable } from "mobx";

export default class TeamTop {
  @observable
  store = null;
  @observable
  rootStore = null;
  @observable
  top = [];

  constructor(data, store) {
    this.store = store;
    this.rootStore = this.store.rootStore;

    this.top = data;
  }

  @action
  addData(data) {
    if (data) {
      data.forEach((team) => {
        this.top.push(team);
      });
    }
  }
}
