import { action, observable, computed, toJS } from "mobx";
import moment from "moment";
import Top from "./Top";
import Company from "./Company";
import ChallengesStore from "~/stores/ChallengesStore";
import Month from "./Month";
import TopItem from "./TopItem";
import Rating from "./Rating";

export default class Season {
  @observable
  store = null;
  @observable
  rootStore = null;
  @observable
  id = null;
  @observable
  description = null;

  @observable
  tops = new Map();

  @observable
  fullTops = new Map();

  @observable
  finish = null;
  @observable
  start = null;

  @observable
  title = null;

  @observable
  myCompanyId = null;

  @observable
  pending = false;
  @observable
  pagePending = false;

  @observable
  companiesPending = false;

  @observable
  companies = new Map();

  @observable
  ratings = new Map();

  @observable
  teamRatings = new Map();

  @observable
  nextPage = true;

  @observable
  isBulkRegistrationAllowed = true;

  @observable
  months = new Map();

  @observable
  fullSeason = null;

  @observable
  disciplines = [];

  @observable
  selectedMonth = null;

  @observable
  companyForTop = null;

  @observable
  currentRatingId = null;

  @observable
  currentTeamRatingId = null;

  @observable
  regulationsUrl = null;

  constructor(data, store) {
    this.store = store;
    this.api = this.store.api;
    this.rootStore = this.store.rootStore;
    this.athletesStore = this.rootStore.athletesStore;
    this.accountStore = this.rootStore.accountStore;

    this.id = data.id;
    this.title = data.title;
    this.isBulkRegistrationAllowed = data.isBulkRegistrationAllowed;
    this.description = data.description;
    this.disciplines = data.disciplines || this.store.disciplinesArrayFallback;
    this.myCompanyId = data.companyId;

    this.regulationsUrl = data.regulations || null;

    this.finish = data.finish
      ? moment
        .utc(data.finish)
        .startOf("day")
        .add(12, "hours")
        .local()
        .utc(true)
      : null;
    this.start = data.start
      ? moment
        .utc(data.start)
        .startOf("day")
        .add(12, "hours")
        .local()
        .utc(true)
      : null;

    this.challengesStore = new ChallengesStore(this);

    this.getCompanies();
    this.getMonths();
    this.getRatings();
    this.getTeamRatings();
  }

  @action
  getMonths() {
    if (!this.start || !this.finish) {
      return null;
    }
    const start = this.start
      .clone()
      .startOf("month")
      .add(15, "days");
    const end = this.finish.clone().endOf("month");

    let firstMonth = null;
    let index = 0;
    while (end > start) {
      const date = start.clone();
      let monthStart = date.clone().startOf("month");
      if (monthStart < this.start) {
        monthStart = this.start.clone();
      }
      let monthEnd = date.clone().endOf("month");
      if (monthEnd > this.end) {
        monthEnd = this.end.clone();
      }
      const month = new Month(
        {
          index,
          start: monthStart,
          end:   monthEnd,
          date,
        },
        this
      );
      this.months.set(month.id, month);
      start.add(1, "month");
      if (index === 0) {
        firstMonth = month;
      }
      index += 1;
    }
    // full season opt START
    this.fullSeason = new Month(
      {
        index,
        start: this.start.clone(),
        end:   this.finish.clone(),
      },
      this
    );
    this.months.set(this.fullSeason.id, this.fullSeason);
    // full season opt END

    const initialMonth = this.months.get(this.store.initialMonthId);
    const currentMonth = this.months.get(moment().format("MM.YYYY"));
    this.setMonth(initialMonth || currentMonth || firstMonth);
  }

  @action
  setMonth(month) {
    this.selectedMonth = month;
    if (this.isCurrent) {
      this.store.setInitialMonthId(month.id);
    }
  }

  @action
  initChallengesStore() {
    this.challengesStore.init();
  }

  @action
  setPending(pending = false) {
    this.pending = pending;
  }

  @action
  onSwitch(amount = 1, fullSeason) {
    const month = this.currentMonth.index + amount;
    const next = this.monthsList[month];
    if (next) {
      this.setMonth(next);
    } else {
      if (amount > 0) {
        this.setMonth(this.monthsList[0]);
      }
      if (amount < 0) {
        this.setMonth(this.monthsList[this.monthsList.length - 1]);
      }
    }
    if (this.currentMonth.id === "full-season" && !fullSeason) {
      this.onSwitch(amount, fullSeason);
    }
  }

  @action
  setPagePending(pending = false) {
    this.pagePending = pending;
  }

  @action
  setCompaniesPending(pending = false) {
    this.companiesPending = pending;
  }

  @action
  setTops(type, data) {
    const top = new Top(data, this.store);
    this.tops.set(type, top);
  }

  @action
  setFullTops(type, data) {
    const top = new Top(data, this.store);
    this.fullTops.set(type, top);
  }

  @action
  addFullTops(type, data) {
    const top = this.fullTops.get(type);
    if (!top) {
      this.setFullTops(type, data);
    } else {
      top.addData(data);
    }
  }

  @action
  processCompanies(companies) {
    this.companies.clear();
    companies.forEach((companyData) => {
      this.createCompany(companyData);
    });
  }

  @action
  createCompany(companyData) {
    const company = new Company(companyData, this);
    this.companies.set(`${company.id}`, company);
    return this.companies.get(`${company.id}`);
  }

  @action
  setTopCompany(companyId = null) {
    this.companyForTop = this.companies.get(`${companyId}`) || null;
  }

  @action
  async getCompanies() {
    if (this.id === "all") {
      this.companies.clear();
      this.companies = this.store.allCompanies;
    }
    if (!this.isCompaniesPending && !this.companies.size) {
      this.setCompaniesPending(true);
      const companies = await this.api.getSeasonCompanies(this.id);
      this.processCompanies(companies);
      this.setCompaniesPending(false);
    }
  }

  @action
  async getTops() {
    // }
  }

  @action
  async getFullTops(sex = "males", page = 1, perPage = 20, from, to) {
    if (!this.isPending && !this.isPagePending) {
      this.setNextPage(true);
      if (page === 1) {
        this.setPending(true);
      } else {
        this.setPagePending(true);
      }
      const tops = await this.api.getTops(
        this.id,
        this.type,
        sex[0].toUpperCase(),
        null,
        this.topCompanyId,
        page,
        perPage,
        from,
        to
      );
      const topData = { males: [], females: [] };
      tops.forEach((data) => {
        const athlete = this.athletesStore.createAthlete(data);
        const topItem = new TopItem({
          id: athlete.id,
          ...data,
        });
        topData[sex].push(topItem);
      });

      if (tops.length !== perPage) {
        this.setNextPage(false);
      }

      if (page === 1) {
        this.setFullTops(this.type, topData);
      } else {
        this.addFullTops(this.type, topData);
      }
      this.setPending();
      this.setPagePending();
    }
  }

  @action
  async getRatingTops(
    sex = "males",
    page = 1,
    perPage = 20,
    limit,
    isTeamRating
  ) {
    if (!this.isPending && !this.isPagePending) {
      this.setNextPage(true);
      if (page === 1) {
        this.setPending(true);
      } else {
        this.setPagePending(true);
      }
      const tops = await this.api.getRatingTops({
        sex:        sex[0].toUpperCase(),
        discipline: this.type,
        clubId:     this.accountStore.currentClubId,
        seasonId:   this.id,
        companyId:  this.topCompanyId,
        from:       this.currentMonth && this.currentMonth.from,
        to:         this.currentMonth && this.currentMonth.to,
        isTeamRating,
        page,
        perPage,
        limit,
      });
      const topData = { males: [], females: [] };
      tops.forEach((data) => {
        const athlete = this.athletesStore.createAthlete(data);
        const topItem = new TopItem({
          id: athlete.id,
          ...data,
        });
        topData[sex].push(topItem);
      });

      if (tops.length !== perPage) {
        this.setNextPage(false);
      }

      if (page === 1) {
        this.setFullTops(this.type, topData);
      } else {
        this.addFullTops(this.type, topData);
      }
      this.setPending();
      this.setPagePending();
    }
  }

  @action
  async getRatings() {
    this.setPending(true);
    const ratings = await this.api.getRatings({ seasonId: this.id });
    ratings.forEach((rating) => {
      this.addRating(rating);
    });
    this.setPending();
  }

  @action
  async getTeamRatings() {
    this.setPending(true);
    const ratings = await this.api.getTeamRatings({ seasonId: this.id });
    ratings.forEach((rating) => {
      this.addTeamRating(rating);
    });
    this.setPending();
  }

  @action
  addRating(rating) {
    const newRating = new Rating(rating, this);
    this.ratings.set(newRating.id, newRating);
  }

  @action
  addTeamRating(rating) {
    const newRating = new Rating(rating, this, true);
    this.teamRatings.set(newRating.id, newRating);
  }

  @action
  setCurrentRating(ratingId) {
    this.currentRatingId = ratingId;
  }

  @action
  setCurrentTeamRating(ratingId) {
    this.currentTeamRatingId = ratingId;
  }

  @action
  async setNextPage(nextPage = false) {
    this.nextPage = nextPage;
  }

  @action
  async assignTo(kind, from, to) {
    this.setPending(true);
    try {
      const registrationsData = await this.api.assignTo(this.id, kind, from, to);
      this.challengesStore.processRegistrations(registrationsData);
    } catch (error) {
      console.warn(error);
    }
    this.setPending(false);
  }

  @computed
  get currentRating() {
    return this.ratings.get(this.currentRatingId);
  }

  @computed
  get currentTeamRating() {
    return this.teamRatings.get(this.currentTeamRatingId);
  }

  @computed
  get ratingList() {
    const list = [];
    this.ratings.forEach((rating) => {
      list.push(rating);
    });
    return list;
  }

  @computed
  get teamRatingList() {
    const list = [];
    this.teamRatings.forEach((rating) => {
      list.push(rating);
    });
    return list;
  }

  @computed
  get topCompany() {
    return this.companyForTop;
  }

  @computed
  get monthsList() {
    const months = [];
    this.months.forEach((month) => {
      months.push(month);
    });
    return months;
  }

  @computed
  get currentMonth() {
    if (this.selectedMonth) {
      return this.selectedMonth;
    }
    return this.monthsList[0];
  }

  @computed
  get currentMonthId() {
    return this.currentMonth && this.currentMonth.id;
  }

  @computed
  get topCompanyId() {
    return (this.topCompany && this.topCompany.id) || null;
  }

  @computed
  get hasNextPage() {
    return this.nextPage;
  }

  @computed
  get name() {
    return this.title;
  }

  @computed
  get regulations() {
    return this.regulationsUrl;
  }

  @computed
  get currentTop() {
    return this.tops.get(this.type);
  }

  @computed
  get currentFullTop() {
    return this.fullTops.get(this.type);
  }

  @computed
  get isPending() {
    return this.pending;
  }

  @computed
  get isPagePending() {
    return this.pagePending;
  }

  @computed
  get isCompaniesPending() {
    return this.companiesPending;
  }

  @computed
  get type() {
    return this.rootStore.kind;
  }

  @computed
  get canSign() {
    return !this.isBulkRegistrationAllowed;
  }

  @computed
  get isCurrent() {
    return `${this.id}` === `${this.store.currentSeasonId}`;
  }

  @computed
  get disciplinesSet() {
    return new Set(this.disciplinesArray);
  }

  @computed
  get disciplinesArray() {
    return toJS(this.disciplines);
  }

  @computed
  get companiesList() {
    const companies = [];
    this.companies.forEach((company) => {
      if (company.isPublic) {
        companies.push(company);
      }
    });
    return companies;
  }

  @computed
  get fullCompaniesList() {
    const companies = [];
    this.companies.forEach((company) => {
      companies.push(company);
    });
    return companies;
  }
}
