import React, { useCallback, useLayoutEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import Select from "react-select";

import useStores from "~/hooks";
import MultiSwitch from "../components/multiSwitch/MultiSwitch";
import { Preloader } from "~/components/common";
import KindCard from "../components/multiSwitch/KindCard";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import ActivityListItem from "../components/common/ActivityListItem";

const GPXForm = observer(() => {
  const { rootStore, seasonsStore, accountStore } = useStores();
  const { type, isGapPending } = rootStore;
  const { currentSeasonId } = seasonsStore;

  useLayoutEffect(() => {
    rootStore.getManualActivityUploadGap();
  }, [rootStore]);

  const [isPending, setPending] = useState(false);
  const [isSucess, setSucess] = useState(false);
  const [kind, setKind] = useState();
  const [addedActivities, setAddedActivities] = useState([]);
  const [photo, setPhoto] = useState([]);
  // timezone - строка - зона пользователя из браузера (думаю достану сам из хедера запроса от пользователя)

  const kindMatch = {
    run: {
      type:  "Run",
      isGPS: true,
    },
    walk: {
      type:  "Walk",
      isGPS: true,
    },
    ride: {
      type:  "Ride",
      isGPS: true,
    },
    bikestation: {
      type:  "VirtualRide",
      isGPS: true,
    },
    mountainbike: {
      type:  "MountainBiking",
      isGPS: true,
    },
    skating: {
      type:  "Skating",
      isGPS: true,
    },
    ski: {
      type:  "NordicSki",
      isGPS: true,
    },
    swim: {
      type:  "Swim",
      isGPS: true,
    },
  };

  const handleInputChange = (value) => {
    setKind(value);
  };

  const canSend = useMemo(() => {
    return !!kind && photo && photo.length > 0;
  }, [kind, photo]);

  const onSend = async() => {
    if (canSend) {
      setAddedActivities([]);
      setPending(true);
      const kindObject = kindMatch[kind.value];
      const photoArray = [];
      photo.forEach((item) => {
        if (item.value) {
          photoArray.push(item.value);
        }
      });
      const data = {
        kind: kindObject.type,
        gpx:  photoArray,
      };
      try {
        const result = await accountStore.sendGPX(data);
        setSucess(true);
        setKind();
        setPhoto([]);
        if (result) {
          setAddedActivities(result);
        }
      } catch (error) {
        console.warn(error);
      }
      setPending(false);
    }
  };

  const onFileChange = (e) => {
    e.preventDefault();
    const newPhotos = [...photo];
    let found = false;
    const newItem = {
      name:  e.target.name,
      value: e.target.files[0],
    };
    newPhotos.forEach((item, i) => {
      if (item.name === e.target.name) {
        newPhotos[i] = newItem;
        found = true;
      }
    });
    if (!found) {
      newPhotos.push(newItem);
    }
    setPhoto(newPhotos);
  };

  const onDummie = useCallback(() => {}, []);

  const onDismiss = useCallback(() => {
    setSucess(false);
  }, []);

  const addedActivitiesRender = useMemo(() => {
    return addedActivities.map((activity) => {
      return (
        <ActivityListItem
          key={`${activity.id}`}
          kind={activity.kind}
          type={type}
          showDate={true}
          activity={activity}
        />
      );
    });
  }, [addedActivities]);
  if (isGapPending) {
    return <Preloader />;
  }

  return (
    <div className={`activity-form page ${type}`}>
      <MultiSwitch
        page="seasons"
        linkPrefix={"/"}
        id={currentSeasonId}
        noSport={true}
        noSeason={true}
        noMonth={true}
      />
      <div className="form-holder">
        <div className={`form-title ${type} gradient`}>
          {isSucess ? "Успешно" : "Внесите данные тренировки"}
        </div>
        {isPending && <Preloader />}
        {isSucess && (
          <div className="form-content">
            <div
              className={`color ${type}`}
              style={{ fontSize: "5rem", textAlign: "center" }}
            >
              <IoIosCheckmarkCircleOutline />
            </div>
            <div
              className="form-text"
              style={{ marginBottom: "1rem", textAlign: "center" }}
            >
              Успешно добавлено!
            </div>
            <div className="activities-list">{addedActivitiesRender}</div>
            <div className="fields-holder">
              <div onClick={onDismiss} className={`button gradient ${type}`}>
                Добавить еще
              </div>
            </div>
          </div>
        )}
        {!isPending && !isSucess && (
          <div className="form-content">
            <div className="form-text">
              Пожалуйста введите следующие данные:
            </div>
            <div className="fields-holder">
              <div className="field-holder">
                <div className="title">Дисциплина</div>
                <Select
                  placeholder="Дисциплина"
                  menuShouldScrollIntoView={false}
                  menuPosition="fixed"
                  captureMenuScroll={true}
                  isSearchable={false}
                  value={kind}
                  classNamePrefix={"kind-select"}
                  options={[
                    {
                      value: "run",
                      label: <KindCard onCardPress={onDummie} kind="run" />,
                    },
                    {
                      value: "walk",
                      label: <KindCard onCardPress={onDummie} kind="walk" />,
                    },
                    {
                      value: "ride",
                      label: <KindCard onCardPress={onDummie} kind="ride" />,
                    },
                    {
                      value: "mountainbike",
                      label: (
                        <KindCard onCardPress={onDummie} kind="mountainbike" />
                      ),
                    },
                    {
                      value: "bikestation",
                      label: (
                        <KindCard onCardPress={onDummie} kind="bikestation" />
                      ),
                    },
                    {
                      value: "skating",
                      label: <KindCard onCardPress={onDummie} kind="skating" />,
                    },
                    {
                      value: "ski",
                      label: <KindCard onCardPress={onDummie} kind="ski" />,
                    },
                    {
                      value: "swim",
                      label: <KindCard onCardPress={onDummie} kind="swim" />,
                    },
                  ]}
                  onChange={handleInputChange}
                />
              </div>
              <div className="field-holder">
                <div className="title">GPX</div>
                <input
                  placeholder="Добавьте файл GPX"
                  name="gpx0"
                  onChange={onFileChange}
                  type="file"
                  accept=".gpx"
                  className="field"
                />
                <input
                  placeholder="Добавьте файл GPX"
                  name="gpx1"
                  onChange={onFileChange}
                  type="file"
                  accept=".gpx"
                  className="field"
                />
                <input
                  placeholder="Добавьте файл GPX"
                  name="gpx2"
                  onChange={onFileChange}
                  type="file"
                  accept=".gpx"
                  className="field"
                />
                <input
                  placeholder="Добавьте файл GPX"
                  name="gpx3"
                  onChange={onFileChange}
                  type="file"
                  accept=".gpx"
                  className="field"
                />
                <input
                  placeholder="Добавьте файл GPX"
                  name="gpx4"
                  onChange={onFileChange}
                  type="file"
                  accept=".gpx"
                  className="field"
                />
              </div>
              <div
                onClick={onSend}
                className={`button gradient ${canSend && type}`}
              >
                Загрузить
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
});

export default GPXForm;
