import { observer } from "mobx-react";
import React from "react";

import GroupButton from "./GroupButton";

const ButtonGroup = observer(({ buttons }) => {
  if (!(buttons && buttons.length)) {
    return null;
  }
  const buttonsRender = [];
  if (buttons && buttons.length) {
    buttons.forEach((button) => {
      buttonsRender.push(
        <GroupButton
          key={button.id}
          icon={button.icon}
          id={button.id}
          title={button.title}
        />
      );
    });
  }
  return (
    <div className="group-container">
      {buttonsRender}
    </div>
  );
});
export default ButtonGroup;
