import React, { useLayoutEffect, useRef } from "react";
import { observer } from "mobx-react";

import { ReactComponent as Run } from "~/resourses/sports/run.svg";
import { ReactComponent as Ride } from "~/resourses/sports/ride.svg";
import { ReactComponent as Ski } from "~/resourses/sports/ski.svg";
import { ReactComponent as Walk } from "~/resourses/sports/walk.svg";
import { ReactComponent as Treadmill } from "~/resourses/sports/treadmill.svg";
import { ReactComponent as Exercycle } from "~/resourses/sports/exercycle.svg";
import { ReactComponent as Swim } from "~/resourses/sports/swim.svg";
import { ReactComponent as Pool } from "~/resourses/sports/pool.svg";
import { ReactComponent as All } from "~/resourses/sports/all.svg";
import { ReactComponent as Multisport } from "~/resourses/sports/multisport.svg";
import { ReactComponent as Mountainbike } from "~/resourses/sports/mountainbike.svg";
import { ReactComponent as Bikestation } from "~/resourses/sports/bikestation.svg";
import { ReactComponent as Rollerskate } from "~/resourses/sports/rollerskate.svg";
import { ReactComponent as Outdoor } from "~/resourses/sports/outdoor.svg";

import useStores from "~/hooks";

const config = {
  all: {
    type: "run",
    text: { ru: "Все дисциплины", en: "All disciplines" },
  },
  run: {
    type: "run",
    text: { ru: "Бег", en: "Run" },
  },
  walk: {
    type: "run",
    text: { ru: "Ходьба", en: "Walk" },
  },
  treadmill: {
    type: "run",
    text: { ru: "Дорожка", en: "Treadmill" },
  },
  various: {
    type: "run",
    text: { ru: "Открытая тренировка", en: "Various" },
  },
  ride: {
    type: "ride",
    text: { ru: "Велосипед", en: "Bicycle" },
  },
  bikestation: {
    type: "ride",
    text: { ru: "Велостанок", en: "Stationary Bike" },
  },
  exercycle: {
    type: "ride",
    text: { ru: "Велотренажер", en: "Exercise bike" },
  },
  mountainbike: {
    type: "ride",
    text: { ru: "Горный велосипед", en: "Mountain bike" },
  },
  skating: {
    type: "ski",
    text: { ru: "Роликовые коньки", en: "Inline skating" },
  },
  ski: {
    type: "ski",
    text: { ru: "Лыжи", en: "Nordic skiing" },
  },
  pool: {
    type: "ski",
    text: { ru: "Бассейн", en: "Swimming" },
  },
  swim: {
    type: "ski",
    text: { ru: "Открытая вода", en: "Open water swimming" },
  },
  multisport: {
    type: "ski",
    text: { ru: "Мультиспорт", en: "Multisport" },
  },
};

const KindCard = observer(
  ({
    kind: rawKind,
    active,
    disabled,
    iconOnly,
    onCardPress,
    noScroll,
    lang = "ru",
  }) => {
    const kind = rawKind.toLowerCase();
    const colorType = config[kind] && config[kind].type;
    const { rootStore } = useStores();
    const buttonRef = useRef(null);
    const { kind: type } = rootStore;

    const scrollToKind = (kind) => {
      const kindButton = document.getElementById(`button-card-${kind}`);
      kindButton && kindButton.scrollIntoView();
    };

    useLayoutEffect(() => {
      if (buttonRef && buttonRef.current && !noScroll) {
        scrollToKind(type);
      }
    }, [type, buttonRef, noScroll]);

    const onPress = () => {
      if (window.navigator.vibrate) {
        window.navigator.vibrate([30]);
      }

      if (onCardPress) {
        onCardPress(rawKind);
      } else {
        rootStore.setType(kind);
      }
    };

    return (
      <div
        ref={buttonRef}
        id={`button-card-${kind}`}
        className={`button-card ${iconOnly && "icon-only fill"} ${!disabled &&
          kind} ${!disabled && colorType} ${
          (type === kind || active) && !iconOnly ? "active gradient" : ""
        }`}
        onClick={disabled ? undefined : onPress}
      >
        <div className="icon-holder">
          {kind === "all" && <All />}
          {kind === "run" && <Run />}
          {kind === "walk" && <Walk />}
          {kind === "treadmill" && <Treadmill />}
          {kind === "ride" && <Ride />}
          {kind === "bikestation" && <Bikestation />}
          {kind === "exercycle" && <Exercycle />}
          {kind === "ski" && <Ski />}
          {kind === "swim" && <Swim />}
          {kind === "pool" && <Pool />}
          {kind === "multisport" && <Multisport />}
          {kind === "mountainbike" && <Mountainbike />}
          {kind === "skating" && <Rollerskate />}
          {kind === "various" && <Outdoor />}
        </div>
        {!iconOnly && (
          <div className="text">{config[kind] && config[kind].text[lang]}</div>
        )}
      </div>
    );
  }
);
export default KindCard;
