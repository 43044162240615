import { observable, action, computed } from "mobx";
import moment from "moment";
import Archievement from "./Archievement";

export default class Athlete {
  @observable
  store = null;
  @observable
  rootStore = null;
  @observable
  companiesStore = null;

  @observable
  birthday = null;

  @observable
  id = null;
  @observable
  sex = null; // ФИО.
  @observable
  email = null; // email

  @observable
  firstame = null; // ФИО.
  @observable
  lastname = null; // ФИО.

  @observable
  country = null; // Фотографии.
  @observable
  city = null; // Наименования группы (компании).
  @observable
  state = null; // Количества звезд в текущем сезоне.

  @observable
  image = null; // Фотографии.
  @observable
  group = null; // Наименования группы.
  @observable
  kind = "spectator"; // Наименования группы.
  @observable
  companyId = null; // ID компании.
  @observable
  stars = null; // Количества звезд в текущем сезоне.
  @observable
  cups = null; // Количества кубков в текущем сезоне.
  @observable
  activities = []; // Количества кубков в текущем сезоне.
  @observable
  activitiesCount = null; // Количества тренировок в текущем сезоне.

  @observable
  distance = null;
  @observable
  movingTime = null;

  @observable
  runPlace = null; // Положения в итоговой таблице текущего сезона бег
  @observable
  ridePlace = null; // Положения в итоговой таблице текущего сезона вело
  @observable
  skiPlace = null; // Положения в итоговой таблице текущего сезона лыж

  //  части таблиц результатов по видам спорта
  @observable
  runTable = [];
  @observable
  rideTable = [];
  @observable
  skiTable = [];

  @observable
  registrationDate = null; // Даты регистрации

  // списки вызовов
  @observable
  challengesInProgress = []; // Списка вызовов в процессе выполнения.
  @observable
  challengesDone = []; // Списка вызовов, на которые атлет записался и уже выполнил.
  @observable
  challengesFailed = []; // Списка вызовов, на которые атлет записался и не выполнил.

  @observable
  pending = false;

  @observable
  pendingImage = false;

  @observable
  isValid = false;

  @observable
  full = false;

  @observable
  rank = null;

  @observable
  activitiesMap = new Map();

  @observable
  activitiesForCalendarMap = new Map();

  @observable
  activitiesByAthleteDateMap = new Map();

  @observable
  personalDataAgreementDate = null;

  @observable
  stats = [];

  @observable
  athleteStatistics = {};

  @observable
  pendingArchievements = false;

  @observable
  archievementsArray = [];

  @observable
  promocodes = [];

  constructor(data, store) {
    this.store = store;
    this.api = this.store.api;
    this.rootStore = this.store.rootStore;
    this.companiesStore = this.rootStore.companiesStore;

    this.start = moment().add(-90, "days");
    this.finish = moment();
    this.id = data.id;
    this.sex = data.sex || null;
    this.email = data.email || null;
    this.firstname = data.firstname || null;
    this.lastname = data.lastname || null;
    this.isValid = data.isValid || null;
    this.image = data.avatar || null;
    this.group = data.group || null;
    this.providers = data.providers || null;
    this.companyId =
      data.companyId || (data.company && data.company.id) || null;
    this.stars = data.stars || null;
    this.cups = data.cups || null;
    this.activitiesCount = data.activitiesCount || null;
    this.activities = data.activities || [];

    this.place = data.place || null;
    this.distance = data.distance || null;
    this.movingTime = data.movingTime || null;
    this.kind = data.kind || "spectator";
    this.birthday = (data.birthday && moment(data.birthday)) || null;
    this.personalDataAgreementDate =
      (data.personalDataAgreementDate &&
        moment(data.personalDataAgreementDate)) ||
      null;

    this.rank = data.rank || null;
    this.country = data.country || null;
    this.city = data.city || null;
    this.state = data.state || null;
    this.getImage();
  }

  @action
  async getArchievements(kind, currentSeasonId, from, to) {
    if (from && to) {
      this.setArchievementsPending(true);
      this.start = moment(from).utc();
      this.finish = moment(to).utc();
      const archievements = await this.api.getArchievements(
        this.id,
        kind,
        currentSeasonId,
        from,
        to
      );
      if (archievements) {
        this.processArchievements(archievements);
      }
      this.setArchievementsPending(false);
    }
  }

  @action
  processArchievements(archievements = []) {
    this.archievementsArray = [];
    archievements &&
      archievements.length &&
      archievements.forEach((item) => {
        const arch = new Archievement(item, this);
        this.archievementsArray.push(arch);
      });
  }

  @action
  setArchievementsPending(pending = false) {
    this.pendingArchievements = pending;
  }

  @action
  async getFullInfo(type, season, from, to) {
    if (!this.pending && this.id) {
      this.pending = true;
      try {
        await this.getArchievements(type, season, from, to);
        const info = await this.store.fetchAthlete(
          this.id,
          type,
          season,
          from,
          to
        );

        if (info && info.id) {
          this.setFullInfo(info);
        } else {
          console.warn("No valid athlete information: ", info);
        }
      } catch (error) {
        console.warn(error);
      }
    }
    if (from && to && this.id) {
      const activities = await this.store.fetchAthleteActivities(
        this.id,
        type,
        from,
        to
      );
      this.setActivitiesForCalendar(this.id, activities);
      const athleteStats = await this.api.fetchAthleteStats(
        this.id,
        type,
        season,
        from,
        to
      );
      this.athleteStatistics = athleteStats;
      if (this.id === "me") {
        const athletePromocodes = await this.api.fetchPromocodes(
          type,
          season,
          from,
          to
        );
        this.promocodes = athletePromocodes;
      }

      const stats = await this.api.fetchAthleteDisciplineStats(
        this.id,
        type,
        season,
        from,
        to
      );
      this.stats = stats;
    }
  }

  @action
  getActicvitiesByDate(athleteId, selectedDate) {
    return this.activitiesByAthleteDateMap.get(`${athleteId}-${selectedDate}`);
  }

  @action
  setActivitiesForCalendar(athleteId, activities = []) {
    // TODO: optimize counting, move to separate class
    this.activitiesByAthleteDateMap.clear();
    this.activitiesForCalendarMap.clear();
    this.activitiesMap.clear();

    const activitiesDataObject = {};
    const activitiesForCalendarArray = [];
    activities &&
      activities.length &&
      activities.forEach((activity, i) => {
        if (activity.disciplineName) {
          const date = moment.utc(activity.start);
          const dateString = date.format("YYYY-MM-DD");
          this.activitiesMap.set(`${dateString}-${i}`, activity);
          if (!activitiesDataObject[dateString]) {
            activitiesDataObject[dateString] = {
              count:      0,
              date:       dateString,
              level:      0,
              activities: [],
            };
          }
          const dataObject = activitiesDataObject[dateString];
          dataObject.count += 1;
          dataObject.activities.push(activity);
          dataObject.level = 0;
          if (dataObject.count > 0 && dataObject.count < 3) {
            dataObject.level = 1;
          } else if (dataObject.count >= 3) {
            dataObject.level = 2;
          } else if (dataObject.count >= 5) {
            dataObject.level = 3;
          } else if (dataObject.count >= 10) {
            dataObject.level = 4;
          }
          let data = this.activitiesByAthleteDateMap.get(
            `${athleteId}-${dateString}`
          );
          if (!data) {
            this.activitiesByAthleteDateMap.set(
              `${athleteId}-${dateString}`,
              []
            );
            data = this.activitiesByAthleteDateMap.get(
              `${athleteId}-${dateString}`
            );
          }
          data.push(activity);
        }
      });
    const start = this.start.clone();
    while (start <= this.finish) {
      const dateString = start.format("YYYY-MM-DD");
      let dummie = {
        count:      0,
        date:       dateString,
        level:      0,
        activities: [],
      };
      if (activitiesDataObject[dateString]) {
        dummie = activitiesDataObject[dateString];
      }
      activitiesForCalendarArray.push(dummie);
      start.add(1, "days");
    }
    this.activitiesForCalendarMap.set(
      `${athleteId}`,
      activitiesForCalendarArray
    );
  }

  @action
  getActivitiesForCalendar(athleteId) {
    return this.activitiesForCalendarMap.get(`${athleteId}`);
  }

  @action
  getActivities() {
    return Array.from(this.activitiesMap.values());
  }

  @action
  updateActivityData(id, newActivityData) {
    this.pending = true;
    let insertIndex = null;
    const newActivityArray = this.activities.slice();
    this.activities.forEach((activity, index) => {
      if (`${activity.id}` === `${id}`) {
        insertIndex = index;
      }
    });
    if (insertIndex !== null) {
      newActivityArray[insertIndex] = newActivityData;
    }
    this.activities.replace(newActivityArray);
    this.setActivitiesForCalendar(this.id, newActivityArray);
    this.pending = false;
  }

  @action
  setFullInfo(data) {
    this.sex = data.sex || null;
    this.firstname = data.firstname || null;
    this.lastname = data.lastname || null;
    this.isValid = data.isValid || null;
    this.image = data.avatar || null;
    this.group = data.group || null;
    this.companyId =
      data.companyId || (data.company && data.company.id) || null;
    this.stars = data.stars || null;
    this.cups = data.cups || null;
    this.activitiesCount = data.activitiesCount || null;
    this.activities = data.activities || [];

    this.place = data.place || null;
    this.distance = data.distance || null;
    this.movingTime = data.movingTime || null;
    this.birthday = (data.birthday && moment(data.birthday)) || null;
    this.personalDataAgreementDate =
      (data.personalDataAgreementDate &&
        moment(data.personalDataAgreementDate)) ||
      null;

    this.kind = data.kind || "spectator";

    this.country = data.country || null;
    this.city = data.city || null;
    this.state = data.state || null;
    // deal with tables of participants
    this.runTable.replace(data.runTable);
    this.rideTable.replace(data.rideTable);
    this.skiTable.replace(data.skiTable);

    this.registrationDate = moment(data.registrationDate);

    this.challengesInProgress.replace(data.challengesInProgress);
    this.challengesDone.replace(data.challengesDone);
    this.challengesFailed.replace(data.challengesFailed);

    this.rank = data.rank || null;

    this.pending = false;
    this.full = true;

    this.getImage();
  }

  @action
  async getImage() {
    if (this.image) {
      this.setPendingImage(true);
      const img = new Image();
      img.src = this.image;
      img.onload = () => {
        this.setPendingImage(false);
      };
      img.onerror = () => {
        this.dropImage();
        this.setPendingImage(false);
      };
    }
  }

  @action
  dropImage() {
    this.image = null;
  }

  @action
  setBirthday(birthday) {
    this.birthday = (birthday && moment(birthday)) || null;
  }

  @action
  setEmail(email) {
    this.email = email || null;
  }

  @action
  setSex(sex) {
    this.sex = sex || "F";
  }

  @action
  setPersonalDataAgreementDate(personalDataAgreementDate) {
    this.personalDataAgreementDate =
      (personalDataAgreementDate && moment(personalDataAgreementDate)) || null;
  }

  @action
  setCompanyId(companyId) {
    this.companyId = companyId;
  }

  @action
  setPendingImage(pending = false) {
    this.pendingImage = pending;
  }

  @action
  setFirstName(firstName) {
    this.firstame = firstName || null;
  }

  @action
  setLastName(lastName) {
    this.lastname = lastName || null;
  }

  @computed
  get isArchievementsPending() {
    return this.pendingArchievements;
  }

  @computed
  get isPending() {
    return this.pending;
  }

  @computed
  get totalActtivities() {
    return this.activitiesMap.size;
  }

  @computed
  get activitiesArray() {
    return this.activities.slice().reverse();
  }

  @computed
  get isPendingImage() {
    return this.pendingImage;
  }

  @computed
  get name() {
    return `${this.lastname} ${this.firstname}`;
  }

  @computed
  get username() {
    return `${this.lastname} ${this.firstname}`;
  }

  @computed
  get firstName() {
    return this.firstname;
  }

  @computed
  get lastName() {
    return this.lastname;
  }

  @computed
  get distanceKM() {
    return Number(this.distance / 1000).toFixed(2);
  }

  @computed
  get hours() {
    return Math.floor(this.movingTime / 3600);
  }

  @computed
  get minutes() {
    return Math.floor(this.movingTime / 60) % 60;
  }

  @computed
  get seconds() {
    return this.movingTime % 60;
  }

  @computed
  get company() {
    const company =
      this.companyId && this.companiesStore.getCompany(`${this.companyId}`);
    return company;
  }

  @computed
  get photo() {
    return this.image;
  }

  @computed
  get isAthlete() {
    return this.kind === "athlete";
  }

  @computed
  get canMatchWithAthlete() {
    return !this.isAthlete;
  }

  @computed
  get address() {
    if (!(this.country || this.state || this.city)) {
      return null;
    }
    return `${this.country || ""} ${this.state || ""} ${this.city}`;
  }

  @computed
  get male() {
    return this.sex === "M" || this.sex === "m";
  }

  @computed
  get isMale() {
    return this.male;
  }

  @computed
  get hasRequiredData() {
    if (!this.isAthlete) {
      return true;
    }
    return !!(this.personalDataAgreementDate && this.birthday && this.email);
  }
}
