import React, { useCallback } from "react";
import { observer } from "mobx-react";

const Promocodes = observer(({ code, type }) => {
  const onClick = useCallback(() => {
    navigator &&
      navigator.clipboard &&
      navigator.clipboard.writeText &&
      navigator.clipboard.writeText(code.value);
  }, [code]);
  return (
    <div className={`code-card challenge-card ${type}`} key={`${code.value}`}>
      <div className={`title gradient ${type}`}>{code.name}</div>
      <div className="description">{code.description}</div>
      <div onClick={onClick} className="code">
        {code.value}
      </div>
    </div>
  );
});

export default Promocodes;
