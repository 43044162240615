import { observable, action, computed, runInAction } from "mobx";
import Club from "../models/Club";

const DEV = !process.env.NODE_ENV || process.env.NODE_ENV === "development"; // eslint-disable-line

const reminderRevision = "2";

export default class AccountStore {
  @observable
  token = null;
  @observable
  rootStore = null;

  @observable
  reminder = localStorage.getItem("reminder") === reminderRevision || null;
  @observable
  profile = null;

  @observable
  passConnectionWarning = false;

  @observable
  defaultClub = null;

  @observable
  clubs = new Map();

  @observable
  endpoints = new Map();

  @observable
  clubId = null;

  @observable
  pending = false;
  @observable
  pendingAthletePhoto = false;
  @observable
  emailChanged = false;
  @observable
  formPassed = false;
  @observable
  pendingInfo = false;

  @observable
  personalData = null;
  @observable
  personalPending = false;

  @observable
  activitySendSuccess = false;

  constructor(store, clubId) {
    this.rootStore = store;
    this.athletesStore = this.rootStore.athletesStore;
    this.seasonsStore = this.rootStore.seasonsStore;
    this.companiesStore = this.rootStore.companiesStore;
    this.api = this.rootStore.api;

    if (
      clubId !== null &&
      clubId !== "null" &&
      clubId !== undefined &&
      clubId !== "undefined"
    ) {
      this.setClubId(clubId);
    }

    this.defaultClub = new Club(
      { id: "vmarafone", logo: null, name: "vmarafone.club" },
      this
    );
    
    const token = localStorage.getItem("token");
    this.setToken(token);
  }

  async getPersonal() {
    if (!this.isPersonalPending) {
      this.setPersonalPending(true);
      const personalData = await this.api.getPersonal();
      this.setPersonalData(personalData);
      this.setPersonalPending();
    }
  }

  @action
  setPersonalData(data) {
    this.personalData = (data && data.content) || null;
  }

  @action
  setPersonalPending(pending = false) {
    this.personalPending = pending;
  }

  @action
  async updateProfile(id) {
    try {
      this.setPending(true);
      this.profile.getFullInfo(null, id);
    } catch (err) {
      console.warn(err);
    }
    this.setPending();
  }

  @action
  async getEndpoints() {
    // api/v0/provider-polar/auth-endpoint
    this.endpoints.clear();
    this.setEndpoint(
      "manual",
      `${
        DEV ? "http://10.25.3.2:8000" : ""
      }/api/v0/provider-manual/action-register`
    );
    const polarData = await this.api.getPolarEndpoint();
    if (polarData && polarData.endpoint) {
      this.setEndpoint("polar", polarData.endpoint);
    }
  }

  @action
  setEndpoint(id, point) {
    this.endpoints.set(id, point);
  }

  @action
  async init() {
    if (this.isLoggedIn) {
      try {
        this.setPending(true);
        this.clubs.clear();
        await this.companiesStore.init();
        const user = await this.api.getProfile();
        if (user) {
          runInAction(() => {
            this.profile = this.athletesStore.createAthlete(user, this);
          });
          await this.getEndpoints();
          const clubs = await this.api.getClubs();
          if (clubs) {
            this.setClubs(clubs);
          }
          if (!this.needToFillData) {
            await this.seasonsStore.init(this.currentClubId);
            this.seasonsStore.initChallengesStore();
          }
        }
      } catch (err) {
        console.warn(err);
      }
      this.setPending();
    }
  }

  @action
  setClubs(clubs) {
    clubs.forEach((club) => {
      const newClub = new Club(club, this);
      this.clubs.set(newClub.id, newClub);
    });
    if (
      !this.clubId &&
      clubs.length &&
      (location.pathname === "/" || location.pathname === "")
    ) {
      // если мы на главной, у user есть клубы и в url нет клуба то включаем первый из списка
      this.setClubId(`${clubs[0].id}`);
    }
  }

  @action
  setClubId(id) {
    this.clubId = id;
    this.rootStore.changeURLSearch();
    this.seasonsStore.init(this.currentClubId);
    const oldLink = document.getElementById("club_style");
    oldLink && oldLink.parentNode.removeChild(oldLink);
    const linkElement = document.createElement("link");
    linkElement.setAttribute("id", "club_style");
    linkElement.setAttribute("rel", "stylesheet");
    linkElement.setAttribute("type", "text/css");
    linkElement.setAttribute("href", `/api/v0/clubs/${id}/style`);
    document.body.appendChild(linkElement);
  }

  @action
  setToken(token) {
    this.token = token;
    this.init();
  }

  @action
  setAppleToken(token) {
    if (!!token && token !== "") {
      // signed in
      localStorage.setItem("token", token);
      this.token = token;
      history.pushState({}, "", "/");
      this.init();
    }
  }

  @action
  async login() {
    await this.api.login("yasithartha", "ghautama");
  }

  @action
  async logout() {
    localStorage.removeItem("token");
    await this.api.logout();
  }

  @action
  async loginStrava() {
    await this.api.login("ssithartha", "ghautama");
  }

  @action
  setPending(pending = false) {
    this.pending = pending;
  }

  @action
  setPendingInfo(pending = false) {
    this.pendingInfo = pending;
  }

  @action
  async sendInfo(
    birthday,
    email,
    firstName,
    lastName,
    sex = "F",
    personalConfirm,
    reminder
  ) {
    this.setPending(true);
    const personalDate = new Date();
    await this.api.sendInfo(
      email,
      birthday && birthday.format("YYYY-MM-DD"),
      firstName,
      lastName,
      sex,
      personalConfirm ? personalDate : null
    );
    if (email) {
      if (email !== this.profile.email) {
        this.setEmailChanged(true);
      }
      this.profile.setEmail(email);
    }
    if (birthday) {
      this.profile.setBirthday(birthday);
    }
    if (reminder) {
      this.setReminder(reminder);
    }
    if (firstName) {
      this.profile.setFirstName(firstName);
    }
    if (lastName) {
      this.profile.setLastName(lastName);
    }
    if (sex) {
      this.profile.setSex(sex);
    }
    if (personalConfirm) {
      this.profile.setPersonalDataAgreementDate(personalDate);
    }
    this.setFormPassed();
    this.setPending();
  }

  @action
  setReminder(reminder) {
    this.reminder = reminder || null;
    localStorage.setItem("reminder", reminder ? reminderRevision : undefined);
  }

  @action
  setEmailChanged(emailChanged = false) {
    this.emailChanged = emailChanged;
  }

  @action
  setFormPassed() {
    this.formPassed = true;
    this.seasonsStore.init(this.currentClubId);
  }

  @action
  setPassConnectionWarning() {
    this.passConnectionWarning = true;
  }

  @action
  async sendGPX(data) {
    return await this.api.sendGPX(data);
  }

  @action
  async sendActivity(data) {
    await this.api.sendActivity(this.meId, data);
  }

  @action
  async sendPhoto(data) {
    this.setPendingPhoto(true);
    const user = await this.api.sendPhoto(data);
    if (user) {
      this.profile.setFullInfo(user);
    }
    this.setPendingPhoto(false);
  }

  @action
  async sendActivityPolar(id, data) {
    const newActivityData = await this.api.sendActivityPolar(id, data);
    this.profile.updateActivityData(id, newActivityData);
    this.setActivitySendSuccess(true);
  }

  @action
  setActivitySendSuccess(success = false) {
    this.activitySendSuccess = success;
  }

  @action
  setPendingPhoto(pending = false) {
    this.pendingAthletePhoto = pending;
  }

  
  @computed
  get isPendingPhoto() {
    return this.pendingAthletePhoto;
  }

  @computed
  get isActivitySendSuccess() {
    return this.activitySendSuccess;
  }

  @computed
  get canMatchWithAthlete() {
    return (
      this.profile &&
      this.profile.canMatchWithAthlete &&
      !this.passConnectionWarning
    );
  }

  @computed
  get isPersonalPending() {
    return this.personalPending;
  }

  @computed
  get isPending() {
    return this.pending;
  }

  @computed
  get isEmailChanged() {
    return this.emailChanged;
  }

  @computed
  get canSwitch() {
    return this.clubs.size > 0 && !this.needToFillData;
  }

  @computed
  get isPendingInfo() {
    return this.pendingInfo;
  }

  @computed
  get username() {
    return this.profile && this.profile.username;
  }

  @computed
  get email() {
    return (this.profile && this.profile.email) || null;
  }

  @computed
  get canLoadManual() {
    return (
      this.profile &&
      this.profile.providers &&
      this.profile.providers.manual > 0
    );
  }

  @computed
  get endpointsArray() {
    const array = [];
    this.endpoints.forEach((point, id) => {
      array.push({
        id,
        point,
        checked:
          this.profile &&
          this.profile.providers &&
          this.profile.providers[id] > 0,
      });
    });
    return array;
  }

  @computed
  get date() {
    return (
      (this.profile &&
        this.profile.birthday &&
        this.profile.birthday.format("YYYY-MM-DD")) ||
      null
    );
  }

  @computed
  get meId() {
    return this.profile && this.profile.id;
  }

  @computed
  get firstName() {
    return this.profile && this.profile.firstName;
  }

  @computed
  get lastName() {
    return this.profile && this.profile.lastName;
  }

  @computed
  get isAthlete() {
    return this.profile && this.profile.isAthlete;
  }

  @computed
  get myTeamId() {
    return this.profile && this.profile.companyId;
  }

  @computed
  get currentClub() {
    return this.clubs.get(this.clubId) || null;
  }

  @computed
  get currentClubId() {
    return this.clubId && this.clubId !== "vmarafone" && `${this.clubId}`;
  }

  @computed
  get athleteId() {
    return this.profile && this.profile.isAthlete && this.profile.id;
  }

  @computed
  get isMale() {
    if (!this.isAthlete) {
      return true;
    }
    return !!(this.profile && this.profile.male);
  }

  @computed
  get clubList() {
    const clubs = [this.defaultClub];
    this.clubs.forEach((club) => {
      clubs.push(club);
    });
    return clubs;
  }

  @computed
  get needToFillData() {
    if (
      this.isPending ||
      this.isPendingInfo ||
      this.formPassed ||
      !this.profile ||
      !this.isAthlete ||
      this.reminder
    ) {
      return false;
    }
    return !this.profile.hasRequiredData;
  }

  @computed
  get birthYear() {
    return (
      (this.profile && this.profile.birthday && this.profile.birthday.year()) ||
      null
    );
  }

  @computed
  get personalDataAgreementDate() {
    return (
      (this.profile &&
        this.profile.personalDataAgreementDate &&
        this.profile.personalDataAgreementDate.format("DD.MM.YYYY")) ||
      null
    );
  }

  @computed
  get personalContent() {
    return this.personalData;
  }

  @computed
  get isLoggedIn() {
    return !!this.token;
  }
}
