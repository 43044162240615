import { observer } from "mobx-react";
import React from "react";

import useStores from "~/hooks";

const GroupButton = observer(({ id, title, icon }) => {
  const { rootStore } = useStores();
  const { kind } = rootStore;

  const onPress = () => {
    if (window.navigator.vibrate) {
      window.navigator.vibrate([30]);
    }

    rootStore.setType(id);
  };

  return (
    <div 
      className={`group-button ${id === kind ? "active" : ""}`}
      onClick={onPress}
    >
      {icon}
      <span className="title">{title}</span>
    </div>
  );
});
export default GroupButton;
