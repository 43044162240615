import React, { useEffect } from "react";
import { IoIosStar, IoIosClose } from "react-icons/io";
import { observer } from "mobx-react";

import useStores from "~/hooks";

import { Preloader, ActivitiesList } from "~/components/common";
import MultiSwitch from "~/components/multiSwitch";
import CompetitionCard from "./CompetitionCard";

const AthleteInCompetition = observer(({ challengeId, athleteId }) => {
  const { rootStore, seasonsStore, accountStore } = useStores();
  const { athleteId: accountId } = accountStore;
  const { kind, sex } = rootStore;
  const { currentSeason, currentSeasonId } = seasonsStore;
  const isMale = sex !== "females";

  useEffect(() => {
    seasonsStore.initChallengesStore();
  }, [kind, currentSeasonId]);
  const { challengesStore } = currentSeason || {};
  const { isPending } = challengesStore || {};
  const challenge =
    challengesStore &&
    challengesStore.competitions &&
    challengesStore.competitions.get(`${challengeId}`);

  useEffect(() => {
    challenge && challenge.getActivitiesInfo(athleteId);
  }, [kind, currentSeasonId, challengeId, challenge, isMale]);

  if (!currentSeason) {
    return (
      <div className="challenges page">
        <div className="page-header">
          <IoIosStar /> Вызов
        </div>
        <div className="page-warning">
          <IoIosClose /> Не выбран сезон
        </div>
      </div>
    );
  }

  if (isPending) {
    return <Preloader />;
  }

  if (!challenge) {
    return <Preloader />;
  }

  const { isActivitiesPending } = challenge;
  const activities = challenge.getActivities(athleteId);
  const activitiesForCalendar = challenge.getActivitiesForCalendar(athleteId);

  return (
    <div className="challenges info page">
      <MultiSwitch
        page="competitions"
        id={challengeId}
        linkPrefix={"/"}
        noSport={true}
        noMonth={true}
        noSeason={true}
      />
      <CompetitionCard
        challenge={challenge}
        showConditions={true}
        showDownloadButtons={true}
        isAnnotation={true}
        isDirectAthleteLink={true}
        athleteId={athleteId || accountId}
      />
      <ActivitiesList
        noDistance={true}
        athleteId={athleteId}
        challenge={challenge}
        activitiesForCalendar={activitiesForCalendar}
        activities={activities}
        isPending={isActivitiesPending}
      />
    </div>
  );
});

export default AthleteInCompetition;
