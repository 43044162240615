import React from "react";
import { observer } from "mobx-react";

const Month = observer(({ month }) => {
  return (
    <div className={"season"}>
      <span className="season-title">{month && month.name}</span>
    </div>
  );
});
export default Month;
