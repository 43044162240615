import { observable, action, computed } from "mobx";
import Challenge from "../models/Challenge";
import Competition from "../models/Competition";
import MultisportCompetition from "../models/MultisportCompetition";
import TeamChallenge from "../models/TeamChallenge";

export default class ChallengesStore {
  @observable
  challenges = new Map();

  @observable
  competitions = new Map();

  @observable
  multisportCompetitions = new Map();

  @observable
  teamChallenges = new Map();

  @observable
  season = null;
  @observable
  rootStore = null;
  @observable
  seasonsStore = null;

  @observable
  pending = false;

  @observable
  disabledCompetitions = new Map();

  constructor(season) {
    this.season = season;
    this.seasonsStore = this.season.store;
    this.rootStore = this.seasonsStore.rootStore;
    this.api = this.rootStore.api;
  }

  @action
  disable(ids = []) {
    ids.forEach((id) => {
      this.disabledCompetitions.set(`${id}`, true);
    });
  }

  @action
  addCompetition(item) {
    this.competitions.set(`${item.id}`, item);
  }

  @action
  async init() {
    if (!this.isPending && this.rootStore.kind !== "all") {
      this.setPending(true);
      this.challenges.clear();
      this.competitions.clear();
      this.multisportCompetitions.clear();
      this.teamChallenges.clear();

      if (this.rootStore.kind === "multisport") {
        const competitions = await this.api.getMultisportCompetitions(this.season.id);
        competitions.forEach((competitionData, i) => {
          const data = { ...competitionData, position: competitionData.position || i };
          const item = new MultisportCompetition(data, this);
          this.multisportCompetitions.set(`${item.id}`, item);
        });
      } else {
        const challenges = await this.api.getChallenges(this.season.id, this.rootStore.kind);
        challenges.forEach((challengeData, i) => {
          const data = { ...challengeData, position: challengeData.position || i };
          const item = new Challenge(data, this);
          this.challenges.set(`${item.id}`, item);
        });
  
        const competitions = await this.api.getCompetitions(this.season.id, this.rootStore.kind);
        competitions.forEach((competitionData, i) => {
          const data = { ...competitionData, position: competitionData.position || i };
          const item = new Competition(data, this);
          this.addCompetition(item);
        });
  
        const teamChallenges = await this.api.getTeamChallenges(this.season.id, this.rootStore.kind);
        teamChallenges.forEach((challengeData, i) => {
          const data = { ...challengeData, position: challengeData.position || i };
          const item = new TeamChallenge(data, this);
          this.teamChallenges.set(`${item.id}`, item);
        });  
      }

      this.setPending(false);
    }
  }
  
  @action
  processRegistrations(registrationsData = {}) {
    Object.keys(registrationsData).forEach((key) => {
      if (key === "challenges") {
        const array = registrationsData[key];
        array.forEach((item) => {
          const challenge = this.challenges.get(`${item.challengeId}`);
          if (challenge && item.status) {
            challenge.setStatus(item.status);
          }
        });
      } else if (key === "competitions") {
        const array = registrationsData[key];
        array.forEach((item) => {
          const challenge = this.competitions.get(`${item.competitionId}`);
          if (challenge && item.status) {
            challenge.setStatus(item.status);
          }
        });
      } else if (key === "multisportCompetitions") {
        const array = registrationsData[key];
        array.forEach((item) => {
          const challenge = this.multisportCompetitions.get(`${item.competitionId}`);
          if (challenge && item.status) {
            challenge.setStatus(item.status);
          }
        });
      }
    });
  }

  @action
  setPending(pending = false) {
    this.pending = pending;
  }

  @computed
  get isPending() {
    return this.pending;
  }

  @computed
  get currentMonth() {
    return this.season.currentMonth;
  }

  @computed
  get teamChallengeList() {
    const challengesArray = [];
    if (!this.currentMonth) {
      return [];
    }
    this.teamChallenges.forEach((challenge) => {
      const { start, finish } = challenge;
      let fits = true;
      const monthStart = this.currentMonth.fromMoment;
      const monthEnd = this.currentMonth.toMoment;
      if (start > monthEnd || finish < monthStart) {
        fits = false;
      }
      if (fits) {
        challengesArray.push(challenge);
      }
    });
    return challengesArray;
  }

  @computed
  get competitionList() {
    const challengesArray = [];
    if (!this.currentMonth) {
      return [];
    }
    this.competitions.forEach((challenge) => {
      const { start, finish } = challenge;
      let fits = !challenge.isSlave;
      const monthStart = this.currentMonth.fromMoment;
      const monthEnd = this.currentMonth.toMoment;
      if (start > monthEnd || finish < monthStart) {
        fits = false;
      }
      if (fits) {
        challengesArray.push(challenge);
      }
    });
    return challengesArray;
  }

  @computed
  get multisportCompetitionList() {
    const challengesArray = [];
    if (!this.currentMonth) {
      return [];
    }
    this.multisportCompetitions.forEach((challenge) => {
      const { start, finish } = challenge;
      let fits = true;
      const monthStart = this.currentMonth.fromMoment;
      const monthEnd = this.currentMonth.toMoment;
      if (start > monthEnd || finish < monthStart) {
        fits = false;
      }
      if (fits) {
        challengesArray.push(challenge);
      }
    });
    return challengesArray;
  }

  @computed
  get challengeList() {
    const challengesArray = [];
    if (!this.currentMonth) {
      return [];
    }
    this.challenges.forEach((challenge) => {
      const { start, finish } = challenge;
      let fits = true;
      const monthStart = this.currentMonth.fromMoment;
      const monthEnd = this.currentMonth.toMoment;
      if (start > monthEnd || finish < monthStart) {
        fits = false;
      }
      if (fits) {
        challengesArray.push(challenge);
      }
    });
    return challengesArray;
  }

  @computed
  get rangedChallengeList() {
    const allChallengeArray = [].concat(
      this.teamChallengeList, 
      this.multisportCompetitionList, 
      this.competitionList, 
      this.challengeList
    );
    const result = allChallengeArray.sort((a, b) => {
      return Number(a.position) - Number(b.position);
    });
    return result;
  }
}
