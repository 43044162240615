import React, { useCallback, useEffect, useMemo } from "react";
import { observer } from "mobx-react";

import {
  IoIosCheckmarkCircleOutline,
  IoIosStar,
  IoIosAdd,
  IoIosRemove,
  IoIosPodium,
  IoIosMan,
  // IoIosAddCircleOutline,
  // IoIosHourglass,
  IoIosCloudOutline,
  IoIosAddCircleOutline,
  IoIosHourglass,
} from "react-icons/io";
import { AiOutlineCalendar } from "react-icons/ai";
import { GiTwoCoins } from "react-icons/gi";

import useStores from "~/hooks";

import { Top, Rules, NavLink, Preloader } from "~/components/common";

import KindCard from "~/components/multiSwitch/KindCard";
import Printables from "./Printables";

const CompetitionCard = observer(
  ({
    isAnnotation,
    challenge,
    athleteId,
    showConditions,
    isDirectAthleteLink,
    showDiscipline,
    showDownloadButtons,
    showSubCompetitions,
  }) => {
    const {
      rootStore,
      accountStore,
      athletesStore,
      seasonsStore,
    } = useStores();
    const { isAthlete, isMale, athleteId: meId } = accountStore;
    const { type, sex, getTypeByKind } = rootStore;
    const { currentSeasonId } = seasonsStore;

    const forMale = sex === "males";
    const sexFits = forMale === isMale;

    const athlete = athletesStore.getAthlete(athleteId);

    useEffect(() => {
      athlete.getFullInfo(null, currentSeasonId);
    }, [athleteId, currentSeasonId]);

    const {
      id,
      name,
      kind,
      start,
      finish,
      isPending,
      status,
      penalty,
      reward,
      points,
      filterNames,
      awardingRulesNames,
      isCompetition,
      isMultisport,
      top,
      forSex,
      isSlave,
      disciplines,
      numberOfAthletes,
      isAnnotationPending,
      isSigning,
      canSign,
      rangedChallengeList,
    } = challenge;

    const isAdditionalInfo =
      (showConditions || (athleteId === meId && sexFits)) && athleteId;

    const signTo = useCallback(() => {
      challenge.signTo();
    }, [challenge]);

    useEffect(() => {
      if (isAnnotation) {
        const athlete = isAdditionalInfo ? athleteId : undefined;
        challenge.getAnnotation(sex, athlete, showConditions && 1);
      }
    }, [
      sex,
      isAthlete,
      isAnnotation,
      status,
      sexFits,
      athleteId,
      showConditions,
    ]);

    const footer = useMemo(() => {
      if (!(isAthlete && sexFits)) {
        return null;
      }
      if (canSign) {
        return (
          <div className={`button gradient ${type}`} onClick={signTo}>
            {!isSigning ? (
              <IoIosAddCircleOutline />
            ) : (
              <IoIosHourglass className="rotating" />
            )}{" "}
            Записаться
          </div>
        );
      }

      if (status === "onapproval") {
        return (
          <div className="info-button">
            <IoIosCloudOutline /> На подтверждении
          </div>
        );
      }

      if (status === "approved") {
        return (
          <div className="info-button green">
            <IoIosCheckmarkCircleOutline /> Участвую
          </div>
        );
      }
    }, [isAthlete, sexFits, canSign, status, type]);

    if (isPending || isAnnotationPending) {
      return <Preloader />;
    }

    if (
      !(isMultisport && forSex === sex) &&
      (!filterNames || !filterNames[sex] || !filterNames[sex].length)
    ) {
      return null;
    }

    let content = null;

    const ids = top[sex];

    content = (
      <React.Fragment>
        {(!isAnnotation || showConditions) && (
          <Rules
            key="rules"
            filters={
              (!isAnnotation || showConditions || status !== "approved") &&
              filterNames
            }
            awardingRules={
              (!isAnnotation || showConditions || status !== "approved") &&
              awardingRulesNames
            }
            canChangeSex={false}
            showMale={forMale}
            showFemale={!forMale}
          />
        )}
        {isAnnotation &&
        (athleteId !== meId || status === "approved" || isCompetition) && (
          <Top
            noNumbers={!isCompetition}
            key="top"
            ids={ids}
            linkPrefix={isDirectAthleteLink ? "/" : `/competitions/${id}/`}
          />
        )}
        {isCompetition &&
          (`${athleteId}` === `${meId}` || !athleteId) &&
          showDownloadButtons && <Printables competition={challenge} />}
      </React.Fragment>
    );

    const sports = [];
    if (isMultisport) {
      disciplines.forEach((kind) => {
        sports.push(
          <KindCard
            disabled={true} key={kind} kind={kind}
            active={true}
          />
        );
      });
    }

    const competitions = [];
    if (rangedChallengeList && showSubCompetitions) {
      rangedChallengeList.forEach((competition) => {
        competitions.push(
          <CompetitionCard
            athleteId={athleteId}
            showDiscipline={true}
            key={`${competition.class}-${competition.id}`}
            challenge={competition}
          />
        );
      });
    }

    return (
      <div
        className={
          `challenge-card ${showDiscipline || isSlave ? getTypeByKind(kind) : type}`
        }
      >
        <NavLink
          className={
            `gradient ${showDiscipline || isSlave ? getTypeByKind(kind) : type
            } ${isMultisport ? "multisport-competitions" : ""}`
          }
          to={
            `/${isMultisport ? "multisport-competitions" : "competitions"}/${id}/`
          }
        >
          {isMultisport && <div className="multisport-kinds">{sports}</div>}
          {(showDiscipline || isSlave) && (
            <KindCard disabled={true} active={true} kind={kind} />
          )}
          <div className="name">
            {isCompetition && !isMultisport && !(showDiscipline || isSlave) && (
              <IoIosPodium />
            )}
            {name}
          </div>
          {numberOfAthletes !== null && (
            <div className="participants">
              <IoIosMan /> {numberOfAthletes}
            </div>
          )}
        </NavLink>
        <div className="info">
          <div className="time-last">
            <AiOutlineCalendar /> {start.format("D MMMM")} &mdash;{" "}
            {finish.format("D MMMM")}
          </div>
          {content}
          {isMultisport && showSubCompetitions && (
            <div className="competitions-widget-holder">
              <div className="competitions-card-holder">{competitions}</div>
            </div>
          )}
          <div className="status-holder">
            <div className="star-holder">
              {!!reward && (
                <div className="reward">
                  <div className="value">
                    <IoIosAdd />
                    <IoIosStar />
                    {reward}
                  </div>
                </div>
              )}
              {!!penalty && (
                <div className="penalty">
                  <div className="value">
                    <IoIosRemove />
                    <IoIosStar />
                    {penalty}
                  </div>
                </div>
              )}
            </div>
            <div className="points-holder">
              {!!points && (
                <div className="reward">
                  <div className="value">
                    <GiTwoCoins />
                    {points}
                  </div>
                </div>
              )}
            </div>

            {footer}
          </div>
        </div>
      </div>
    );
  }
);

export default CompetitionCard;
