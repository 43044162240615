import React, { useEffect, useMemo } from "react";
import { observer } from "mobx-react";
import {
  IoIosStar,
  IoIosExit,
  IoIosContact,
  IoLogoInstagram,
  IoIosMail,
  IoIosPaper,
  IoIosPeople,
  IoIosPodium,
  IoIosTv,
  IoIosFitness,
  IoIosSettings,
  IoIosCloudUpload,
} from "react-icons/io";
import { FaFilePdf, FaInfoCircle } from "react-icons/fa";
import { slide as Menu } from "react-burger-menu";  
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "moment/locale/ru";

import useStores from "~/hooks";

import { doesHttpOnlyCookieExist } from "~/helpers/getCoockie";

import "~/App.scss";

import TransitionRouter from "~/TransitionRouter";
import Main from "~/views/Main";
import Login from "~/views/Login";
import Challenges from "~/views/Challenges";
import Competitions from "~/views/Competitions";
import AthletePage from "~/views/AthletePage";
import InitialForm from "~/views/InitialForm";
import TopPage from "~/views/TopPage";
import NotFound from "~/views/NotFound";
import News from "~/views/News";
import Faq from "~/views/Faq";
import Team from "~/views/Team";
import RatingPage from "~/views/RatingPage";
import TeamInChallenge from "~/views/TeamInChallenge";

import { NavLink, Preloader, Sex } from "~/components/common";
import { ClubSwitch } from "~/components/club";
import { ChallengeInfo, AthleteInChallenge } from "~/components/challenge";
import { NewsFull } from "~/components/news";
import { TeamChallengeInfo } from "~/components/team";
import { CompetitionInfo, AthleteInCompetition } from "~/components/competition";
import RatingListPage from "./views/RatingListPage";
import PersonalDataAgreement from "./views/PersonalDataAgreement";
import ActivityForm from "./views/ActivityForm";
import Settings from "./views/Settings";

import "react-toastify/dist/ReactToastify.css";
import TeamRatingPage from "./views/TeamRatingPage";
import TeamRatingListPage from "./views/TeamRatingListPage";
import GPXForm from "./views/GPXForm";
moment.locale("ru");

const App = observer(() => {
  const { rootStore, accountStore, seasonsStore } = useStores();
  const { type, isPending, isOpen } = rootStore;
  const {
    isLoggedIn,
    needToFillData,
    username,
    isActivitySendSuccess,
    canLoadManual,
    profile,
    myTeamId,
    isEmailChanged,
  } = accountStore;
  const { currentSeason } = seasonsStore;

  const isAthlete = useMemo(() => {
    return !!(profile && profile.isAthlete);
  }, [profile]);

  const token = useMemo(() => {
    const token = localStorage.getItem("token");
    if (token && token.lenght) {
      return token;
    }
    return doesHttpOnlyCookieExist("sessionid");
  }, []);

  const isPersonalPage = useMemo(() => {
    return location.pathname === "/personal/";
  }, [location.pathname]);

  const isRating = useMemo(() => {
    return location.pathname.indexOf("rating") !== -1;
  }, [location.pathname]);

  const onOpen = () => {
    rootStore.setIsOpen(true);
  };
  const onClose = () => {
    rootStore.setIsOpen(false);
  };

  useEffect(() => {
    if (token && !isLoggedIn) {
      rootStore.init(token);
    }
  }, [token, isLoggedIn]);

  const onExit = () => {
    accountStore.logout();
  };

  useEffect(() => {
    if (isEmailChanged && !isPending) {
      accountStore.setEmailChanged();
      // NOTE: this code reveals toast message about email confirmation
      // toast.success(
      //   <div>
      //     <div>
      //       <strong>Сохранено успешно!</strong>
      //     </div>
      //     <div>
      //       На указанный адрес email направлено письмо со ссылкой. Перейдите по
      //       ссылке в письме для подтверждения адреса email.
      //     </div>
      //   </div>,
      //   {
      //     position: toast.POSITION.TOP_CENTER,
      //   }
      // );
    }
    if (isActivitySendSuccess && !isPending) {
      accountStore.setActivitySendSuccess();
      // NOTE: this code reveals toast message about email confirmation
      toast.success(
        <div>
          <div>
            <strong>Сохранено успешно!</strong>
          </div>
          <div>Информация о тренировке успешно обновлена.</div>
        </div>,
        { position: toast.POSITION.TOP_CENTER }
      );
    }
  }, [isEmailChanged, isActivitySendSuccess, isPending]);

  const footer = useMemo(() => {
    return (
      <div className="footer">
        <div className="news-link">
          <NavLink to="/news/">
            <IoIosPaper />
          </NavLink>
        </div>
        <div className="left gradient ski">
          <a className="link" target="blank" href="https://www.instagram.com/vmarafone.club/">
            <IoLogoInstagram />
          </a>
        </div>
        <div className="middle gradient ride">
          <a className="link" href="mailto:admin@vmarafone.club">
            <IoIosMail />
          </a>
        </div>
        <div className="right gradient run">
          <div className="powered" />
        </div>
      </div>
    );
  }, []);

  if (!isLoggedIn) {
    return <Login />;
  }

  if (isPending) {
    return (
      <div id="outer-container" className={`${type}`}>
        <div id="page-wrap" className={`main ${type}`}>
          <div className="wrapper">
            <div className={`header gradient ${type}`}>
              <div className="angle-holder">
                <div className="angle"></div>
              </div>
              <ClubSwitch />
              <div className="menu"></div>
            </div>
            <div className="container">
              <Preloader />
            </div>
            {footer}
          </div>
        </div>
      </div>
    );
  }

  if (needToFillData && !isPersonalPage) {
    return <InitialForm />;
  }

  // if (canMatchWithAthlete) {
  //   return <ConnectAthleteWarning />;
  // }
  return (
    <div id="outer-container" className={`${type}`}>
      {!needToFillData && (
        <Menu
          right={true}
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          menuClassName={`bm-menu gradient ${type}`}
          pageWrapId={"page-wrap"}
          outerContainerId={"outer-container"}
        >
          <NavLink to="athletes/me">
            <div className="menu-item user">
              <IoIosContact />
              <div className="user">
                <div className="name">{username}</div>
                <div className="role">{isAthlete ? "Атлет" : "Зритель"}</div>
              </div>
            </div>
          </NavLink>
          {isAthlete && canLoadManual && (
            <NavLink to={"/activity-form"}>
              <div className="menu-item">
                <IoIosFitness /> Внести тренировку
              </div>
            </NavLink>
          )}
          {isAthlete && (
            <NavLink to={"/gpx-form"}>
              <div className="menu-item">
                <IoIosCloudUpload /> Загрузить GPX
              </div>
            </NavLink>
          )}

          <NavLink to={"/settings"}>
            <div className="menu-item">
              <IoIosSettings /> Настройки
            </div>
          </NavLink>
          {myTeamId && (
            <NavLink to={`/teams/${myTeamId}`}>
              <div className="menu-item">
                <IoIosPeople /> Моя команда
              </div>
            </NavLink>
          )}
          <NavLink to="/top/">
            <div className="menu-item">
              <IoIosStar /> Звезды
            </div>
          </NavLink>
          <NavLink to="/rating/">
            <div className="menu-item">
              <IoIosPodium /> Личные рейтинги
            </div>
          </NavLink>
          <NavLink to="/team-rating/">
            <div className="menu-item">
              <IoIosPodium /> Командные рейтинги
            </div>
          </NavLink>
          <NavLink to="/news/">
            <div className="menu-item">
              <IoIosPaper /> Новости
            </div>
          </NavLink>
          {currentSeason && currentSeason.regulations && (
            <a className="nav-link" target="blank" href={currentSeason.regulations}>
              <div className="menu-item link">
                <FaFilePdf /> Положение
              </div>
            </a>
          )}
          <a className="nav-link" target="blank" href="/api/v0/docs/presentation">
            <div className="menu-item link">
              <IoIosTv /> Презентация
            </div>
          </a>
          <NavLink to="/faq/">
            <div className="menu-item link">
              <FaInfoCircle /> FAQ
            </div>
          </NavLink>
          <a onClick={onExit} className="nav-link" href="">
            <div className="menu-item">
              <IoIosExit /> Выйти
            </div>
          </a>
          <div className="spacer"></div>
          <a
            className="nav-link link"
            target="blank"
            href="https://www.instagram.com/vmarafone.club/"
          >
            <div className="menu-item link">
              <IoLogoInstagram /> Instagram
            </div>
          </a>
          <a className="nav-link link" href="mailto:admin@vmarafone.club">
            <div className="menu-item link">
              <IoIosMail /> Эл. почта
            </div>
          </a>
        </Menu>
      )}
      <div id="page-wrap" className={`main ${type}`}>
        <div className="wrapper">
          <div className={`header gradient ${type}`}>
            <div className="angle-holder">
              <div className="angle"></div>
            </div>
            <ClubSwitch disabled={needToFillData} />
            <div className="menu">
              <Sex keepPage={isRating} disabled={needToFillData} />
            </div>
          </div>
          <div className="container">
            <TransitionRouter>
              <Main path="/" />
              <Faq path="/faq/" />
              <PersonalDataAgreement path="/personal/" />
              <NotFound default={true} />
              <News path="/news/" />
              <Challenges path="/challenges/" />
              <TeamChallengeInfo path="/team-challenges/:challengeId/" />
              <ChallengeInfo path="/challenges/:challengeId/" />
              <CompetitionInfo path="/competitions/:challengeId/" />
              <CompetitionInfo isMultisport={true} path="/multisport-competitions/:challengeId/" />
              <Team path="/teams/:id/" />
              <NewsFull path="/news/:id/" />
              <Competitions path="/competitions/" />
              <AthletePage path="/athletes/:athleteId/" />
              <AthletePage path="/athletes/:athleteId/:showModal/" />
              <TeamInChallenge path="/team-challenges/:challengeId/teams/:teamId/" />
              <AthleteInChallenge path="/challenges/:challengeId/athletes/:athleteId/" />
              <AthleteInCompetition path="/competitions/:challengeId/athletes/:athleteId/" />
              <TopPage path="/top/" />
              <RatingListPage path="/rating/" />
              <TeamRatingListPage path="/team-rating/" />
              <RatingPage path="/rating/:ratingId/" />
              <TeamRatingPage path="/team-rating/:ratingId/" />
              <ActivityForm path="/activity-form" />
              <GPXForm path="/gpx-form" />
              <Settings path="/settings" />
            </TransitionRouter>
          </div>
          {footer}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
});

export default App;
