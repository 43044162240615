import React from "react";
import { observer } from "mobx-react";

import { IoIosClose } from "react-icons/io";

import useStores from "~/hooks";

import MultiSwitch from "~/components/multiSwitch";
import { AthleteProfile } from "~/components/athlete";

const AthletePage = observer(({ athleteId, showModal }) => {
  const { seasonsStore, rootStore } = useStores();
  const { kind } = rootStore;
  const { currentSeasonId } = seasonsStore;

  if (!currentSeasonId) {
    return (
      <div className="challenges page">
        <div className="page-warning"><IoIosClose /> Нет доступных сезонов</div>
      </div>
    );
  }

  return (
    <div className="athlete-page page">
      <MultiSwitch
        noSex={true} 
        page="seasons"
        noMultisport={true}
        withEmptySport={true}
        withEmptyMonth={true}
        id={currentSeasonId}
      />
      <AthleteProfile
        kind={kind}
        showModal={showModal}
        currentSeasonId={currentSeasonId}  
        id={athleteId}
      />
    </div>
  );
});

export default AthletePage;
