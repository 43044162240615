import { observable, computed } from "mobx";
import moment from "moment";

export default class Month {
  @observable
  store = null;
  @observable
  rootStore = null;
  @observable
  index = null;
  @observable
  start = moment();
  @observable
  end = moment();
  @observable
  date = null;

  constructor(data, store) {
    this.store = store;
    this.rootStore = this.store.rootStore;

    this.index = data.index;
    this.start = data.start;
    this.end = data.end;
    this.date = data.date || null;
  }

  @computed
  get canAssignTo() {
    let canAssign = false;
    const list = this.store.challengesStore.rangedChallengeList;
    list.forEach((challenge) => {
      if (challenge.canMultiSign) {
        canAssign = true;
      }
    });
    return canAssign;
  }

  @computed
  get id() {
    return this.date && this.date.format("MM.YYYY") || "full-season";
  }

  @computed
  get name() {
    return this.date && this.date.format("MMMM") || "Весь сезон";
  }

  @computed
  get fromMoment() {
    const from = moment(this.start).startOf("day")
      .add(12, "hours")
      .local()
      .utc(true);
    return from;
  }

  @computed
  get toMoment() {
    const to = moment(this.end).startOf("day")
      .add(12, "hours")
      .local()
      .utc(true);
    return to;
  }

  @computed
  get from() {
    const from = this.fromMoment.toISOString();
    return from;
  }

  @computed
  get to() {
    const to = this.toMoment.toISOString();
    return to;
  }
}
