import { observable, action, computed, runInAction } from "mobx";
import moment from "moment";
import Season from "~/models/Season";

export default class SeasonsStore {
  @observable
  seasons = new Map();
  @observable
  currentSeasonId = null;
  @observable
  rootStore = null;
  @observable
  athletesStore = null;
  @observable
  challengesStore = null;
  @observable
  page = 1;
  @observable
  perPage = 20;

  @observable
  pending = false;

  @observable
  initialMonthId = moment().format("MM.YYYY");

  constructor(store, seasonId, monthId) {
    this.rootStore = store;
    this.athletesStore = this.rootStore.athletesStore;
    this.api = this.rootStore.api;

    if (monthId) {
      this.initialMonthId = monthId;
    }
    this.currentSeasonId = seasonId;
  }

  @action
  initChallengesStore() {
    this.currentSeason && this.currentSeason.initChallengesStore();
  }

  @action
  async setSeasonId(id) {
    if (window.navigator.vibrate) {
      window.navigator.vibrate([30]);
    }

    this.currentSeasonId = `${id}` || null;
    this.rootStore.accountStore.updateProfile(id);
    this.rootStore.changeURLSearch();
  }

  @action
  setPending(pending = false) {
    this.pending = pending;
  }

  @action
  onSwitch(add = 1) {
    let index = 0;
    this.seasonList.forEach((season, i) => {
      if (`${season.id}` === `${this.currentSeasonId}`) {
        index = i;
      }
    });
    let nextIndex = (index + add) % this.seasonList.length;
    if (nextIndex < 0) {
      nextIndex = this.seasonList.length + nextIndex;
    }
    if (this.seasonList[nextIndex]) {
      this.setSeasonId(this.seasonList[nextIndex].id);
    }
  }

  @action
  async getTops() {
    await this.currentSeason && this.currentSeason.getTops();
  }

  @action
  async getFullTops(sex, from, to) {
    this.setPage(1);
    await this.currentSeason && this.currentSeason.getFullTops(sex, this.page, this.perPage, from, to);
  }

  @action
  async getRatingTops(sex) {
    this.setPage(1);
    await this.currentSeason && this.currentSeason.getRatingTops(sex, this.page, this.perPage);
  }

  @action
  async setPage(page) {
    this.page = page;
  }

  @action
  setInitialMonthId(id) {
    this.initialMonthId = id;
    this.rootStore.changeURLSearch();
  }

  @action
  async getFullTopsNextPage(sex, from, to) {
    this.setPage(this.page  + 1);
    await this.currentSeason && this.currentSeason.getFullTops(sex, this.page, this.perPage, from, to);
  }

  @action
  async getRatingTopsNextPage(sex) {
    this.setPage(this.page  + 1);
    await this.currentSeason && this.currentSeason.getRatingTops(sex, this.page, this.perPage);
  }

  @action
  async init(club) {
    if (!this.isPending) {
      this.setPending(true);
      this.seasons.clear();
      const seasons = await this.api.getSeasons({ club });
      runInAction(() => {
        seasons.forEach((seasonData) => {
          const season = new Season(seasonData, this);
          this.seasons.set(`${season.id}`, season);
        });
        this.allSeasons = new Season({
          id:    "all",
          title: "Все сезоны",
        }, this);
      });
  
      if ((!this.currentSeasonId || !this.currentSeason) && seasons.length) {
        this.setSeasonId(seasons[0].id);
      }
      this.setPending();
    }
  }

  @computed
  get seasonList() {
    const seasonsArray = [];
    this.seasons.forEach((season) => {
      seasonsArray.push(season);
    });
    return seasonsArray;
  }

  @computed
  get topCompanyId() {
    return this.rootStore.companiesStore.topCompanyId;
  }

  @computed
  get disciplinesSetFallback() {
    let array = [];
    this.seasons.forEach((season) => {
      array = array.concat(season.disciplinesArray);
    });
    const set = new Set(array);
    return set;
  }

  @computed
  get disciplinesArrayFallback() {
    return Array.from(this.disciplinesSetFallback);
  }

  @computed
  get allCompanies() {
    const map = new Map();
    this.seasons.forEach((season) => {
      season.companies.forEach((company) => {
        map.set(company.id, company);
      });
    });
    return map;
  }

  @computed
  get currentSeason() {
    if (this.currentSeasonId === "all") {
      return this.allSeasons;
    } 
    return this.seasons.get(this.currentSeasonId);
  }

  @computed
  get isPending() {
    return this.pending;
  }

  @computed
  get canSwitch() {
    return this.seasons.size > 1;
  }

  @computed
  get type() {
    return this.rootStore.kind;
  }
}
