import React, { useState } from "react";
import { observer } from "mobx-react";
import ReactModal from "react-modal";
import { navigate } from "@reach/router";

import { IoIosArrowDown } from "react-icons/io";

import { ReactComponent as Run } from "~/resourses/run.svg";
import { ReactComponent as Ride } from "~/resourses/ride.svg";
import { ReactComponent as Ski } from "~/resourses/ski.svg";

import useStores from "~/hooks";

import { NavLink } from "~/components/common";
import ClubsList from "./ClubsList";

ReactModal.setAppElement("#root");

const ClubSwitch = observer(({ disabled }) => {
  const { accountStore } = useStores();
  const { currentClub, canSwitch } = accountStore;

  const [isModalVisible, setModalVisible] = useState(false);

  const onSelectClub = (club) => {
    if (club && club.id) {
      accountStore.setClubId(club.id);
      navigate(`/${location.search}`);
    }
    setModalVisible(false);
  };

  const onCloseModal = () => {
    setModalVisible(false);
  };

  const onToggleModal = () => {
    setModalVisible(!isModalVisible);
  };

  if (disabled) {
    return (
      <div className="club-holder">
        {!(currentClub && currentClub.logo) && (
          <div className="logo-holder">
            <div className="logo"></div>
            <div className="icons">
              <Run />
              <Ride />
              <Ski />
            </div>
          </div>
        )}
        { currentClub && currentClub.logo && (
          <div className="logo" style={{ backgroundImage: `url(${currentClub.logo})` }}></div>
        )}
      </div>
    );
  }
  return (
    <div className="club-holder">
      <NavLink to="/">
        {!(currentClub && currentClub.logo) && (
          <div className="logo-holder">
            <div className="logo"></div>
            <div className="icons">
              <Run />
              <Ride />
              <Ski />
            </div>
          </div>
        )}
        { currentClub && currentClub.logo && (
          <div className="logo" style={{ backgroundImage: `url(${currentClub.logo})` }}></div>
        )}
      </NavLink>
      {canSwitch && (
        <div className="drop">
          <IoIosArrowDown onClick={onToggleModal} />
        </div>
      )}
      <ClubsList
        isVisible={isModalVisible}
        onSelect={onSelectClub} 
        closeModal={onCloseModal}
      />
    </div>
  );
});
export default ClubSwitch;
