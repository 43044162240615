import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { navigate } from "@reach/router";

import { IoIosMan, IoIosWoman } from "react-icons/io";

import useStores from "~/hooks";

const Sex = observer(({ disabled, keepPage }) => {
  const { rootStore } = useStores();
  const { sex } = rootStore;

  useEffect(() => {
    if (!sex) {
      rootStore.setSex("males");
    }
  }, []);

  const onSexChange = () => {
    if (!disabled) {
      rootStore.setSex((sex === "males") ? "females" : "males");
      !keepPage && navigate(`/${location.search}`);
    }
  };

  return (
    <div className="nav-link sex" onClick={onSexChange}>
      {(sex === "males") ? <IoIosMan /> : <IoIosWoman /> }
    </div>
  );
});

export default Sex;
