const getCookie = (name) => {
  const matches = document.cookie.match(new RegExp(
    `(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1")}=([^;]*)` // eslint-disable-line
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

const doesHttpOnlyCookieExist = (cookiename) => {
  const d = new Date();
  d.setTime(d.getTime() + (1000));
  const expires = "Max-Age=2";

  document.cookie = `${cookiename}=temp_cookie;path=/;${expires}`;
  if (document.cookie.indexOf(`${cookiename}=`) === -1) {
    return true;
  } else {
    return false;
  }
};

export { doesHttpOnlyCookieExist };
export default getCookie;
