import { observable, computed } from "mobx";

export default class PageInfo {
  @observable
  rootStore = null;
  @observable
  stars = null; // Количества звезд в текущем сезоне.
  @observable
  cups = null; // Количества кубков в текущем сезоне.
  @observable
  activities = []; // Количества кубков в текущем сезоне.
  @observable
  activitiesCount = null; // Количества тренировок в текущем сезоне.

  @observable
  distance = null;
  @observable
  movingTime = null;

  @observable
  elevationGain = null;

  @observable
  numberOfAthletes = null;

  @observable
  pending = false;

  constructor(data, store) {
    this.rootStore = store;

    this.stars = data.stars || null;
    this.cups = data.cups || null;
    this.activitiesCount = data.activitiesCount || null;
    this.distance = data.distance || null;
    this.elevationGain = data.elevationGain || null;
    this.movingTime = data.movingTime || null;
    this.numberOfAthletes = data.numberOfAthletes || null;
  }

  @computed
  get isPending() {
    return this.pending;
  }

  @computed
  get activitiesArray() {
    return this.activities.slice().reverse();
  }

  @computed
  get distanceKM() {
    return this.distance ? Number(this.distance / 1000).toFixed(2) : null;
  }

  @computed
  get elevationM() {
    return this.elevationGain;
  }

  @computed
  get athletesCount() {
    return this.numberOfAthletes;
  }

  @computed
  get hours() {
    return Math.floor(this.movingTime / 3600);
  }

  @computed
  get minutes() {
    return Math.floor(this.movingTime / 60) % 60;
  }

  @computed
  get seconds() {
    return Math.floor(this.movingTime % 60);
  }
}
