import React, { useEffect } from "react";
import { observer } from "mobx-react";

import { IoIosClose } from "react-icons/io";

import useStores from "~/hooks";
import { Preloader } from "~/components/common";
import { TeamTop } from "~/components/team";

import MultiSwitch from "~/components/multiSwitch";
import { NewsWidget } from "~/components/news";
import Printables from "../components/competition/Printables";

const TeamRatingPage = observer(({ ratingId }) => {
  const { rootStore, seasonsStore } = useStores();
  const { kind, sex } = rootStore;
  const { currentSeason, currentSeasonId } = seasonsStore;

  const multiSwitch = (
    <MultiSwitch
      page="seasons"
      id={currentSeasonId}
      withEmptySport={true}
      withEmptyMonth={true}
      noSeason={true}
    />
  );

  const news = <NewsWidget limit={3} />;

  if (!currentSeason) {
    return (
      <div className="challenges page">
        {multiSwitch}
        {news}
        <div className="page-warning">
          <IoIosClose /> Нет доступных сезонов
        </div>
      </div>
    );
  }

  const { currentTeamRating, currentMonthId, topCompanyId } = currentSeason;

  useEffect(() => {
    if (currentSeason) currentSeason.setCurrentTeamRating(ratingId);
  }, [ratingId, sex]);

  useEffect(() => {
    if (currentTeamRating) currentTeamRating.getPageInit(sex);
  }, [currentTeamRating, kind, currentMonthId, topCompanyId, sex]);

  if (!currentTeamRating) {
    return (
      <div className="challenges page">
        {multiSwitch}
        {news}
        <div className="page-warning">
          <IoIosClose /> Нет доступных рейтингов
        </div>
      </div>
    );
  }

  const {
    currentFullTop,
    isPending,
    isPagePending,
    hasNextPage,
    name,
  } = currentTeamRating;

  if (isPending) {
    return (
      <div className="challenges page">
        {multiSwitch}
        <Preloader />
      </div>
    );
  }

  if (!currentFullTop) {
    return (
      <div className="challenges page">
        {multiSwitch}
        <div className="page-warning">
          <IoIosClose /> Нет информации о рейтингах
        </div>
      </div>
    );
  }

  const getNextPage = () => {
    if (currentTeamRating) {
      currentTeamRating.getNextPage(sex);
    }
  };

  const ids = currentFullTop[sex];
  return (
    <div className="top-page page">
      {multiSwitch}
      <NewsWidget limit={3} />
      <div className="rating-name">{name}</div>
      <Printables competition={currentTeamRating} />
      <TeamTop
        linkPrefix="/"
        ids={ids}
        isPending={isPending}
        isPagePending={isPagePending}
        getNextPage={getNextPage}
        hasNextPage={hasNextPage}
      />
    </div>
  );
});

export default TeamRatingPage;
