import React from "react";
import { observer } from "mobx-react";

import useStores from "~/hooks";

import { ReactComponent as Run } from "~/resourses/run.svg";
import { ReactComponent as Ride } from "~/resourses/ride.svg";
import { ReactComponent as Ski } from "~/resourses/ski.svg";

const Club = observer(({ club, onSelect, type }) => {
  const { accountStore } = useStores();
  const { name, isCurrent, id, intId, logo } = club;

  const changeClub = () => {
    if (onSelect) {
      onSelect(club);
    } else {
      accountStore.setClubId(id);
    }
  };

  return (
    <div 
      onClick={changeClub} 
      className={`club ${type} ${isCurrent ? "current gradient" : ""}`}
    >
      {(!intId || !logo) && (
        <div className="logo-holder">
          <div className="logo"></div>
          <div className="icons">
            <Run />
            <Ride />
            <Ski />
          </div>
        </div>
      )}
      {(intId && logo) && (
        <div className="logo" style={{ backgroundImage: `url(${logo})` }}></div>
      )}
      <span className="club-title">{name}</span>
    </div>

  );
});
export default Club;
