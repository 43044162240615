import React from "react";
import { observer } from "mobx-react";
import { IoMdMedal } from "react-icons/io";
import { 
  NavLink, 
} from "~/components/common";

const Archivement = observer(({ item }) => {
  const { type, kind, imageURL, challengeId, athleteId } = item;
  return (
    <NavLink to={`/challenges/${challengeId}/athletes/${athleteId}/`}>
      <div className={`archivement ${type} ${kind} gradient button-card`}>
        {imageURL && (
          <div
            className="image-background"
            style={{ backgroundImage: `url(${imageURL})` }}
          ></div>
        )}
        {!imageURL && <IoMdMedal />}
      </div>
    </NavLink>
  );
});

export default Archivement;
