import React, { useEffect } from "react";
import { observer } from "mobx-react";

import { IoIosClose } from "react-icons/io";

import useStores from "~/hooks";

import { NavLink, Preloader } from "~/components/common";
import NewsCard from "./NewsCard";

const NewsWidget = observer(({ limit }) => {
  const { newsStore, rootStore, seasonsStore } = useStores();
  const { currentSeasonId } = seasonsStore;

  const { type } = rootStore;
  const { 
    isPending, 
    newsList, 
  } = newsStore;

  useEffect(() => {
    !isPending && newsStore.init(limit, currentSeasonId);
  }, [currentSeasonId]);

  if (isPending) {
    return <Preloader />;
  }

  const news = [];
  newsList.forEach((newsItem) => {
    news.push(
      <div key={newsItem.id} className={`news-card ${type}`}>
        <NewsCard
          isPreview={true} 
          id={newsItem.id}
        />
      </div>
    );
  });

  if (!news.length) {
    news.push(
      <div key="warning" className="page-warning">
        <IoIosClose /> Нет новостей
      </div>
    );
  }
  
  return (
    <div className={`news-widget-holder ${type}`}>
      <NavLink to={"/news/"}>
        <div className="name">Новости</div>
      </NavLink>
      <div className={`news-cards-holder ${type}`}>
        {news}
      </div>
    </div>
  );
});

export default NewsWidget;
