import { observable, action, computed, toJS } from "mobx";
import moment from "moment";

import Top from "./Top";
import TopItem from "./TopItem";

export default class Rating {
  @observable
  store = null;
  @observable
  rootStore = null;
  @observable
  id = null;
  @observable
  name = null; // название.
  @observable
  status = null; // название.

  @observable
  penalty = null;
  @observable
  reward = null;

  @observable
  start = null;
  @observable
  finish = null;

  @observable
  isCompetition = false;

  @observable
  kind = null;

  @observable
  filters = new Map();

  @observable
  conditions = new Map();

  @observable
  seasonId = null;

  @observable
  signing = false;

  @observable
  fullTops = null;

  @observable
  tops = null;

  @observable
  pagePending = false;

  @observable
  pending = false;

  @observable
  annotationPending = false;

  @observable
  activitiesPending = false;

  @observable
  nextPage = true;

  @observable
  progressMap = new Map();

  @observable
  activitiesMap = new Map();

  @observable
  activitiesForCalendarMap = new Map();

  @observable
  activitiesByAthleteDateMap = new Map();

  @observable
  numberOfAthletes = null;

  @observable
  page = 1;
  @observable
  perPage = 20;

  @observable
  position = null;

  @observable
  isTeam = false;

  @observable
  printablesPending = false;

  @observable
  competitorDiploma = false;
  @observable
  competitorNumber = false;
  @observable
  prizewinnerDiploma = false;

  constructor(data, season, isTeam) {
    this.season = season;
    this.store = this.season.store;
    this.rootStore = this.store.rootStore;
    this.athletesStore = this.rootStore.athletesStore;
    this.api = this.rootStore.api;
    this.isTeam = isTeam;

    this.id = `${data.id}`;
    this.name = data.name;
  }

  @action
  setFullTops(data) {
    const top = new Top(data, this.store);
    this.fullTops = top;
  }

  @action
  setTops(data) {
    const top = new Top(data, this.store);
    this.tops = top;
  }

  @action
  addFullTops(data) {
    const top = this.fullTops;
    if (!top) {
      this.setFullTops(data);
    } else {
      top.addData(data);
    }
  }

  @action
  setPending(pending = false) {
    this.pending = pending;
  }

  @action
  setPagePending(pending = false) {
    this.pagePending = pending;
  }

  @action
  getNextPage(sex) {
    this.page = this.page + 1;
    this.getFullTops(sex);
  }

  @action
  getPageInit(sex) {
    this.page = 1;
    this.getFullTops(sex);
  }

  @action
  async getPrintables(from, to, kind, currentClubId) {
    if (!this.isPrintablesPending) {
      this.setPrintablesPending(true);
      const printables = await this.api.getPrintables(
        this.id,
        this.urlPart,
        from,
        to,
        kind,
        currentClubId
      );
      this.setPrintables(printables);
      this.setPrintablesPending();
    }
  }

  @action
  setPrintables({ competitorDiploma, competitorNumber, prizewinnerDiploma }) {
    this.competitorDiploma = competitorDiploma;
    this.competitorNumber = competitorNumber;
    this.prizewinnerDiploma = prizewinnerDiploma;
  }

  @action
  setPrintablesPending(pending = false) {
    this.printablesPending = pending;
  }

  @action
  async getFullTops(sex = "males") {
    const page = this.page;
    if (!this.isPending && !this.isPagePending) {
      this.setNextPage(true);
      if (page === 1) {
        this.setPending(true);
      } else {
        this.setPagePending(true);
      }

      const tops = await this.api.getRatingTops(
        this.id,
        sex[0].toUpperCase(),
        page,
        this.perPage,
        this.rootStore.kind,
        this.season.topCompanyId,
        this.season.currentMonth && this.season.currentMonth.from,
        this.season.currentMonth && this.season.currentMonth.to,
        this.isTeam
      );

      const topData = { males: [], females: [] };
      tops.forEach((data) => {
        const athlete = this.athletesStore.createAthlete(data);
        const topItem = new TopItem({
          id: athlete.id,
          ...data,
        });
        topData[sex].push(topItem);
      });

      if (tops.length !== this.perPage) {
        this.setNextPage(false);
      }

      if (page === 1) {
        this.setFullTops(topData);
      } else {
        this.addFullTops(topData);
      }
      this.setPending();
      this.setPagePending();
    }
  }

  @action
  async getAnnotation(sex = "males", amount = 3) {
    if (!this.isAnnotationPending) {
      this.setAnnotationPending(true);
      const tops = await this.api.getRatingAnnotation(
        this.id,
        sex[0].toUpperCase(),
        amount,
        this.isTeam
      );

      const topData = { males: [], females: [] };
      tops.forEach((data) => {
        const athlete = this.athletesStore.createAthlete(data);
        const topItem = new TopItem({
          id: athlete.id,
          ...data,
        });
        topData[sex].push(topItem);
      });
      this.setTops(topData);

      this.setAnnotationPending();
    }
  }

  @action
  async setNextPage(nextPage = false) {
    this.nextPage = nextPage;
  }

  @action
  setAnnotationPending(pending = false) {
    this.annotationPending = pending;
  }

  @computed
  get urlPart() {
    return this.isTeam ? "team-ratings" : "personal-ratings";
  }

  @computed
  get fullTop() {
    return this.fullTops || { males: [], females: [] };
  }

  @computed
  get top() {
    return this.tops || { males: [], females: [] };
  }

  @computed
  get topCompanyId() {
    return this.season.topCompanyId;
  }

  @computed
  get isPending() {
    return this.pending;
  }

  @computed
  get isAnnotationPending() {
    return this.annotationPending;
  }

  @computed
  get isActivitiesPending() {
    return this.activitiesPending;
  }

  @computed
  get isPagePending() {
    return this.pagePending;
  }

  @computed
  get currentFullTop() {
    return this.fullTops && toJS(this.fullTops);
  }

  @computed
  get hasNextPage() {
    return this.nextPage;
  }

  @computed
  get type() {
    return this.rootStore.kind;
  }

  @computed
  get canSign() {
    return moment().isBefore(this.finish);
  }

  @computed
  get isSigning() {
    return this.signing;
  }

  @computed
  get ruleNames() {
    return toJS(this.conditions);
  }
  @computed
  get conditionNames() {
    return toJS(this.conditions);
  }
  @computed
  get filterNames() {
    return toJS(this.filters);
  }

  @computed
  get class() {
    return this.isCompetition ? "competition" : "challenge";
  }
}
