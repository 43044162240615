import { computed, observable } from "mobx";

export default class TopItem {
  @observable
  id = null;
  @observable
  rank = null;
  @observable
  distance = null;
  @observable
  movingTime = null;
  @observable
  elevationGain = null;
  @observable
  points = null;

  constructor(data) {
    this.id = data.id;
    this.rank = data.rank;
    this.distance = data.distance;
    this.elevationGain = data.elevationGain;
    this.points = data.points;
    this.movingTime = data.movingTime;
  }

  @computed
  get distanceKM() {
    return this.distance && Number(this.distance / 1000).toFixed(2);
  }

  @computed
  get elevationM() {
    return this.elevationGain && Number(this.elevationGain).toFixed(2);
  }

  @computed
  get hours() {
    return Math.floor(this.movingTime / 3600);
  }

  @computed
  get minutes() {
    return Math.floor(this.movingTime / 60) % 60;
  }

  @computed
  get seconds() {
    return Math.floor(this.movingTime % 60);
  }
}
