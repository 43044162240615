import React, { useEffect, useMemo } from "react";
import { observer } from "mobx-react";

import useStores from "~/hooks";

import { Preloader } from "~/components/common";
import { IoIosDocument, IoIosRibbon, IoIosShirt } from "react-icons/io";

const Printables = observer(({ competition }) => {
  const {
    id,
    isTeam,
    isMultisport,
    isPrintablesPending,
    competitorDiploma,
    competitorNumber,
    prizewinnerDiploma,
    urlPart: url,
  } = competition;

  const { rootStore, seasonsStore, accountStore } = useStores();
  const { currentSeason, currentSeasonId } = seasonsStore;
  const { currentMonthId, currentMonth } = currentSeason;
  const { from, to } = currentMonth;
  const { currentClubId } = accountStore;
  const { type, kind } = rootStore;

  useEffect(() => {
    competition.getPrintables(from, to, kind, currentClubId);
  }, [currentSeasonId, currentMonthId]);

  const urlParams = useMemo(() => {
    const urlParams = new URLSearchParams();
    kind && urlParams.set("discipline", kind);
    from && urlParams.set("from", from);
    to && urlParams.set("to", to);
    currentClubId && urlParams.set("club", currentClubId);
    return urlParams;
  }, [kind, from, to, currentClubId]);

  if (!competitorDiploma && !competitorNumber && !prizewinnerDiploma) {
    return null;
  }

  let urlPart = "competitions";
  if (isMultisport) {
    urlPart = "multisport-competitions";
  }
  if (isTeam) {
    urlPart = "team-competitions";
  }
  if (url) {
    urlPart = url;
  }

  if (isPrintablesPending) {
    return (
      <div className="printables">
        <Preloader />
      </div>
    );
  }

  return (
    <div className="printables">
      <div className="buttons">
        {competitorNumber && (
          <a
            target="_blank"
            className={`button gradient ${type}`}
            href={`/api/v0/${urlPart}/${id}/publishing/competitor-number?${urlParams}`}
          >
            <IoIosShirt /> Скачать стартовый номер
          </a>
        )}
        {competitorDiploma && (
          <a
            target="_blank"
            className={`button gradient ${type}`}
            href={`/api/v0/${urlPart}/${id}/publishing/competitor-diploma?${urlParams}`}
          >
            <IoIosDocument /> Скачать диплом участника
          </a>
        )}
        {prizewinnerDiploma && (
          <a
            target="_blank"
            className={`button gradient ${type}`}
            href={`/api/v0/${urlPart}/${id}/publishing/prizewinner-diploma?${urlParams}`}
          >
            <IoIosRibbon /> Скачать диплом призера
          </a>
        )}
      </div>
    </div>
  );
});

export default Printables;
