import { observable, action, computed } from "mobx";
import moment from "moment";

export default class NewsStory {
  @observable
  store = null;
  @observable
  rootStore = null;
  @observable
  intId = null;

  @observable
  pending = false;

  datetime = moment();
  title = null;
  annotation = null;
  @observable
  body = null;

  constructor(data, store) {
    this.intId = data.id;
    this.setInfo(data);

    this.store = store;
    this.api = this.store.api;
    this.rootStore = this.store.rootStore;
  }

  @action
  async getInfo() {
    if (!this.pending && !this.full) {
      this.pending = true;
      const info = await this.api.getNewsStory(this.id);
      this.setInfo(info);
    }
  }

  @action
  setInfo(data) {
    this.datetime = moment(data.datetime);
    this.title = data.title;
    this.annotation = data.annotation;
    this.body = data.body;
    this.pending = false;
  }

  @computed
  get id() {
    return `${this.intId}`;
  }

  @computed
  get isPending() {
    return this.pending;
  }

  @computed
  get full() {
    return !!this.body;
  }

  @computed
  get date() {
    return this.datetime.format("DD.MM.YYYY HH:mm");
  }

  @computed
  get text() {
    return this.body;
  }
}
