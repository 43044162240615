import React, { useEffect } from "react";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { observer } from "mobx-react";

import useStores from "~/hooks";
import { Preloader } from "~/components/common";


const PersonalDataAgreement = observer(() => {
  const { accountStore } = useStores();
  const { isPersonalPending, personalContent } = accountStore;

  useEffect(() => {
    accountStore.getPersonal();
  }, []);

  return (
    <div className="personal page">
      <div className="page-header"><IoIosInformationCircleOutline /> Соглашение</div>
      {isPersonalPending && (<Preloader />)}
      { 
        !isPersonalPending && (
          <div className="personal-data-agreement" dangerouslySetInnerHTML={{ __html: personalContent }} />
        )}
    </div>
  );
});

export default PersonalDataAgreement;
