import React from "react";
import { observer } from "mobx-react";

import useStores from "~/hooks";

import Season from "./Season";

const SeasonsList = observer(({ onSelect, closeModal, title, withEmptySeason }) => {
  const { seasonsStore, rootStore } = useStores();
  const { type } = rootStore;
  const { canSwitch, seasonList, allSeasons } = seasonsStore;
  const seasons = [];

  const onSelectSeason = (season) => {
    if (canSwitch || withEmptySeason) {
      onSelect(season);
      closeModal();
    }
  };
  if (withEmptySeason) {
    seasons.push(
      <Season
        key={"all"}
        season={allSeasons}
        type={type}
        showTimeLast={false} 
        onSelect={onSelectSeason} 
      />
    );
  }
  seasonList.forEach((season, i) => {
    seasons.push(
      <Season
        key={`${i}`}
        season={season}
        type={type}
        showTimeLast={true} 
        onSelect={onSelectSeason} 
      />
    );
  });
  return (
    <div className={`main seasons-list ${type}`}>
      <div className={`modal-title gradient ${type}`}>{title}</div>
      <div className={`seasons ${type}`}>
        {seasons}
      </div>
      <div className="buttons">
        <div 
          className={`button gradient ${type}`} 
          onClick={closeModal}
        >
          Закрыть
        </div>
      </div>
    </div>
  );
});
export default SeasonsList;
