import React, { useEffect } from "react";
import handleViewport from "react-in-viewport";
import { observer } from "mobx-react";

import { IoIosClose } from "react-icons/io";

import useStores from "~/hooks";

import { Preloader } from "~/components/common";
import { NewsCard } from "~/components/news";
import MultiSwitch from "../components/multiSwitch/MultiSwitch";

const News = observer(({ limit }) => {
  const AdditionalLoader = handleViewport(Preloader);
  const { newsStore, rootStore, seasonsStore } = useStores();
  const { currentSeasonId } = seasonsStore;
  const { type } = rootStore;
  const { 
    isPending, 
    newsList, 
    getNextPage,
    isPagePending,
    hasNextPage,
  } = newsStore;

  useEffect(() => {
    !isPending && newsStore.init(limit, currentSeasonId);
  }, [currentSeasonId]);

  if (isPending) {
    return <Preloader />;
  }

  const news = [];
  newsList.forEach((newsItem) => {
    news.push(<NewsCard key={newsItem.id} id={newsItem.id} />);
  });

  let loader = null;
  if (getNextPage && !isPending && !isPagePending && news.length && hasNextPage && !limit) {
    loader = (
      <AdditionalLoader
        key={"loader"}
        onEnterViewport={() => {
          newsStore.getNextPage();
        }}
      />
    );
  }

  if (getNextPage && !isPending && isPagePending) {
    loader = (
      <Preloader key="preloader" />
    );
  }

  if (!news.length) {
    news.push(
      <div key="warning" className="page-warning">
        <IoIosClose /> Нет новостей
      </div>
    );
  }
  
  return (
    <div className={`news page ${type}`}>
      <MultiSwitch
        page="seasons" 
        linkPrefix={"/"}
        id={currentSeasonId}
        noSport={true}
        noMonth={true}
      />
      {news}
      {loader}
    </div>
  );
});

export default News;
