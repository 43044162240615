import React from "react";

const Bar = ({ percent, type }) => {
  let percentValue = Number(percent);
  if (isNaN(percentValue)) {
    percentValue = 0;
  }
  percentValue = percentValue * 100;
  if (percentValue > 100) {
    percentValue = 100;
  }
  percentValue = percentValue.toFixed(0);
  return (
    <div className="bar-holder status-holder">
      <div className={`button gradient ${type}`} style={{ width: `${percentValue}%` }}>
        {percentValue >= 50 && (<span className="percent-value">{percentValue} %</span>)}
      </div>
      <div className={"percent-holder"}>
        {percentValue < 50 && (<span className="percent-value">{percentValue} %</span>)}
      </div>
    </div>
  );
};
export default Bar;
