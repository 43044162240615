import React, { useEffect } from "react";
import { observer } from "mobx-react";

import { IoIosClose } from "react-icons/io";

import useStores from "~/hooks";
import { Preloader } from "~/components/common";
import MultiSwitch from "~/components/multiSwitch";
import { NewsWidget } from "~/components/news";
import TeamRatingList from "../components/rating/TeamRatingList";

const TeamRatingListPage = observer(() => {
  const { rootStore, seasonsStore, accountStore } = useStores();
  const { kind, sex } = rootStore;
  const { currentClubId } = accountStore;
  const { currentSeason, currentSeasonId } = seasonsStore;

  const multiSwitch = (
    <MultiSwitch
      page="seasons"
      id={currentSeasonId}
      noSport={true}
      noMonth={true}
    />
  );

  const news = <NewsWidget limit={3} />;

  useEffect(() => {
    if (currentSeason) {
      currentSeason.getTeamRatings();
    }
  }, [kind, currentSeasonId, currentClubId, sex]);

  if (!currentSeason) {
    return (
      <div className="challenges page">
        {multiSwitch}
        <div className="page-warning">
          <IoIosClose /> Нет доступных сезонов
        </div>
      </div>
    );
  }

  const { isPending } = currentSeason;

  return (
    <div className="top-page page">
      {multiSwitch}
      {news}
      {isPending && <Preloader />}
      {!isPending && <TeamRatingList />}
    </div>
  );
});

export default TeamRatingListPage;
