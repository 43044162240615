import React from "react";
import { observer } from "mobx-react";

import useStores from "~/hooks";

import { 
  Preloader, 
} from "~/components/common";
import RatingCard from "./RatingCard";

const RatingList = observer(() => {
  const { rootStore, seasonsStore } = useStores();
  const { currentSeason, isPending } = seasonsStore;
  const { ratingList } = currentSeason;
  const { type } = rootStore;

  if (isPending) {
    return <Preloader />;
  }

  const ratings = ratingList.map((rating) => {
    return (
      <RatingCard key={rating.id} rating={rating} />
    );
  });
  return (
    <div className={`rating-list ${type}`}>
      {ratings}
    </div>
  );
});

export default RatingList;
