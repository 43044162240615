import React, { useEffect } from "react";
import { observer } from "mobx-react";

import { 
  IoIosStar, 
  IoIosAdd, 
  IoIosRemove, 
  IoIosPeople,
} from "react-icons/io";
import { AiOutlineCalendar } from "react-icons/ai";

import useStores from "~/hooks";

import { Rules, NavLink, Preloader } from "~/components/common";
import TeamTop from "./TeamTop";
import Printables from "../competition/Printables";

const TeamChallengeCard = observer(({ isAnnotation, showDownloadButtons, challenge, companyId, showConditions }) => {
  const { rootStore, accountStore, companiesStore } = useStores();
  const { myTeamId: myCompanyId } = accountStore;
  const { type, sex } = rootStore;
  const forMale = sex === "males";

  const compId = companyId || myCompanyId;
  const company = compId && companiesStore.getCompany(compId);

  const { 
    id,
    name,
    start,
    finish,
    isPending,
    status,
    penalty,
    reward,
    isChampionship,
    filterNames,
    conditionNames,
    isCompetition,
    top,
    numberOfTeams,
    isAnnotationPending,
  } = challenge;

  const isAdditionalInfo = (showConditions || (compId === myCompanyId)) && compId;
  
  useEffect(() => {
    if (isAnnotation && isCompetition) {
      const athlete = isAdditionalInfo ? compId : undefined;
      challenge.getAnnotation(sex, athlete, showConditions && 1);
    } else if (isAdditionalInfo && !isCompetition && (compId !== myCompanyId || (status === "approved"))) {
      challenge.getProgressInfo(compId);
    }
  }, [sex, isAnnotation, isCompetition, status, compId, showConditions]);

  if (isPending || isAnnotationPending) {
    return <Preloader />;
  }
  
  if (
    !conditionNames 
  ) {
    return null;
  }

  const footer = null;
  let content = null;

  let ids = top;
  if (!isCompetition && companyId) {
    ids = [company];
  }

  content = (
    <React.Fragment>
      { (!isAnnotation || !isCompetition || showConditions) && (
        <Rules
          key="rules"
          filters={(!isAnnotation || showConditions || status !== "approved") && filterNames}
          conditions={conditionNames}
          showMale={forMale}
          showFemale={!forMale}
          canChangeSex={!companyId}
        />
      )}
      {isCompetition && showDownloadButtons &&  (
        <Printables competition={challenge} />
      )}
      {(isAnnotation && (isAdditionalInfo || isCompetition)
        && (companyId !== myCompanyId 
          || (status === "approved") 
          || isCompetition
        )) && (
        <TeamTop
          noNumbers={!isCompetition}
          isChampionship={isChampionship}
          key="top"
          ids={ids}
          linkPrefix={!isChampionship ? "/" : `/team-challenges/${id}/`}
        />
      )}
    </React.Fragment>
  );

  return (
    <div className={`challenge-card ${type}`}>
      <NavLink className={`gradient ${type}`} to={`/team-challenges/${id}/`}>
        <div className="name">
          <IoIosPeople />
          {name}
        </div>
        {numberOfTeams !== null && (
          <div className="participants">
            {numberOfTeams}
          </div>
        )}
      </NavLink>
      <div className="info">
        <div className="time-last">
          <AiOutlineCalendar /> {start.format("D MMMM")} &mdash; {finish.format("D MMMM")}
        </div>
        {content}
        <div className="status-holder">
          <div className="star-holder">
            {!!reward && (
              <div className="reward">
                <div className="value">
                  <IoIosAdd /><IoIosStar />{reward}
                </div>
              </div>
            )}
            {!!penalty && (
              <div className="penalty">
                <div className="value">
                  <IoIosRemove /><IoIosStar />{penalty}
                </div>
              </div>
            )}          
          </div>
          {footer}
        </div>
      </div>
    </div>
  );
});

export default TeamChallengeCard;
