import React, { useEffect } from "react";
import { IoIosStar, IoIosClose } from "react-icons/io";
import Select from "react-select";
import { observer } from "mobx-react";

import useStores from "~/hooks";

import { Top, Preloader } from "~/components/common";
import MultiSwitch from "~/components/multiSwitch";
import CompetitionCard from "./CompetitionCard";

const CompetitionInfo = observer(({ challengeId, athleteId, isMultisport }) => {
  const { rootStore, seasonsStore } = useStores();
  const { kind, sex } = rootStore;
  const { currentSeason, currentSeasonId } = seasonsStore;
  const isMale = sex !== "females";

  const { challengesStore } = currentSeason || {};
  const { isPending } = challengesStore || {};

  useEffect(() => {
    seasonsStore.initChallengesStore();
  }, [kind, currentSeasonId]);

  let challenge = null;

  if (isMultisport) {
    challenge = challengesStore.multisportCompetitions.get(`${challengeId}`);
  } else {
    challenge = challengesStore.competitions.get(`${challengeId}`);
  }

  const {
    fullCompaniesList,
    topCompany,
    topCompanyId,
    isCompaniesPending,
  } = currentSeason;

  useEffect(() => {
    challenge && challenge.getPageInit(sex);
  }, [kind, currentSeasonId, challengeId, challenge, isMale, topCompanyId]);

  if (!currentSeason) {
    return (
      <div className="challenges page">
        <div className="page-header">
          <IoIosStar /> Вызов
        </div>
        <div className="page-warning">
          <IoIosClose /> Не выбран сезон
        </div>
      </div>
    );
  }

  if (isPending || !challenge) {
    return <Preloader />;
  }

  const { isPagePending, hasNextPage, isCompetition } = challenge;

  const nextPage = () => {
    challenge.getNextPage(sex);
  };

  const ids = challenge.fullTop[sex];

  const onChange = (value) => {
    currentSeason.setTopCompany(value && value.id);
  };

  return (
    <div className="challenges info page">
      <MultiSwitch
        page={isMultisport ? "multisport-competitions" : "competitions"}
        id={challengeId}
        noSport={true}
        noMonth={true}
        noSeason={true}
      />
      <CompetitionCard
        showSubCompetitions={true}
        showDownloadButtons={true}
        challenge={challenge}
        athleteId={athleteId}
      />
      <div className="select-holder">
        <Select
          isLoading={isCompaniesPending}
          className="select-comp"
          classNamePrefix="select-comp"
          value={topCompany}
          onChange={onChange}
          isClearable={true}
          placeholder="Выберите компанию"
          options={fullCompaniesList}
        />
      </div>
      <Top
        ids={ids}
        noNumbers={!isCompetition}
        linkPrefix={isMultisport ? "/" : `/competitions/${challengeId}/`}
        isPending={isPending}
        isPagePending={isPagePending}
        getNextPage={nextPage}
        hasNextPage={hasNextPage}
      />
    </div>
  );
});

export default CompetitionInfo;
