import React, { useLayoutEffect, useMemo, useRef } from "react";
import { observer } from "mobx-react";
import moment from "moment";

import useStores from "~/hooks";

import { Preloader } from "~/components/common";

const ActivityCalendar = observer(
  ({
    data,
    isPending,
    showWeekdayLabels,
    labels,
    totalCount,
    selectedDate,
    setSelectedDate,
  }) => {
    if ((!data || !data.length) && !isPending) {
      return null;
    }

    const { months } = labels;
    const { rootStore } = useStores();
    const { type } = rootStore;

    const weekDays = (
      <div className="month-header">
        <div className="day-name">Пн</div>
        <div className="day-name">Вт</div>
        <div className="day-name">Ср</div>
        <div className="day-name">Чт</div>
        <div className="day-name">Пт</div>
        <div className="day-name weekend">Сб</div>
        <div className="day-name weekend">Вс</div>
      </div>
    );

    const monthsRender = useMemo(() => {
      const monthsRender = [];
      let previousMonthIndex = -1;
      let previousYear = null;
      let previousWeekIndex = -1;
      let weeksRender = [];
      let oneWeekRender = [];

      data.forEach((activity, i) => {
        const momentDate = moment(activity.date, "YYYY-MM-DD");
        const currentYear = momentDate.year();
        const currentMonthIndex = momentDate.month();
        const currentWeekIndex = momentDate.week();
        const currentDayOfWeekIndex = momentDate.weekday();

        if (currentMonthIndex !== previousMonthIndex) {
          // if month has changed, push to months array and clean temp values
          if (previousMonthIndex >= 0) {
            if (oneWeekRender.length) {
              weeksRender.push(
                <div key={`w-${currentWeekIndex}`} className="week-render">
                  {oneWeekRender}
                </div>
              ); // add week to weeks
            }
            oneWeekRender = [];
            previousWeekIndex = currentWeekIndex;

            monthsRender.push(
              <div
                id={`m-${previousMonthIndex}`}
                key={`m-${previousMonthIndex}-${momentDate.year()}`}
                className="month card"
              >
                <div className={`month-name color ${type}`}>
                  {months[previousMonthIndex]} {previousYear}
                </div>
                {showWeekdayLabels && weekDays}
                <div className="month-body">{weeksRender}</div>
              </div>
            );
          }
          previousMonthIndex = currentMonthIndex; // set current month as previous now
          previousYear = currentYear; // set current month as previous now
          weeksRender = []; // clean up weeks render
          oneWeekRender = []; // clean up particular week render
        }
        // proces day to week and a week to weeks and month render if needed
        if (currentWeekIndex !== previousWeekIndex) {
          // if week has changed
          if (oneWeekRender.length) {
            weeksRender.push(
              <div key={`w-a-${currentWeekIndex}`} className="week-render">
                {oneWeekRender}
              </div>
            ); // add week to weeks
          }
          oneWeekRender = [];
          previousWeekIndex = currentWeekIndex;
        }
        oneWeekRender.push(
          <div
            key={`${currentDayOfWeekIndex}`}
            className={`day-render day-${currentDayOfWeekIndex}`}
          >
            <div
              className={`day-render-background gradient level-${activity.level} ${type}`}
            ></div>
            <div
              id={`${activity.date}`}
              className={`day-render-number ${
                activity.level && selectedDate === activity.date ? "border" : ""
              } ${type}`}
              onClick={() => {
                activity.level && setSelectedDate(activity.date);
              }}
            >
              {momentDate.date()}
            </div>
          </div>
        );

        if (i === data.length - 1) {
          if (previousMonthIndex >= 0) {
            if (oneWeekRender.length) {
              weeksRender.push(
                <div key={`w-${currentWeekIndex}`} className="week-render">
                  {oneWeekRender}
                </div>
              ); // add week to weeks
            }
            oneWeekRender = [];
            previousWeekIndex = currentWeekIndex;

            monthsRender.push(
              <div
                id={`m-${previousMonthIndex}`}
                key={`m-${previousMonthIndex}-${momentDate.year()}`}
                className="month card"
              >
                <div className={`month-name color ${type}`}>
                  {months[previousMonthIndex]} {currentYear}
                </div>
                {showWeekdayLabels && weekDays}
                <div className="month-body">{weeksRender}</div>
              </div>
            );
          }
        }
      });

      return monthsRender;
    }, [data, showWeekdayLabels, selectedDate]);

    const scrollToToday = () => {
      const today = document.getElementById(`m-${moment(selectedDate).month()}`);
      today && today.scrollIntoView();
    };

    const element = useRef();

    useLayoutEffect(() => {
      if (element && element.current && selectedDate) {
        scrollToToday();
      }
    }, [element, selectedDate]);

    const calendar = (
      <div className="calendar" ref={element}>
        <div className="calendar-body">
          {monthsRender}
          {monthsRender.length > 1 && <div className="after"></div>}
        </div>
        <div className="calendar-footer">
          {totalCount && (
            <div className={`total-count color ${type}`}>
              Всего активностей: {totalCount}
            </div>
          )}
          <div className="legend">
            <div className="legend-title">Меньше</div>
            <div
              className={`day-render-background gradient level-1 ${type}`}
            ></div>
            <div
              className={`day-render-background gradient level-2 ${type}`}
            ></div>
            <div
              className={`day-render-background gradient level-3 ${type}`}
            ></div>
            <div
              className={`day-render-background gradient level-4 ${type}`}
            ></div>
            <div className="legend-title">Больше</div>
          </div>
        </div>
      </div>
    );
    return (
      <div className={"activities-calendar"}>
        {isPending && <Preloader />}
        {!isPending && calendar}
      </div>
    );
  }
);

export default ActivityCalendar;
