import React, { useCallback, useMemo, useState } from "react";
import {
  IoIosCheckmark,
  IoIosMan,
  IoIosPie,
  IoIosSettings,
  IoIosWoman,
} from "react-icons/io";
import { observer } from "mobx-react";

import useStores from "~/hooks";
import { Preloader } from "~/components/common";
import { AthleteProfile } from "~/components/athlete";
import MultiSwitch from "../components/multiSwitch/MultiSwitch";
import moment from "moment";
import Switch from "../components/common/Switch";

const Settings = observer(() => {
  const { seasonsStore, rootStore, accountStore } = useStores();

  const id = "me";
  const { kind, type } = rootStore;
  const { currentSeasonId } = seasonsStore;
  const {
    profile,
    personalDataAgreementDate,
    isPending,
    isAthlete,
    endpointsArray,
  } = accountStore;

  const [email, setEmail] = useState((profile && profile.email) || "");
  const [firstName, setFirstName] = useState(profile && profile.firstName);
  const [lastName, setLastName] = useState(profile && profile.lastName);
  const [personal, setPersonal] = useState(!!personalDataAgreementDate);
  const [sexValue, setSexValue] = useState(profile && profile.isMale);

  const [date, setDate] = useState(
    profile && profile.birthday && profile.birthday.format("YYYY-MM-DD")
  );

  const onSetEmail = useCallback((e) => {
    setEmail(e.target.value);
  }, []);

  const onCheckPersonal = useCallback(() => {
    setPersonal(!personal);
  }, []);

  const onInput = useCallback((e) => {
    setDate(e.target.value);
  }, []);

  const onFirstName = useCallback((e) => {
    setFirstName(e.target.value);
  }, []);

  const onLastName = useCallback((e) => {
    setLastName(e.target.value);
  }, []);

  const canSend = useMemo(() => {
    return !!firstName && !!lastName && !!personal && !!email;
  }, [firstName, lastName, personal, email]);

  const onSwitch = useCallback(() => {
    setSexValue(!sexValue);
  }, [sexValue]);

  const onSend = useCallback(() => {
    if (canSend) {
      accountStore.sendInfo(
        date && moment(date),
        email,
        firstName,
        lastName,
        sexValue ? "M" : "F",
        personal,
        null,
        true
      );
    }
  }, [date, email, firstName, lastName, personal, sexValue]);

  const endpoints = useMemo(() => {
    return endpointsArray.map((item) => {
      return (
        <a
          href={!item.checked && item.point}
          className={`data-provider-link ${
            item.id
          } ${type} color ${item.checked && "checked"}`}
          key={item.id}
        >
          {item.checked && <IoIosCheckmark />}
          <div className={`background-holder color ${type}`}></div>
        </a>
      );
    });
  }, [endpointsArray]);
  return (
    <div className="athlete-page settings page">
      <MultiSwitch
        noSex={true}
        page="seasons"
        noMultisport={true}
        noSport={true}
        noMonth={true}
        id={currentSeasonId}
      />
      {isPending && <Preloader />}
      {!isPending && (
        <div className="settings">
          <AthleteProfile
            kind={kind}
            showModal={false}
            currentSeasonId={currentSeasonId}
            isSettings={true}
            id={id}
          />
        </div>
      )}
      {!isPending && isAthlete && (
        <div className={`${type} user-form`}>
          <div className={`${type} before gradient`}></div>
          <div className="form-holder">
            <div className={`form-title ${type} gradient`}>
              <IoIosSettings /> Настройки
            </div>
            <div className="form-content">
              <div className="fields-holder">
                <div className="field-holder">
                  <div className="title">Email</div>
                  <input
                    placeholder="Введите email"
                    onChange={onSetEmail}
                    name="email"
                    value={email}
                    type="text"
                    inputMode="email"
                    className="field"
                  />
                </div>
                <div className="field-holder">
                  <div className="title">Пол</div>
                  <Switch
                    value={sexValue}
                    type={type}
                    titles={[
                      <span className="switch-body-render" key="f">
                        <IoIosWoman /> Женский
                      </span>,
                      <span className="switch-body-render" key="m">
                        <IoIosMan /> Мужской
                      </span>,
                    ]}
                    onChange={onSwitch}
                  />
                </div>
                <div className="field-holder">
                  <div className="title">Дата рождения</div>
                  <input
                    placeholder="Введите дату"
                    onChange={onInput}
                    value={date}
                    type="date"
                    name="birthday"
                    className="field"
                  />
                </div>
                <div className="field-holder">
                  <div className="title">Имя</div>
                  <input
                    placeholder="Введите имя"
                    onChange={onFirstName}
                    value={firstName}
                    type="text"
                    name="name"
                    className="field"
                  />
                </div>
                <div className="field-holder">
                  <div className="title">Фамилия</div>
                  <input
                    placeholder="Введите фамилию"
                    onChange={onLastName}
                    value={lastName}
                    type="text"
                    name="lastname"
                    className="field"
                  />
                </div>
                <div className="field-holder">
                  <div className="title"></div>
                  <p className="field">
                    <input
                      type="checkbox"
                      className="checkbox"
                      name="personal"
                      id="personal"
                      checked={personal ? "checked" : null}
                      onChange={onCheckPersonal}
                    />
                    <label htmlFor="personal">
                      <p className="personal-label">
                        Даю согласие на{" "}
                        <a target="_blank" href="/personal/">
                          обработку персональных данных
                        </a>
                      </p>
                    </label>
                  </p>
                </div>
                <div
                  onClick={onSend}
                  className={`button gradient ${canSend && type} ${
                    canSend ? "" : "disabled"
                  }`}
                >
                  Сохранить
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {!isPending && (
        <div className={`${type} user-form`}>
          <div className={`${type} before gradient`}></div>
          <div className="form-holder">
            <div className={`form-title ${type} gradient`}>
              <IoIosPie /> Провайдеры данных
            </div>
            <div className="form-text">
              Нажмите, для подключения провайдера данных о тренировках и
              разрешите приложению доступ к данным
            </div>
            <div className="form-content">
              <div className="fields-holder">{endpoints}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
});

export default Settings;
