import React from "react";
import { observer } from "mobx-react";

import useStores from "~/hooks";

const translation = {
  ru: {
    choose:  "Выбери любимый вид спорта",
    train:   "Тренируйся в удобном месте",
    compete: "Соревнуйся в любое время",
    compare: "Сравнивай результаты с коллегами и конкурентами",
    become:  "Становись сильнее",
    win:     "Выигрывай призы",
  },
  en: {
    choose:  "Choose your favourite sport",
    train:   "Exercise at any place",
    compete: "Compete at any time",
    compare: "Compare your results with colleagues and competitors",
    become:  "Become stronger",
    win:     "Win valuable prizes",
  },
};

const Motivation = observer(({ lang }) => {
  const { rootStore } = useStores();
  const { type } = rootStore;

  return (
    <div className="motivation-container">
      <div className="pre-motivation gradient ski"></div>
      <div className="cards">
        <div className="motivation-card">
          <div id="photo-1" className="card-image">
            <div
              style={{ backgroundImage: "url(/images/motivation/1.png)" }}
              id="photo-1-render"
              className="card-image-render"
            ></div>
          </div>
          <div className={`card-text color ${type}`}>
            {translation[lang].choose}
          </div>
        </div>
        <div className="motivation-card">
          <div className="card-image">
            <div
              style={{ backgroundImage: "url(/images/motivation/2.png)" }}
              id="photo-2"
              className="card-image-render"
            ></div>
          </div>
          <div className={`card-text color ${type}`}>
            {translation[lang].train}
          </div>
        </div>
        <div className="motivation-card">
          <div className="card-image">
            <div
              style={{ backgroundImage: "url(/images/motivation/3.png)" }}
              id="photo-3"
              className="card-image-render"
            ></div>
          </div>
          <div className={`card-text color ${type}`}>
            {translation[lang].compete}
          </div>
        </div>
        <div className="motivation-card">
          <div className="card-image">
            <div
              style={{ backgroundImage: "url(/images/motivation/4.png)" }}
              id="photo-4"
              className="card-image-render"
            ></div>
          </div>
          <div className={`card-text color ${type}`}>
            {translation[lang].compare}
          </div>
        </div>
        <div className="motivation-card">
          <div className="card-image">
            <div
              style={{ backgroundImage: "url(/images/motivation/5.png)" }}
              id="photo-5"
              className="card-image-render"
            ></div>
          </div>
          <div className={`card-text color ${type}`}>
            {translation[lang].become}
          </div>
        </div>
        <div className="motivation-card">
          <div className="card-image">
            {" "}
            <div
              style={{ backgroundImage: "url(/images/motivation/6.png)" }}
              id="photo-6"
              className="card-image-render"
            ></div>
          </div>
          <div className={`card-text color ${type}`}>
            {translation[lang].win}
          </div>
        </div>
      </div>
    </div>
  );
});

export default Motivation;
