import React from "react";
import { IoIosInformationCircleOutline } from "react-icons/io";


const NotFound = () => {
  return (
    <div className="faq page">
      <div className="page-header"><IoIosInformationCircleOutline /> 404</div>
    </div>
  );
};

export default NotFound;
