import { observable, action, computed } from "mobx";
import NewsStory from "../models/NewsStory";

export default class NewsStore {
  @observable
  news = new Map();

  @observable
  page = 1;
  @observable
  perPage = 10;

  @observable
  pending = false;

  @observable
  pagePending = false;

  @observable
  nextPage = true;

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.api = this.rootStore.api;
  }

  @action
  async init(limit, season) {
    this.setPending(true);
    this.news.clear();
    this.setPage(1);
    this.setNextPage(true);
    this.getNews(limit, season);
    this.setPending();
  }

  @action
  async getNews(limit, season) {
    const newsData = await this.api.getNews({
      limit, 
      season,
      page:    this.page,
      perPage: this.perPage,
    });
    if (newsData.length !== this.perPage) {
      this.setNextPage(false);
    }
    this.processNews(newsData);
  }

  @action
  async setPage(page) {
    this.page = page;
  }

  @action
  getStory(id) {
    let story = this.news.get(id);
    if (!story) {
      this.processNews([{ id }]);
      story = this.news.get(id);
    }
    return story;
  }

  @action
  async getPage() {
    this.setPage(1);
    this.setPagePending(true);
    this.setNextPage(true);
    await this.getNews();
    this.setPagePending();
  }

  @action
  async getNextPage() {
    this.setPage(this.page  + 1);
    this.setPagePending(true);
    await this.getNews();
    this.setPagePending();
  }


  @action
  processNews(data) {
    data.forEach((item) => {
      const newsStory = new NewsStory(item, this);
      this.news.set(newsStory.id, newsStory);
    });
  }

  @action
  setPending(pending = false) {
    this.pending = pending;
  }

  @action
  setPagePending(pending = false) {
    this.pagePending = pending;
  }

  @action
  getItem(id) {
    const item = this.getStory(`${id}`);
    item.getInfo();
    return item;
  }

  @action
  setNextPage(nextPage = false) {
    this.nextPage = nextPage;
  }


  @computed
  get hasNextPage() {
    return this.nextPage;
  }

  @computed
  get isPending() {
    return this.pending;
  }

  @computed
  get isPagePending() {
    return this.pagePending;
  }

  @computed
  get newsList() {
    const newsArray = [];
    this.news.forEach((item) => {
      newsArray.push(item);
    });
    return newsArray;
  }
}
