import React, { useCallback } from "react";
import { observer } from "mobx-react";

import KindCard from "../multiSwitch/KindCard";

const ActivityTypes = observer(({ lang }) => {
  const onDummie = useCallback(() => {}, []);

  return (
    <div className="activity-types">
      <div className="pre-types border white"></div>
      <div className="card-line">
        <KindCard
          lang={lang}
          onCardPress={onDummie}
          noScroll={true}
          active={true}
          kind="run"
        />
        <KindCard
          lang={lang}
          onCardPress={onDummie}
          noScroll={true}
          active={true}
          kind="treadmill"
        />
        <KindCard
          lang={lang}
          onCardPress={onDummie}
          noScroll={true}
          active={true}
          kind="various"
        />
      </div>
      <div className="card-line">
        <KindCard
          lang={lang}
          onCardPress={onDummie}
          noScroll={true}
          active={true}
          kind="ride"
        />
        <KindCard
          lang={lang}
          onCardPress={onDummie}
          noScroll={true}
          active={true}
          kind="mountainbike"
        />
        <KindCard
          lang={lang}
          onCardPress={onDummie}
          noScroll={true}
          active={true}
          kind="bikestation"
        />
        <KindCard
          lang={lang}
          onCardPress={onDummie}
          noScroll={true}
          active={true}
          kind="exercycle"
        />
      </div>
      <div className="card-line">
        <KindCard
          lang={lang}
          onCardPress={onDummie}
          noScroll={true}
          active={true}
          kind="skating"
        />
        <KindCard
          lang={lang}
          onCardPress={onDummie}
          noScroll={true}
          active={true}
          kind="ski"
        />
        <KindCard
          lang={lang}
          onCardPress={onDummie}
          noScroll={true}
          active={true}
          kind="swim"
        />
        <KindCard
          lang={lang}
          onCardPress={onDummie}
          noScroll={true}
          active={true}
          kind="pool"
        />
      </div>
    </div>
  );
});

export default ActivityTypes;
