import React, { useEffect } from "react";
import { observer } from "mobx-react";
import Select from "react-select";

import { IoIosClose } from "react-icons/io";

import useStores from "~/hooks";
import { Preloader, Top } from "~/components/common";
import MultiSwitch from "~/components/multiSwitch";
import { NewsWidget } from "~/components/news";
import Printables from "../components/competition/Printables";

const RatingPage = observer(({ ratingId }) => {
  const { rootStore, seasonsStore } = useStores();
  const { kind, sex } = rootStore;
  const { currentSeason, currentSeasonId } = seasonsStore;

  const multiSwitch = (
    <MultiSwitch
      page="seasons"
      id={currentSeasonId}
      withEmptySport={true}
      withEmptyMonth={true}
      noSeason={true}
    />
  );

  const news = <NewsWidget limit={3} />;

  if (!currentSeason) {
    return (
      <div className="challenges page">
        {multiSwitch}
        {news}
        <div className="page-warning">
          <IoIosClose /> Нет доступных сезонов
        </div>
      </div>
    );
  }

  const {
    fullCompaniesList,
    topCompany,
    isCompaniesPending,
    currentRating,
    currentMonthId,
    topCompanyId,
  } = currentSeason;

  useEffect(() => {
    if (currentSeason) currentSeason.setCurrentRating(ratingId);
  }, [ratingId, sex]);

  useEffect(() => {
    if (currentRating) currentRating.getPageInit(sex);
  }, [currentRating, kind, currentMonthId, topCompanyId, sex]);

  if (!currentRating) {
    return (
      <div className="challenges page">
        {multiSwitch}
        {news}
        <div className="page-warning">
          <IoIosClose /> Нет доступных рейтингов
        </div>
      </div>
    );
  }

  const {
    currentFullTop,
    isPending,
    isPagePending,
    hasNextPage,
    name,
  } = currentRating;

  if (isPending) {
    return (
      <div className="challenges page">
        {multiSwitch}
        <Preloader />
      </div>
    );
  }

  if (!currentFullTop) {
    return (
      <div className="challenges page">
        {multiSwitch}
        <div className="page-warning">
          <IoIosClose /> Нет информации о рейтингах
        </div>
      </div>
    );
  }

  const getNextPage = () => {
    if (currentRating) {
      currentRating.getNextPage(sex);
    }
  };

  const onChange = (value) => {
    currentSeason.setTopCompany(value && value.id);
  };

  const ids = currentFullTop[sex];
  return (
    <div className="top-page page">
      {multiSwitch}
      <NewsWidget limit={3} />
      <div className="rating-name">{name}</div>
      <Printables competition={currentRating} />
      <div className="select-holder">
        <Select
          isLoading={isCompaniesPending}
          className="select-comp"
          classNamePrefix="select-comp"
          value={topCompany}
          onChange={onChange}
          isClearable={true}
          placeholder="Выберите компанию"
          options={fullCompaniesList}
        />
      </div>
      <Top
        linkPrefix="/"
        ids={ids}
        isPending={isPending}
        isPagePending={isPagePending}
        getNextPage={getNextPage}
        hasNextPage={hasNextPage}
      />
    </div>
  );
});

export default RatingPage;
