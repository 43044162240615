import { observable, action, computed, toJS } from "mobx";
import moment from "moment";
import Competition from "./Competition";

import Top from "./Top";
import TopItem from "./TopItem";

export default class MultisportCompetition {
  @observable
  store = null;
  @observable
  rootStore = null;
  @observable
  id = null;
  @observable
  sex = "males";
  @observable
  name = null; // название.
  @observable
  status = null; // название.

  @observable
  competitions = new Map();

  @observable
  penalty = null;
  @observable
  reward = null;

  @observable
  start = null;
  @observable
  finish = null;

  @observable
  isCompetition = true;

  @observable
  isMultisport = true;

  @observable
  kind = null;

  @observable
  filters = new Map();

  @observable
  awardingRules = new Map();

  @observable
  conditions = new Map();

  @observable
  seasonId = null;

  @observable
  signing = false;

  @observable
  fullTops = null;

  @observable
  tops = null;

  @observable
  pagePending = false;

  @observable
  pending = false;

  @observable
  annotationPending = false;

  @observable
  activitiesPending = false;

  @observable
  nextPage = true;

  @observable
  progressMap = new Map();

  @observable
  activitiesMap = new Map();

  @observable
  numberOfAthletes = null;

  @observable
  page = 1;
  @observable
  perPage = 20;

  @observable
  position = null;

  @observable
  fromYear = null;
  @observable
  toYear = null;

  @observable
  adjacents = [];

  @observable
  printablesPending = false;

  @observable
  competitorDiploma = false;
  @observable
  competitorNumber = false;
  @observable
  prizewinnerDiploma = false;

  constructor(data, store) {
    this.store = store;
    this.season = this.store.season;
    this.rootStore = this.store.rootStore;
    this.athletesStore = this.rootStore.athletesStore;
    this.api = this.rootStore.api;
    this.seasonsStore = this.rootStore.seasonsStore;

    this.sex = data.sex === "M" ? "males" : "females";
    this.id = data.id;
    this.name = data.name;
    // ai: для консистентности поставим время и началу - 00:00.
    this.start = data.start
      ? moment(data.start, "YYYY-MM-DD HH:mm:ss")
        .set({ hour: 0, minute: 0 })
        .local()
        .utc(true)
      : null;
    // ai: ставим время на 23:59 чтобы работала запись в последний день.
    this.finish = data.finish
      ? moment(data.finish, "YYYY-MM-DD HH:mm:ss")
        .set({ hour: 23, minute: 59 })
        .local()
        .utc(true)
      : null;
    this.kind = data.discipline;
    this.status = (data.registration && data.registration.status) || null;
    this.seasonId = data.seasonId;
    this.penalty = data.penalty || null;
    this.reward = data.reward || null;
    this.position = data.position || 0;
    if (data.restrictions) {
      this.processRestrictions(data.restrictions);
    }
    this.disable();
    if (data.sex && (data.filters || data.awardingRules)) {
      if (data.sex === "M") {
        this.filters.set("males", data.filters);
        this.awardingRules.set("males", data.awardingRules);
      }
      if (data.sex === "F") {
        this.filters.set("females", data.filters);
        this.awardingRules.set("females", data.awardingRules);
      }
    }

    data.competitions.forEach((competitionData, i) => {
      const data = {
        ...competitionData,
        position: competitionData.position || i,
      };
      const item = new Competition(data, this.store, true);
      this.competitions.set(`${item.id}`, item);
      this.store.addCompetition(item);
    });
  }

  @action
  async getPrintables() {
    if (!this.isPrintablesPending) {
      this.setPrintablesPending(true);
      const printables = await this.api.getPrintables(
        this.id,
        "multisport-competitions"
      );
      this.setPrintables(printables);
      this.setPrintablesPending();
    }
  }

  @action
  setPrintables({ competitorDiploma, competitorNumber, prizewinnerDiploma }) {
    this.competitorDiploma = competitorDiploma;
    this.competitorNumber = competitorNumber;
    this.prizewinnerDiploma = prizewinnerDiploma;
  }

  @action
  setPrintablesPending(pending = false) {
    this.printablesPending = pending;
  }

  @action
  processRestrictions(restrictions) {
    if (restrictions.age) {
      if (restrictions.age.fromYear) {
        this.fromYear = restrictions.age.fromYear;
      }
      if (restrictions.age.toYear) {
        this.toYear = restrictions.age.toYear;
      }
    }
    if (restrictions.adjacents) {
      this.adjacents = restrictions.adjacents;
    }
  }

  @action
  disable() {
    if (this.status === "onapproval" || this.status === "approved") {
      this.store.disable(this.adjacents);
    }
  }

  @action
  async signTo() {
    this.setSigning(true);
    try {
      const registration = await this.api.registerMultisportCompetition(
        this.id
      );
      this.setStatus(registration && registration.status);
    } catch (error) {
      console.warn(error);
    }
    this.setSigning(false);
  }

  @action
  setSigning(signing = false) {
    this.signing = signing;
  }

  @action
  setFullTops(data) {
    const top = new Top(data, this.store);
    this.fullTops = top;
  }

  @action
  setTops(data) {
    const top = new Top(data, this.store);
    this.tops = top;
  }

  @action
  addFullTops(data) {
    const top = this.fullTops;
    if (!top) {
      this.setFullTops(data);
    } else {
      top.addData(data);
    }
  }

  @action
  setPending(pending = false) {
    this.pending = pending;
  }

  @action
  setPagePending(pending = false) {
    this.pagePending = pending;
  }

  @action
  getNextPage(sex) {
    this.page = this.page + 1;
    this.getFullTops(sex);
  }

  @action
  getPageInit(sex) {
    this.page = 1;
    this.getFullTops(sex);
  }

  @action
  async getFullTops(sex = "males") {
    const page = this.page;
    if (!this.isPending && !this.isPagePending) {
      this.setNextPage(true);
      if (page === 1) {
        this.setPending(true);
      } else {
        this.setPagePending(true);
      }

      const tops = await this.api.getMultisportCompetitionTops(
        this.id,
        this.topCompanyId,
        page,
        this.perPage,
        this.isCompetition
      );

      const topData = { males: [], females: [] };
      tops.forEach((data) => {
        const athlete = this.athletesStore.createAthlete(data);
        const topItem = new TopItem({
          id: athlete.id,
          ...data,
        });
        topData[sex].push(topItem);
      });

      if (tops.length !== this.perPage) {
        this.setNextPage(false);
      }

      if (page === 1) {
        this.setFullTops(topData);
      } else {
        this.addFullTops(topData);
      }
      this.setPending();
      this.setPagePending();
    }
  }

  @action
  async getAnnotation(sex = "males", forAthlete, amount) {
    if (!this.isAnnotationPending) {
      this.setAnnotationPending(true);
      const tops = await this.api.getMultisportCompetitionAnnotation(
        this.id,
        this.topCompanyId,
        forAthlete || null,
        amount || 3
      );

      const topData = { males: [], females: [] };
      tops.forEach((data) => {
        const athlete = this.athletesStore.createAthlete(data);
        const topItem = new TopItem({
          id: athlete.id,
          ...data,
        });
        topData[sex].push(topItem);
      });
      this.setTops(topData);

      this.setAnnotationPending();
    }
  }

  @action
  async getProgressInfo(athleteId) {
    if (!this.isAnnotationPending && athleteId) {
      this.setAnnotationPending(true);
      try {
        const progress = await this.api.getProgress(this.id, athleteId);
        if (progress.progress && progress.progress.length) {
          const progressObj = {};

          progress.progress.forEach((item) => {
            progressObj[item.conditionId] = item.percentage;
          });

          this.setProgress(athleteId, progressObj);
        }
      } catch (error) {
        console.warn("Couldn't load progress", error);
      }
      this.setAnnotationPending();
    }
  }

  @action
  setProgress(athleteId, progress) {
    this.progressMap.set(`${athleteId}`, progress);
  }

  @action
  getProgress(athleteId) {
    return this.progressMap.get(`${athleteId}`);
  }

  @action
  async getActivitiesInfo(athleteId) {
    if (!this.isActivitiesPending && athleteId) {
      this.setActivitiesPending(true);
      try {
        const activities = await this.api.getCompetitionActivities(
          this.id,
          athleteId
        );
        if (activities && activities.length) {
          this.setActivities(athleteId, activities);
        }
      } catch (error) {
        console.warn("Couldn't load athlete activities", error);
      }
      this.setActivitiesPending();
    }
  }

  @action
  setActivities(athleteId, activities) {
    this.activitiesMap.set(`${athleteId}`, activities);
  }

  @action
  getActivities(athleteId) {
    return this.activitiesMap.get(`${athleteId}`);
  }

  @action
  async setNextPage(nextPage = false) {
    this.nextPage = nextPage;
  }

  @action
  setStatus(status = null) {
    this.status = status;
    this.disable();
  }

  @action
  setAnnotationPending(pending = false) {
    this.annotationPending = pending;
  }

  @action
  setActivitiesPending(pending = false) {
    this.activitiesPending = pending;
  }

  @computed
  get urlPart() {
    return "multisport-competitions";
  }

  @computed
  get fullTop() {
    return this.fullTops || { males: [], females: [] };
  }

  @computed
  get top() {
    return this.tops || { males: [], females: [] };
  }

  @computed
  get topCompanyId() {
    return this.season.topCompanyId;
  }

  @computed
  get isPending() {
    return this.pending;
  }

  @computed
  get isAnnotationPending() {
    return this.annotationPending;
  }

  @computed
  get isActivitiesPending() {
    return this.activitiesPending;
  }

  @computed
  get isPrintablesPending() {
    return this.printablesPending;
  }

  @computed
  get isPagePending() {
    return this.pagePending;
  }

  @computed
  get currentFullTop() {
    return toJS(this.fullTops.get(this.type));
  }

  @computed
  get hasNextPage() {
    return this.nextPage;
  }

  @computed
  get type() {
    return this.rootStore.kind;
  }

  @computed
  get canSign() {
    const birthYear = this.rootStore.accountStore.birthYear;
    if (this.store.disabledCompetitions.has(`${this.id}`)) {
      return false;
    }
    if (this.fromYear && birthYear && this.fromYear > birthYear) {
      return false;
    }
    if (this.toYear && birthYear && this.toYear < birthYear) {
      return false;
    }
    if (this.forMale !== this.rootStore.accountStore.profile.isMale) {
      return false;
    }
    return moment().isBefore(this.finish) && !this.status;
  }

  @computed
  get forMale() {
    return this.sex === "males";
  }

  @computed
  get isSigning() {
    return this.signing;
  }

  @computed
  get disciplines() {
    const disciplines = new Set();
    this.competitions.forEach((competition) => {
      if (competition.kind) {
        disciplines.add(competition.kind);
      }
    });
    return Array.from(disciplines);
  }

  @computed
  get ruleNames() {
    return toJS(this.conditions);
  }
  @computed
  get conditionNames() {
    return toJS(this.conditions);
  }
  @computed
  get filterNames() {
    return toJS(this.filters);
  }
  @computed
  get awardingRulesNames() {
    return toJS(this.awardingRules);
  }

  @computed
  get forSex() {
    return this.sex;
  }
  @computed
  get rangedChallengeList() {
    const challengesArray = [];
    this.competitions.forEach((challenge) => {
      challengesArray.push(challenge);
    });
    return challengesArray;
  }

  @computed
  get class() {
    return "multisport-competition";
  }
}
