import React, { useMemo } from "react";
import { observer } from "mobx-react";

import useStores from "~/hooks";

import { Preloader } from "~/components/common";
import KindCard from "../multiSwitch/KindCard";

const RadarChart = observer(({ data, isPending }) => {
  const { rootStore } = useStores();
  const { type } = rootStore;

  const axis = [];
  const circles = [];
  for (let i = 1; i < 6; i += 1) {
    circles.push(
      <circle
        key={`c-${i}`}
        strokeWidth={1}
        strokeLinejoin="round"
        strokeLinecap="round"
        fillOpacity={0}
        stroke={"#000"}
        strokeOpacity={0.2}
        r={i * 20}
        cx={105}
        cy={105}
      />
    );
  }
  const icons = [];
  const path = useMemo(() => {
    let max = 1;
    const dataArray = [];
    for (const kind in data) {
      if (Object.hasOwnProperty.call(data, kind)) {
        const value = data[kind];
        dataArray.push({ value, kind });
        max = Math.max(max, value);
      }
    }
    const count = dataArray.length;
    const angle = (Math.PI * 2) / count;
    const radius = 100;
    const rotation = -Math.PI / 2;

    let path = "M";
    for (let i = 0; i < dataArray.length; i += 1) {
      const cos = Math.cos(angle * i + rotation);
      const sin = Math.sin(angle * i + rotation);
      axis.push(
        <path
          key={`key-${i}`}
          transform="translate(105,105)"
          strokeWidth={1}
          strokeLinejoin="round"
          strokeLinecap="round"
          fillOpacity={0.1}
          stroke={"#000"}
          strokeOpacity={0.2}
          d={`M0,0L${cos * radius},${sin * radius}Z`}
        />
      );
      const item = dataArray[i];
      icons.push(
        <div
          key={`chart-icon-holder-${item.kind}`}
          className={`chart-icon-holder ${type}`}
          style={{
            left: `${cos * 9 + 10}rem`,
            top:  `${sin * 9 + 10}rem`,
          }}
        >
          <KindCard iconOnly={true} key={item.kind} kind={item.kind} />
        </div>
      );
      path += `${cos * (item.value / max) * radius},${sin *
        (item.value / max) *
        radius}L`;
    }
    path += `${Math.cos(rotation) *
      (((dataArray[0] && dataArray[0].value) || 0) / max) *
      radius},${Math.sin(rotation) *
      (((dataArray[0] && dataArray[0].value) || 0) / max) *
      radius}Z`;
    return path;
  }, [data]);

  return (
    <div className={"radar-chart"}>
      {isPending && <Preloader />}
      {!isPending && (
        <div key="chart" className={`chart-body fill stroke ${type}`}>
          <svg
            version="1.1"
            id="all"
            viewBox="0 0 210 210"
            xmlns="http://www.w3.org/2000/svg"
          >
            {circles}
            {axis}
            <path
              transform="translate(105,105)"
              strokeWidth={2}
              strokeLinejoin="round"
              strokeLinecap="round"
              fillOpacity={0.5}
              d={path}
            />
          </svg>
          {icons}
        </div>
      )}
    </div>
  );
});

export default RadarChart;
