import React from "react";

const Preloader = ({ forwardedRef }) => {
  return (
    <div 
      ref={forwardedRef} 
      className="preloader-holder"
    >
      <div className="preloader">
        <svg
          width="100%"
          height="100%" 
          viewBox="0 0 120 120"
          xmlns='http://www.w3.org/2000/svg'
        >
          <circle
            cx='60' 
            cy='60' 
            r='45'
            strokeWidth="5"
            strokeLinecap='round'
          />
        </svg>
      </div>
    </div>
  );
};
export default Preloader;
