import React, { useMemo } from "react";
import { observer } from "mobx-react";

import useStores from "~/hooks";

import { Preloader } from "~/components/common";
import Archivement from "./Archivement";

const Archievements = observer(({ athlete, isPending }) => {
  if (!athlete && !isPending) {
    return null;
  }

  const { rootStore } = useStores();
  const { type } = rootStore;
  const { archievementsArray } = athlete;

  const archivements = useMemo(() => {
    const archArray = [];
    if (archievementsArray && athlete && !isPending) {
      archievementsArray.forEach((archivement) => {
        archArray.push(<Archivement key={archivement.id} item={archivement} />);
      });
    }

    return archArray;
  }, [archievementsArray, athlete, isPending]);

  return (
    <div className={`archievements ${type}`}>
      {isPending && <Preloader />}
      {!isPending && (
        <div className="cards">{archivements}</div>
      )}
    </div>
  );
});

export default Archievements;
