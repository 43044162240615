import React, { useEffect } from "react";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { observer } from "mobx-react";

import useStores from "~/hooks";
import { Preloader } from "~/components/common";


const Faq = observer(() => {
  const { rootStore } = useStores();
  const { isFaqPending, faqContent } = rootStore;

  useEffect(() => {
    rootStore.getFaq();
  }, []);

  return (
    <div className="faq page">
      <div className="page-header"><IoIosInformationCircleOutline /> FAQ</div>
      {isFaqPending && (<Preloader />)}
      { !isFaqPending && (<div className="faq" dangerouslySetInnerHTML={{ __html: faqContent }} />)}
    </div>
  );
});

export default Faq;
