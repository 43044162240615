import React from "react";
import { observer } from "mobx-react";

import useStores from "~/hooks";

const Banner = observer(() => {
  const { rootStore } = useStores();
  const { kind } = rootStore;
  return (<div className={`banner ${kind}`}></div>);
});

export default Banner;
