import React, { useMemo } from "react";
import { observer } from "mobx-react";

import useStores from "~/hooks";

import { Preloader } from "~/components/common";
import Code from "./Code";

const Promocodes = observer(({ athlete, isPending }) => {
  if (!athlete && !isPending) {
    return null;
  }

  const { rootStore } = useStores();
  const { type } = rootStore;
  const { promocodes } = athlete;

  const promocodesRender = useMemo(() => {
    const render = [];
    promocodes &&
      promocodes.length &&
      promocodes.forEach((code) => {
        render.push(<Code key={code.value} code={code} type={type} />);
      });
    return render;
  }, promocodes);

  if (!promocodesRender.length) {
    return null;
  }

  return (
    <div className={"promocodes"}>
      <div className="name">Доступные промокоды</div>
      {isPending && <Preloader />}
      {!isPending && (
        <div
          className={`athlete-promocodes competitions-card-holder  ${type} color`}
        >
          {promocodesRender}
        </div>
      )}
    </div>
  );
});

export default Promocodes;
