import React from "react";
import { observer } from "mobx-react";

import { 
  IoIosStar, 
  IoIosTrophy, 
  IoMdTimer, 
  IoIosShirt, 
} from "react-icons/io";
import { RiRouteLine } from "react-icons/ri";
import { 
  GiMountainRoad, 
  GiTwoCoins, 
} from "react-icons/gi";

import { ReactComponent as Man } from "~/resourses/man.svg";
import { ReactComponent as Woman } from "~/resourses/woman.svg";

import useStores from "~/hooks";

import { NavLink, Preloader } from "~/components/common";
    
const Athlete = observer(({ 
  index, 
  id, 
  style, 
  starsOnly, 
  linkPrefix, 
  noNumbers, 
  noPlaces,
  rank,
  distanceKM,
  elevationM,
  points,
  hours,
  minutes,
  seconds,
}) => {
  const { rootStore, athletesStore } = useStores();
  const { type } = rootStore;

  const athlete = athletesStore.getAthlete(id);

  const { 
    time,
    male,
    photo,
    stars,
    cups,
    name,
    company,
    isPending,
    isPendingImage,
  } = athlete;

  const i = rank || index + 1;

  if (isPending) {
    return <Preloader />;
  }

  let image = isPendingImage ? <Preloader /> : null;
  if (!photo) {
    image = male ? <Man /> : <Woman />;
  }

  let decimal = "";
  if (i > 9) {
    decimal = "signs2";
    if (i > 99) {
      decimal = "signs3";
      if (i > 999) {
        decimal = "signs4";
        if (i > 9999) {
          decimal = "signs5";
        }
      }
    }
  }
  let number = i;

  if (number === 1 && !starsOnly) {
    number = (
      <div className="shirt">
        <IoIosShirt className={`color ${type}`} />
        <div className={`num color ${type}`}>{number}</div>
      </div>
    );
  }

  return (
    <div 
      style={style} 
      key={time} 
      className={`athlete ${type} ${male ? "male" : "female"}`}
    >
      {!noNumbers && !noPlaces && (
        <div className={`number color ${type} ${decimal}`}>{number}</div>
      )}
      <NavLink to={`${linkPrefix}athletes/${id}/`}>
        <div className={`photo ${type} border`}>
          <div
            className="pic"
            style={{ backgroundImage: photo && `url(${photo})` }}
          >
            {image}
          </div>
        </div>
        {!starsOnly && (
          <div className="rewards">
            {stars && (
              <div className={`stars color ${type}`}>
                <IoIosStar />
                {stars}
              </div>
            )}
            {cups && (
              <div className={`cups color ${type}`}>
                <IoIosTrophy />
                {cups}
              </div>
            )}
          </div>
        )}
      </NavLink>
      <div className="descr">
        <div className="text">
          <div className="name">{name}</div>
          <div className="company">{company && company.name}</div>
        </div>
        {!starsOnly && (
          <div className="stats">
            {!noNumbers && !!elevationM && (
              <div className={`elevation color ${type}`}>
                <GiMountainRoad />
                {elevationM} м
              </div>
            )}
            {!noNumbers && !!distanceKM && (
              <div className={`distance color ${type}`}>
                <RiRouteLine />
                {distanceKM} км
              </div>
            )}
            {!noNumbers && (hours || minutes || seconds || null) && (
              <div className={`time color ${type}`}>
                <IoMdTimer />
                {hours}:
                {minutes < 10
                  ? `0${minutes}`
                  : minutes}:
                {seconds < 10
                  ? `0${seconds}`
                  : seconds}
              </div>
            )}
            {!noNumbers && !!points && (
              <div className={`elevation color ${type}`}>
                <GiTwoCoins />
                {points}
              </div>
            )}
          </div>
        )}
        {starsOnly && (
          <div className="stats">
            {stars && (
              <div className={`stars color ${type}`}>
                <IoIosStar />
                {stars}
              </div>
            )}
          </div>  
        )}
      </div>
    </div>
  );
});

export default Athlete;
