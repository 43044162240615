import React from "react";
import { observer } from "mobx-react";
import { AiOutlineCalendar } from "react-icons/ai";

import useStores from "~/hooks";

const Season = observer(({ season, onSelect, showTimeLast, noSelection, type }) => {
  const { seasonsStore } = useStores();
  const { name, isCurrent, id, start, finish } = season;

  const changeSeason = () => {
    if (onSelect) {
      onSelect(season);
    } else {
      seasonsStore.setSeasonId(id);
    }
  };

  return (
    <div 
      onClick={changeSeason} 
      className={`season ${type} ${isCurrent && !noSelection ? "current gradient" : ""}`}
    >
      <span className="season-title">{name}</span>
      {showTimeLast && (
        <div className="season-time-last">
          <AiOutlineCalendar /> {start.format("D MMMM YYYY")} &mdash; {finish.format("D MMMM YYYY")}
        </div>
      )}
    </div>

  );
});
export default Season;
