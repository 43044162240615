import React from "react";
import { Router, Location } from "@reach/router";

const TransitionRouter = (props) => {
  return (
    <Location>
      {({ location }) => {
        return (
          <Router location={location} className="router">
            {props.children}
          </Router>
        );
      }}
    </Location>
  );
};

export default TransitionRouter;
