import React, { useMemo } from "react";
import { observer } from "mobx-react";

import { 
  IoIosStar, 
  IoIosTrophy, 
} from "react-icons/io";

import { ReactComponent as Man } from "~/resourses/man.svg";
import { ReactComponent as Woman } from "~/resourses/woman.svg";
import { ReactComponent as User } from "~/resourses/user.svg";

import useStores from "~/hooks";

import { NavLink, Preloader } from "~/components/common";
    
const AthleteImage = observer(({ 
  id, 
  style, 
  starsOnly, 
  linkPrefix, 
}) => {
  const { rootStore, athletesStore, accountStore } = useStores();
  const { type } = rootStore;
  const { profile } = accountStore;

  const athlete = useMemo(() => {
    if (id === "me") {
      return profile;
    }
    return athletesStore.getAthlete(id);
  }, [id, profile]);

  const { 
    time,
    male,
    photo,
    stars,
    cups,
    isAthlete,
    isPending,
    isPendingImage,
  } = athlete;

  const image = useMemo(() => {
    let userImage = isPendingImage ? <Preloader /> : null;
    if (!photo) {
      userImage = male ? <Man /> : <Woman />;
    }
    if (!isAthlete && !photo) {
      userImage = <User className={`gradient ${type} spectator`} />;
    }
    return userImage;
  }, [photo, isAthlete, isPendingImage]);

  if (isPending) {
    return <Preloader />;
  }

  return (
    <div 
      style={style} 
      key={time} 
      className={`athlete ${type} ${male ? "male" : "female"}`}
    >
      <NavLink to={`${linkPrefix}athletes/${id}/`}>
        <div className={`photo ${type} border`}>
          <div
            className="pic"
            style={{ backgroundImage: photo && `url(${photo})` }}
          >
            {image}
          </div>
        </div>
        {!starsOnly && (
          <div className="rewards">
            {stars && (
              <div className="stars">
                <IoIosStar />
                {stars}
              </div>
            )}
            {cups && (
              <div className="cups">
                <IoIosTrophy />
                {cups}
              </div>
            )}
          </div>
        )}
      </NavLink>
    </div>
  );
});

export default AthleteImage;
