import React, { useEffect } from "react";
import { observer } from "mobx-react";

import {
  // IoIosAddCircleOutline,
  // IoIosHourglass,
  IoIosCloudOutline,
  IoIosCheckmarkCircleOutline,
  IoIosStar,
  IoIosAdd,
  IoIosRemove,
  IoIosPodium,
  IoIosMan,
  IoIosWoman,
  IoIosAddCircleOutline,
  IoIosHourglass,
} from "react-icons/io";
import { GiTwoCoins } from "react-icons/gi";

import { AiOutlineCalendar } from "react-icons/ai";

import useStores from "~/hooks";

import { NavLink, Preloader, Rules, Top } from "~/components/common";

const ChallengeCard = observer(
  ({
    isAnnotation,
    challenge,
    athleteId,
    showConditions,
    isDirectAthleteLink,
  }) => {
    const {
      rootStore,
      accountStore,
      athletesStore,
      seasonsStore,
    } = useStores();
    const { isAthlete, isMale, athleteId: meId } = accountStore;
    const { type, sex } = rootStore;
    const { currentSeasonId } = seasonsStore;

    const athlete = athletesStore.getAthlete(athleteId);
    useEffect(() => {
      athlete.getFullInfo(null, currentSeasonId);
    }, [athleteId, currentSeasonId]);

    const forMale = sex === "males";
    const sexFits = forMale === isMale;

    const {
      id,
      name,
      start,
      finish,
      isPending,
      isSigning,
      status,
      penalty,
      points,
      reward,
      filterNames,
      conditionNames,
      isCompetition,
      canSign,
      top,
      numberOfAthletes,
      isAnnotationPending,
    } = challenge;

    const progress = challenge.getProgress(athleteId);
    const isAdditionalInfo =
      (showConditions || (athleteId === meId && sexFits)) && athleteId;

    useEffect(() => {
      if (isAnnotation && isCompetition) {
        const athlete = isAdditionalInfo ? athleteId : undefined;
        challenge.getAnnotation(sex, athlete, showConditions && 1);
      } else if (
        isAdditionalInfo &&
        !isCompetition &&
        (athleteId !== meId || status === "approved")
      ) {
        challenge.getProgressInfo(athleteId);
      }
    }, [
      sex,
      isAthlete,
      isAnnotation,
      isCompetition,
      status,
      sexFits,
      athleteId,
      showConditions,
    ]);

    if (isPending || isAnnotationPending) {
      return <Preloader />;
    }

    if (
      !conditionNames ||
      !conditionNames[sex] ||
      !conditionNames[sex].length
    ) {
      return null;
    }

    const signTo = () => {
      challenge.signTo();
    };

    let footer = null;

    if (isAthlete && sexFits) {
      if (canSign) {
        footer = (
          <div className={`button gradient ${type}`} onClick={signTo}>
            {!isSigning ? (
              <IoIosAddCircleOutline />
            ) : (
              <IoIosHourglass className="rotating" />
            )}{" "}
            Записаться
          </div>
        );
      }

      if (status === "onapproval") {
        footer = (
          <div className="info-button">
            <IoIosCloudOutline /> На подтверждении
          </div>
        );
      }

      if (status === "approved") {
        footer = (
          <div className="info-button green">
            <IoIosCheckmarkCircleOutline /> Участвую
          </div>
        );
      }
    }

    let content = null;
    let ids = top[sex];
    if (!isCompetition && athleteId) {
      ids = [athlete];
    }

    content = (
      <React.Fragment>
        {(!isAnnotation || !isCompetition || showConditions) && (
          <Rules
            key="rules"
            filters={
              (!isAnnotation || showConditions || status !== "approved") &&
              filterNames
            }
            conditions={conditionNames}
            canChangeSex={!athleteId}
            showMale={forMale}
            type={type}
            showFemale={!forMale}
            progress={!!athleteId && progress}
          />
        )}
        {isAnnotation &&
          (isAdditionalInfo || isCompetition) &&
          (athleteId !== meId || status === "approved" || isCompetition) && (
          <Top
            noNumbers={!isCompetition}
            key="top"
            ids={ids}
            linkPrefix={isDirectAthleteLink ? "/" : `/challenges/${id}/`}
          />
        )}
      </React.Fragment>
    );

    return (
      <div className={`challenge-card ${type}`}>
        <NavLink className={`gradient ${type}`} to={`/challenges/${id}/`}>
          <div className="name">
            {isCompetition && <IoIosPodium />}
            {name}
          </div>
          {numberOfAthletes !== null && (
            <div className="participants">
              {sex === "males" ? <IoIosMan /> : <IoIosWoman />}{" "}
              {numberOfAthletes}
            </div>
          )}
        </NavLink>
        <div className="info">
          <div className="time-last">
            <AiOutlineCalendar /> {start.format("D MMMM")} &mdash;{" "}
            {finish.format("D MMMM")}
          </div>
          {content}
          <div className="status-holder">
            <div className="star-holder">
              {!!reward && (
                <div className="reward">
                  <div className="value">
                    <IoIosAdd />
                    <IoIosStar />
                    {reward}
                  </div>
                </div>
              )}
              {!!penalty && (
                <div className="penalty">
                  <div className="value">
                    <IoIosRemove />
                    <IoIosStar />
                    {penalty}
                  </div>
                </div>
              )}
            </div>
            <div className="points-holder">
              {!!points && (
                <div className="reward">
                  <div className="value">
                    <GiTwoCoins />
                    {points}
                  </div>
                </div>
              )}
            </div>
            {footer}
          </div>
        </div>
      </div>
    );
  }
);

export default ChallengeCard;
