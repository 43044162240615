import React, { useMemo } from "react";

const Switch = ({ value, titles, onChange, type }) => {
  const checked = useMemo(() => {
    return value ? "checked" : undefined;
  }, [value]);

  const title = useMemo(() => {
    return value ? titles[1] : titles[0];
  }, [value, titles]);

  const onCheck = () => {
    if (onChange) {
      onChange(!value);
    }
  };

  return (
    <label className={`switch gradient ${type}`}>
      <input type="checkbox" onChange={onCheck} checked={checked} />
      <span className={`slider round ${value ? "on" : "off"}`}>{title}</span>
    </label>
  );
};
export default Switch;
