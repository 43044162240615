import React, { useMemo } from "react";
import { observer } from "mobx-react";

import { 
  IoIosPaper, 
} from "react-icons/io";

import useStores from "~/hooks";

import { NavLink, Preloader } from "~/components/common";

const NewsCard = observer(({ id, isPreview }) => {
  const { newsStore, rootStore } = useStores();
  const { type } = rootStore;
  const { isPending } = newsStore;
  if (isPending) {
    return <Preloader />;
  }
  const item = useMemo(() => {
    return newsStore.news.get(id);
  }, [id]);

  if (!item) {
    return;
  }

  
  const { title, annotation, image, date, isPending: isStoryPending  } = item;

  if (isStoryPending) {
    return (
      <div className={`${type} ${(isPreview ? "preview" : "news-card")}`}>
        <NavLink to={`/news/${id}/`}>
          <div className="name">
            <IoIosPaper />
            {title}
          </div>
        </NavLink>
        <Preloader />
      </div>      
    );
  }

  return (
    <div className={`${type} ${(isPreview ? "preview" : "news-card")}`}>
      <div className={`before ${(isPreview ? "gradient" : "")} ${type}`}></div>
      <NavLink  className={`gradient ${type}`} to={`/news/${id}/`}>
        <div className="name">
          {!isPreview && <IoIosPaper />}
          {title}
        </div>
      </NavLink>
      {!isPreview && image && (
        <div className="news-image" style={{ backgroundImage: `url(${image})` }} />
      )}
      {!isPreview && (
        <div className="info small" dangerouslySetInnerHTML={{ __html: annotation }} />
      )}
      <div className={"news-date"}>{date}</div>
    </div>
  );
});

export default NewsCard;
