import React, { useMemo } from "react";
import { observer } from "mobx-react";

import { 
  IoIosPaper, 
} from "react-icons/io";

import useStores from "~/hooks";

import { NavLink, Preloader } from "~/components/common";

const NewsFull = observer(({ id }) => {
  const { newsStore, rootStore } = useStores();
  const { type } = rootStore;
  const { isPending } = newsStore;
  if (isPending) {
    return <Preloader />;
  }

  const item = useMemo(() => {
    return newsStore.getItem(id);
  }, [id]);

  if (!item) {
    return "Нет такой новости.";
  }
  const { title, body, image, date, isPending: isStoryPending } = item;

  if (isStoryPending) {
    return (
      <div className={`news page ${type}`}>
        <div className={`news-card ${type}`}>
          <NavLink className={`gradient ${type}`} to={`/news/${id}/`}>
            <div className="name">
              <IoIosPaper />
              {title}
            </div>
          </NavLink>
          <Preloader />
        </div>
      </div>
    );
  }

  return (
    <div className={`news page ${type}`}>
      <div className={`news-card ${type}`}>
        <NavLink className={`gradient ${type}`} to={`/news/${id}/`}>
          <div className="name">
            <IoIosPaper />
            {title}
          </div>
        </NavLink>
        {image && (
          <div className="news-image full" style={{ backgroundImage: `url(${image})` }} />
        )}
        <div className="info" dangerouslySetInnerHTML={{ __html: body }} />
        <div className="news-date">{date}</div>
      </div>
    </div>
  );
});

export default NewsFull;
