import React from "react";
import { observer } from "mobx-react";

import { RiFlagLine, RiPlayLine, RiStarLine } from "react-icons/ri";

import Bar from "./Bar";

const Rules = observer(({ filters, conditions, awardingRules, type, showMale, showFemale, progress }) => {
  const maleRules = [];
  const femaleRules = [];
    
  if (conditions) {
    if (conditions.males) {
      conditions.males.forEach((rule, i) => {
        maleRules.push(
          <div key={`mc${i}`} className="rule">
            <RiFlagLine /> {rule.name}
          </div>
        );
        if (progress && progress[rule.id] !== undefined && progress[rule.id] !== null) {
          maleRules.push(<Bar key={`bar${i}`} type={type} percent={progress[rule.id]} />);
        }
      });
    }
    if (conditions.females) {
      conditions.females.forEach((rule, i) => {
        femaleRules.push(
          <div key={`fc${i}`} className="rule">
            <RiFlagLine /> {rule.name}
          </div>
        );
        if (progress && progress[rule.id] !== undefined && progress[rule.id] !== null) {
          femaleRules.push(<Bar key={`bar${i}`} type={type} percent={progress[rule.id]} />);
        }
      });
    }
  }

  if (filters) {
    if (filters.males) {
      filters.males.forEach((rule, i) => {
        maleRules.push(
          <div key={`mf${i}`} className="rule">
            <RiPlayLine /> {rule.name}
          </div>
        );
      });
    }
    if (filters.females) {
      filters.females.forEach((rule, i) => {
        femaleRules.push(
          <div key={`ff${i}`}  className="rule">
            <RiPlayLine /> {rule.name}
          </div>
        );
      });
    }
  }

  if (awardingRules) {
    if (awardingRules.males) {
      awardingRules.males.forEach((rule, i) => {
        maleRules.push(
          <div key={`mf${i}`} className="rule">
            <RiStarLine /> {rule.name}
          </div>
        );
      });
    }
    if (awardingRules.females) {
      awardingRules.females.forEach((rule, i) => {
        femaleRules.push(
          <div key={`ff${i}`}  className="rule">
            <RiStarLine /> {rule.name}
          </div>
        );
      });
    }
  }

  return (
    <React.Fragment>
      <div className="rules-holder">
        <div className="conditions">
          {!!maleRules.length && showMale && maleRules}
          {!!femaleRules.length && showFemale && femaleRules}
        </div>
      </div>
    </React.Fragment>
  );
});

export default Rules;
