import React, { useCallback, useMemo, useRef, useState } from "react";
import { observer } from "mobx-react";
import moment from "moment";
import { IoIosImages, IoMdSpeedometer, IoMdTimer } from "react-icons/io";
import { RiRouteLine } from "react-icons/ri";
import { GiMountainRoad } from "react-icons/gi";
import ReactModal from "react-modal";
import ImageGallery from "react-image-gallery";

import { Preloader } from "~/components/common";
import useStores from "~/hooks";
import KindCard from "../multiSwitch/KindCard";

import "react-image-gallery/styles/css/image-gallery.css";
ReactModal.setAppElement("#root");

const ActivityListItem = observer(({ activity, kind, type, showDate }) => {
  const { accountStore } = useStores();

  const [isModal, setModal] = useState(null);

  const [isPending, setPending] = useState(false);
  const [isUpdated, setUpdated] = useState(false);
  const [isModalVisible, setModalVisible] = useState();

  const [elevationGain, setElevationGain] = useState("");
  const [photo, setPhoto] = useState([]);

  const canSend = useMemo(() => {
    return elevationGain && elevationGain >= 0 && photo && photo.length > 0;
  }, [elevationGain, photo]);

  const onElevationGainChange = useCallback(
    (e) => {
      setElevationGain(e.target.value);
    },
    [setElevationGain]
  );

  const onShowModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const onHideModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const onSend = async() => {
    if (canSend) {
      setPending(true);
      onHideModal();
      const photoArray = [];
      photo.forEach((item) => {
        if (item.value) {
          photoArray.push(item.value);
        }
      });
      const data = {
        elevationGain,
        photo: photoArray,
      };
      try {
        await accountStore.sendActivityPolar(activity.id, data);
        setElevationGain("");
        setPhoto([]);
        setUpdated(true);
      } catch (error) {
        onShowModal();
        console.warn(error);
      }
      setPending(false);
    }
  };

  const imageRef = useRef(null);

  const onToggleCarousel = useCallback(() => {
    setModal(!isModal);
  }, [setModal, isModal]);

  const images = useMemo(() => {
    if (!activity.photos || !activity.photos.length) {
      return null;
    }
    return activity.photos.map((item) => {
      return {
        original:  item.url,
        thumbnail: item.url,
      };
    });
  }, [activity.photos]);

  const onImageChange = (e) => {
    e.preventDefault();
    const newPhotos = [...photo];
    let found = false;
    const newItem = {
      name:  e.target.name,
      value: e.target.files[0],
    };
    newPhotos.forEach((item, i) => {
      if (item.name === e.target.name) {
        newPhotos[i] = newItem;
        found = true;
      }
    });
    if (!found) {
      newPhotos.push(newItem);
    }
    setPhoto(newPhotos);
  };

  const movingMoment = moment()
    .startOf("day")
    .add(activity.movingTime, "seconds");
  const movingMomentStart = moment().startOf("day");
  return (
    <div className="item-holder">
      <div key={`${kind}-${activity.id}`} className={`activity color ${type}`}>
        <div className="fitnes-icon-holder">
          <KindCard iconOnly={true} key={kind} kind={kind} />
          {activity.provider && (
            <a
              className={`data-provider-link ${activity.provider} ${type} color checked`}
            >
              <div className={`background-holder color ${type}`}></div>
            </a>
          )}
        </div>
        <div className="activity-holder">
          <div className="date">
            {showDate && (
              <div className="start">
                {moment.utc(activity.start).format("DD.MM.YYYY")}
              </div>
            )}
            <div className="start">
              {moment.utc(activity.start).format("HH:mm")}
            </div>
          </div>
          {activity.photos && activity.photos.length > 0 && (
            <div className="photos" onClick={onToggleCarousel}>
              <IoIosImages />
            </div>
          )}
          {activity.canElevationGainBeChanged &&
            activity.userId === accountStore.meId &&
            !isUpdated && (
            <div className="photos" onClick={onShowModal}>
              {isPending && <Preloader />}
              {!isPending && <GiMountainRoad />}
            </div>
          )}
          <div className="info">
            {activity.distance > 0 && (
              <div className="distance">
                <RiRouteLine />
                {Number(activity.distance / 1000).toFixed(2)} км
              </div>
            )}
            {activity.elevationGain > 0 && (
              <div className="elevation">
                <GiMountainRoad />
                {Number(activity.elevationGain).toFixed(2)} м
              </div>
            )}
            {activity.movingTime > 0 && (
              <div className="time">
                <IoMdTimer />
                {movingMoment.diff(movingMomentStart, "hours")}:
                {movingMoment.format("mm:ss")}
              </div>
            )}
            {!!activity.averagePace && (
              <div className="time">
                <IoMdSpeedometer />
                {activity.averagePace}/км
              </div>
            )}
            {activity.averageSpeed > 0 && (
              <div className="time">
                <IoMdSpeedometer />
                {Number(activity.averageSpeed).toFixed(2)} км/ч
              </div>
            )}
          </div>
        </div>
      </div>
      {isModal && (
        <ImageGallery ref={imageRef} items={images} showPlayButton={false} />
      )}
      <ReactModal
        isOpen={isModalVisible}
        shouldCloseOnOverlayClick={true}
        onRequestClose={onHideModal}
        contentLabel="User select modal"
      >
        <div className={`activity-form main seasons-list ${type}`}>
          <div className={`modal-title gradient ${type}`}>Набор высоты</div>
          {isPending && <Preloader />}
          {!isPending && (
            <div className="form-holder">
              <div className="form-content">
                <div className={"modal-body"}>
                  <div className="field-holder">
                    <div className="title">Набор высоты в метрах</div>
                    <input
                      id="elevationGain"
                      name="elevationGain"
                      placeholder="Введите высоту"
                      onChange={onElevationGainChange}
                      value={elevationGain}
                      min={0}
                      type="number"
                      inputMode="decimal"
                      className="field"
                    />
                  </div>
                  <div className="field-holder">
                    <div className="title">
                      <b>
                        Для подтверждения тренировки необходимо приложить
                        изображение:
                      </b>
                    </div>
                  </div>
                  <div className="field-holder">
                    <div className="title">Фото</div>
                    <input
                      placeholder="Добавьте файлы"
                      name="photo0"
                      onChange={onImageChange}
                      type="file"
                      accept="image/*"
                      className="field"
                    />
                    <input
                      placeholder="Добавьте файлы"
                      name="photo1"
                      onChange={onImageChange}
                      type="file"
                      accept="image/*"
                      className="field"
                    />
                    <input
                      placeholder="Добавьте файлы"
                      name="photo2"
                      onChange={onImageChange}
                      type="file"
                      accept="image/*"
                      className="field"
                    />
                    <input
                      placeholder="Добавьте файлы"
                      name="photo3"
                      onChange={onImageChange}
                      type="file"
                      accept="image/*"
                      className="field"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {!isPending && (
            <div className="buttons">
              <div
                className={`button gradient ${canSend && "run"}`}
                onClick={onSend}
              >
                Подтвердить
              </div>
              <div className={"button gradient ride"} onClick={onHideModal}>
                Отменить
              </div>
            </div>
          )}
        </div>
      </ReactModal>
    </div>
  );
});

export default ActivityListItem;
