import React, { useMemo, useState } from "react";
import { observer } from "mobx-react";

import useStores from "~/hooks";

import { Preloader } from "~/components/common";
import ActivityCalendar from "./ActivitiyCalendar";
import ActivityListItem from "./ActivityListItem";

const ActivitiesList = observer(
  ({ activities, activitiesForCalendar, isPending, challenge, athleteId }) => {
    const { rootStore } = useStores();
    const [selectedDate, setSelectedDate] = useState(null);

    const acticvitiesList = useMemo(() => {
      const acticvitiesList = [];
      if (!isPending && selectedDate && athleteId) {
        const activities = challenge.getActicvitiesByDate(
          athleteId,
          selectedDate
        );
        activities.forEach((activity) => {
          const kind = activity.disciplineName;
          const type = rootStore.getTypeByKind(kind);
          acticvitiesList.push(
            <ActivityListItem activity={activity} type={type} kind={kind} />
          );
        });
      }
      return acticvitiesList;
    }, [isPending, athleteId, selectedDate]);

    return (
      <div className={"activities-list"}>
        {isPending && <Preloader />}
        {!isPending && acticvitiesList}
        {activitiesForCalendar && (
          <ActivityCalendar
            totalCount={activities.length}
            data={activitiesForCalendar}
            showWeekdayLabels={true}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            isPending={isPending}
            labels={{
              legend: {
                less: "Меньше",
                more: "Больше",
              },
              months: [
                "Январь",
                "Февраль",
                "Март",
                "Апрель",
                "Май",
                "Июнь",
                "Июль",
                "Август",
                "Сентябрь",
                "Октябрь",
                "Ноябрь",
                "Декабрь",
              ],
            }}
          />
        )}
      </div>
    );
  }
);

export default ActivitiesList;
