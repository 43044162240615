import React from "react";
import { observer } from "mobx-react";

import useStores from "~/hooks";

import { Preloader } from "~/components/common";
import RatingCard from "./RatingCard";

const TeamRatingList = observer(() => {
  const { rootStore, seasonsStore } = useStores();
  const { currentSeason, isPending } = seasonsStore;
  const { teamRatingList } = currentSeason;
  const { type } = rootStore;

  if (isPending) {
    return <Preloader />;
  }

  const ratings = teamRatingList.map((rating) => {
    return <RatingCard key={rating.id} isTeam={true} rating={rating} />;
  });
  return <div className={`rating-list ${type}`}>{ratings}</div>;
});

export default TeamRatingList;
