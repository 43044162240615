import React from "react";
import { Link } from "@reach/router";

import useStores from "~/hooks";

const NavLink = (props) => {
  const { rootStore } = useStores();
  const onClick = () => {
    if (window.navigator.vibrate) {
      window.navigator.vibrate([30]);
    }
    if (props.action) {
      props.action();
    }
    rootStore.setIsOpen(false);
  };
  return (
    <Link
      {...props}
      action="none"
      to={props.to + location.search}
      onClick={onClick}
      getProps={({ isCurrent }) => {
        return {
          className: `nav-link ${isCurrent ? "current" : ""} ${props.className}`,
        };
      }}
    />
  );
};
export default NavLink;
