import { action, observable } from "mobx";

export default class Top {
  @observable
  store = null;
  @observable
  rootStore = null;
  @observable
  males = [];
  @observable
  females = [];

  constructor(data, store) {
    this.store = store;
    this.rootStore = this.store.rootStore;

    this.males = data.males;
    this.females = data.females;
  }

  @action
  addData(data) {
    if (data.males) {
      data.males.forEach((male) => {
        this.males.push(male);
      });
    }
    if (data.females) {
      data.females.forEach((female) => {
        this.females.push(female);
      });
    }
  }
}
