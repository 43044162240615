import React, { useEffect, useMemo } from "react";
import { observer } from "mobx-react";

import {
  IoIosFitness,
  IoIosMan,
  IoIosStar,
  IoIosTimer,
  IoIosTrophy,
  IoIosWoman,
} from "react-icons/io";
import { GiMountainRoad } from "react-icons/gi";
import { RiRouteLine } from "react-icons/ri";

import useStores from "~/hooks";

import { Preloader } from "~/components/common";
import { AthleteImage } from "~/components/athlete";
import KindCard from "./KindCard";
import MonthsSwitch from "./MonthsSwitch";
import SeasonsSwitch from "./SeasonsSwitch";

const kindArray = [
  "run",
  "walk",
  "treadmill",
  "various",
  "ride",
  "exercycle",
  "bikestation",
  "mountainbike",
  "skating",
  "ski",
  "pool",
  "swim",
  "multisport",
];

const MultiSwitch = observer(
  ({
    noMonth,
    noSeason,
    noSport,
    withEmptySport,
    withEmptySeason,
    withEmptyMonth,
    noMultisport,
    page,
    id,
    companyId,
  }) => {
    const { seasonsStore, rootStore } = useStores();
    const { kind, pageInfo, isPageInfoPending, sex } = rootStore;
    const { currentSeason } = seasonsStore;

    const {
      currentMonth,
      topCompanyId: topCompany,
      disciplinesArray: disciplines,
      disciplinesSet,
    } = currentSeason;

    let from = null;
    let to = null;

    if (currentMonth) {
      from = currentMonth.from;
      to = currentMonth.to;
    }

    const company = companyId || topCompany;

    useEffect(() => {
      if (
        disciplines &&
        disciplines[0] &&
        !disciplinesSet.has(kind) &&
        !withEmptySport
      ) {
        rootStore.setType(disciplines[0]);
      }
    }, [disciplines, disciplinesSet, withEmptySport]);

    useEffect(() => {
      rootStore.getPageInfo(
        page,
        id,
        noSport ? null : kind,
        sex,
        noMonth ? null : from,
        noMonth ? null : to,
        company
      );
    }, [kind, page, id, from, to, sex, company]);

    let time = "";
    if (pageInfo && pageInfo.hours) {
      time = `${pageInfo.hours}:${
        pageInfo.minutes < 10 ? `0${pageInfo.minutes}` : pageInfo.minutes
      }:${pageInfo.seconds < 10 ? `0${pageInfo.seconds}` : pageInfo.seconds}`;

      if (pageInfo.hours > 48) {
        time = `${~~(pageInfo.hours / 24)} дн.`;
      }
    }

    const cards = useMemo(() => {
      const cardList = [];
      if (withEmptySport) {
        cardList.push(<KindCard key="all" kind="all" />);
      }
      kindArray.forEach((kind) => {
        if (
          disciplinesSet &&
          disciplinesSet.has(kind) &&
          !(noMultisport && kind === "multisport")
        ) {
          cardList.push(<KindCard key={kind} kind={kind} />);
        }
      });
      return cardList;
    }, [disciplinesSet, withEmptySport]);
    return (
      <div className="multi-switch-holder">
        <div className={`back-holder ${kind}`}>
          <div className="back"></div>
        </div>
        <div className="stats-holder">
          {isPageInfoPending && <Preloader />}
          {!isPageInfoPending && (
            <div className="stats">
              {pageInfo && pageInfo.stars && (
                <div className="distance">
                  <IoIosStar /> {pageInfo.stars}
                </div>
              )}
              {pageInfo && pageInfo.cups && (
                <div className="distance">
                  <IoIosTrophy /> {pageInfo.cups}
                </div>
              )}
              {pageInfo && pageInfo.athletesCount && (
                <div className="distance">
                  {sex === "males" ? <IoIosMan /> : <IoIosWoman />}{" "}
                  {pageInfo.athletesCount}
                </div>
              )}
              {pageInfo && pageInfo.activitiesCount && (
                <div className="distance">
                  <IoIosFitness /> {pageInfo.activitiesCount}
                </div>
              )}
              {pageInfo && pageInfo.movingTime && (
                <div className="time">
                  <IoIosTimer /> {time}
                </div>
              )}
              {pageInfo && pageInfo.distanceKM && (
                <div className="distance">
                  <RiRouteLine /> {~~pageInfo.distanceKM} км
                </div>
              )}
              {pageInfo && pageInfo.elevationGain && (
                <div className="distance">
                  <GiMountainRoad />
                  {pageInfo.elevationGain > 5000
                    ? `${~~(pageInfo.elevationGain / 1000)} км`
                    : `${pageInfo.elevationGain} м`}
                </div>
              )}
            </div>
          )}
          <div className="buttons">
            <AthleteImage id={"me"} starsOnly={true} linkPrefix={"/"} />
          </div>
        </div>
        {!(noSeason && noMonth) && (
          <div className="combined-controll-holder">
            <div className="combined-controll">
              <div className="left-side">
                {!noSeason && (
                  <div className="left-side-line">
                    <SeasonsSwitch withEmptySeason={withEmptySeason} />
                  </div>
                )}
                {!noMonth && (
                  <div className="left-side-line">
                    <MonthsSwitch withEmptyMonth={withEmptyMonth} />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {!noSport && <div className="cards">{cards}</div>}
      </div>
    );
  }
);
export default MultiSwitch;
