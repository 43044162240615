/* eslint-disable max-len */
import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react";

import {
  IoIosPerson,
  IoIosWalk,
  IoIosTv,
  IoIosMail,
  IoIosHome,
  IoIosSpeedometer,
  IoIosTime,
  IoIosDoneAll,
} from "react-icons/io";
import { ToastContainer } from "react-toastify";

import { ReactComponent as Run } from "~/resourses/run.svg";
import { ReactComponent as Ride } from "~/resourses/ride.svg";
import { ReactComponent as Ski } from "~/resourses/ski.svg";

import { ClubSwitch } from "~/components/club";

import useStores from "~/hooks";
import PhotoGallery from "../components/info/PhotoGallery";
import Motivation from "../components/info/Motivation";
import ActivityTypes from "../components/info/ActivityTypes";
import { FaEye, FaTelegram } from "react-icons/fa";
import { GiMountainRoad } from "react-icons/gi";
import { RiRouteLine } from "react-icons/ri";

const DEV = !process.env.NODE_ENV || process.env.NODE_ENV === "development"; // eslint-disable-line

const appleLink = "https://appleid.apple.com/auth/authorize?client_id=club.vmarafone&redirect_uri=https%3A%2F%2Fvmarafone.club%2Fapi%2Fv0%2Fauth%2Fapple-id%2Fauth-redirect&response_type=code%20id_token&response_mode=form_post&scope=name%20email";
const translation = {
  ru: {
    yandexLogin:   "Вход с Яндекс",
    googlelogin:   "Вход с Google",
    applelogin:    "Вход с Apple",
    marketingText:
      "Мотивационная онлайн-платформа для занятий физической культурой и спортом атлетов любого уровня подготовки. Возможность соревноваться и выполнять вызовы по 11 видам активности, независимо от времени и географического положения. Личные и командные соревнования, медали и розыгрыши ценных призов среди участников проекта. Более 5000 атлетов с сентября 2020 года!",
    compete:      "Соревнуйся с vmarafone.club",
    activities:   "Различные виды активности",
    competitions: "Соревнования",
    speed:        "на скорость прохождения дистанции",
    distance:     "на длительность дистанции в километрах",
    duration:     "на длительность занятий по времени",
    elevation:    "на сумму набора высоты",
    challenges:   "Вызовы",
    quantity:     "на количество занятий",
    contacts:     "Контактная информация",
    policy:       "Политика конфиденциальности",
    delete:       "Удаление аккаунта",
    policyURL:    "./policy?lang=ru",
    companyInfo:  (
      <table id="rekviz_table">
        <tbody>
          <tr>
            <td id="rekviz_table_1">Полное наименование организации</td>
            <td id="rekviz_table_2">
              Общество с ограниченной ответственностью «ЮР Эвентс»
            </td>
          </tr>
          <tr>
            <td>Сокращенное наименование организации</td>
            <td>ООО «ЮР Эвентс»</td>
          </tr>
          <tr>
            <td>ИНН</td>
            <td>7714845133</td>
          </tr>
          <tr>
            <td>КПП</td>
            <td>770101001</td>
          </tr>
          <tr>
            <td>ОГРН</td>
            <td>1117746550239</td>
          </tr>
          <tr>
            <td>Адрес</td>
            <td>105082, г. Москва, ул. Большая Почтовая, д.26 В, стр. 1</td>
          </tr>
          <tr>
            <td>Email</td>
            <td>
              <a href="mailto:info@vmarafone.club">info@vmarafone.club</a>
            </td>
          </tr>
        </tbody>
      </table>
    ),
    policyText: (
      <Fragment>
        <h1>Политика ООО «ЮР Эвентс» в отношении обработки персональных данных и сведения о реализуемых требованиях к защите персональных данных, в том числе для пользователей мобильного приложения Vmarafone</h1>
        <h3>Общие положения</h3>
        <h5>1.1 Назначение документа</h5>
        <p>Политика ООО «ЮР Эвентс» в отношении обработки персональных данных и сведения о реализуемых требованиях к защите персональных данных (далее – Политика) является основополагающим локальным актом компании, регулирующим вопросы обработки персональных данных в ООО «ЮР Эвентс» (далее – Общество). </p>
        <p>Настоящая Политика разработана в соответствии с п.2 ч.1 статьи 18.1 Федерального закона от 27 июля 2006 года № 152 «О персональных данных» и предназначена для ознакомления неограниченного круга лиц, в том числе – путём опубликования на вебсайтах Общества. </p>
        <p>Политика раскрывает основные категории субъектов, персональные данные которых обрабатываются Обществом, цели, способы и принципы обработки персональных данных, права субъектов персональных данных, а также перечень мер, применяемых Обществом в целях обеспечения безопасности персональных данных при их обработке. </p>
        <p>Положения настоящей Политики являются основой для разработки локальных актов Общества, регламентирующих вопросы обработки и защиты персональных данных в Обществе.</p>
        <h5>1.2 Область действия </h5>
        <p>Действие настоящей Политики распространяется на все процессы Общества, в рамках которых осуществляется обработка персональных данных как с использованием средств вычислительной техники, в том числе с использованием информационнотелекоммуникационных сетей, так и без использования таких средств. </p>
        <h5>1.3 Основные понятия </h5>
        <p>В настоящей Политике используются следующие основные понятия: </p>
        <p>Конфиденциальность персональных данных – обязательное для соблюдения оператором или иным получившим доступ к персональным данным лицом требование не раскрывать третьим лицам и не распространять персональные данные без согласия субъекта персональных данных, если иное не предусмотрено федеральным законом. </p>
        <p>Обработка персональных данных – любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных. </p>
        <p>Оператор – государственный орган, муниципальный орган, юридическое или физическое лицо, самостоятельно или совместно с другими лицами организующие и (или) осуществляющие обработку персональных данных, а также определяющие цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными. </p>
        <p>Персональные данные – любая информация, относящаяся прямо или косвенно к определённому или определяемому физическому лицу (субъекту персональных данных). </p>
        <p>Субъект персональных данных – физическое лицо, которое прямо или косвенно определено или определяемо с помощью персональных данных. </p>
        <p>Трансграничная передача персональных данных – передача персональных данных на территорию иностранного государства органу власти иностранного государства, иностранному физическому лицу или иностранному юридическому лицу. </p>
        <h5>1.4 Утверждение и пересмотр </h5>
        <p>Настоящая Политика вступает в силу с 1 сентября 2023 года и действует до принятия ее новой редакции. </p>
        <p>Общество проводит пересмотр положений настоящей Политики и их актуализацию по мере необходимости при наступлении следующих условий: </p>
        <ul>
          <li>при изменении нормативной базы, затрагивающей принципы и (или) процессы обработки персональных данных в Обществе; </li>
          <li>при создании новых или внесении изменений в существующие процессы обработки персональных данных. </li>
        </ul>
        <h3>2 Цели сбора и обработки персональных данных </h3>
        <p>С целью поддержания деловой репутации и обеспечения выполнения требований Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных» и принятых в соответствии с ним нормативных правовых актов, Общество, являясь оператором персональных данных с соответствующими правами и обязанностями, обеспечивает соответствие обработки персональных данных требованиям законодательства Российской Федерации, а также надлежащий уровень безопасности обрабатываемых персональных данных. </p>
        <p>Обработка персональных данных в Обществе осуществляется на законной и справедливой основе и ограничивается достижением конкретных, заранее определенных и законных целей. Обработке подлежат только персональные данные, которые отвечают целям их обработки. Содержание и объем обрабатываемых персональных данных соответствуют заявленным целям обработки, избыточность обрабатываемых данных не допускается. </p>
        <p>При обработке персональных данных обеспечивается точность персональных данных, их достаточность и, в необходимых случаях, актуальность по отношению к целям обработки персональных данных. Общество принимает и обеспечивает принятие необходимых мер по удалению или уточнению неполных или неточных персональных данных.  </p>
        <p>Хранение персональных данных осуществляется в форме, позволяющей определить субъекта персональных данных, не дольше, чем этого требуют цели обработки персональных данных, и они подлежат уничтожению по достижению целей обработки или в случае утраты необходимости в их достижении, если иное не предусмотрено законодательством. </p>
        <p>Общество в рамках выполнения своей основной деятельности осуществляет обработку персональных данных пользователей мобильных приложений и сайтов Общества в сети Интернет. </p>
        <p>Цели обработки ПДн, категории и перечень обрабатываемых ПДн, категории субъектов ПДн, способы, сроки их обработки и хранения приведены в Приложении 1 к Политике. </p>
        <h3>3 Правовые основания обработки персональных данных </h3>
        <p>Обработка персональных данных Обществом допускается в следующих случаях: </p>
        <ul>
          <li>при наличии согласия Субъекта персональных данных на обработку его персональных данных;  </li>
          <li>обработка необходима для достижения целей, предусмотренных законодательством, а также для осуществления и выполнения возложенных законодательством на Общество функций, полномочий и обязанностей;  </li>
          <li>обработка необходима для осуществления прав и законных интересов Общества и/или третьих лиц либо для достижения общественно значимых целей при условии, что при этом не нарушаются права и свободы Субъектов персональных данных;  </li>
          <li>обработка осуществляется в статистических или иных исследовательских целях при условии обязательного обезличивания персональных данных; </li>
          <li>обработка персональных данных, разрешенных Субъектом персональных данных для распространения;  </li>
          <li>персональные данные подлежат опубликованию или обязательному раскрытию в соответствии с законодательством. </li>
        </ul>
        <h3>4 Порядок и условия обработки персональных данных </h3>
        <p>В отношении персональных данных Общество осуществляет обработку смешанным способом (с использованием средств автоматизации, а также без использования средств автоматизации).  </p>
        <p>Общество осуществляет следующие действия (операции) с персональными данными: сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передача (распространение, предоставление, доступ), блокирование, удаление, уничтожение персональных данных. </p>
        <p>В отношении всех категорий персональных данных Общество при сборе персональных данных обеспечивает запись, систематизацию, накопление, хранение, уточнение (обновление, изменение) и извлечение персональных данных с использованием баз данных, находящихся на территории Российской Федерации. </p>
        <p>Общество не размещает персональные данные Субъекта персональных данных в общедоступных источниках и не распространяет персональные данные неограниченному кругу лиц без предварительного согласия Субъекта персональных данных. </p>
        <p>В целях обеспечения адекватной защиты персональных данных Общество проводит оценку вреда, который может быть причинен Субъектам персональных данных в случае нарушения безопасности их персональных данных, а также определяет актуальные угрозы безопасности персональных данных при их обработке в информационных системах персональных данных. </p>
        <p>С целью обеспечения безопасности персональных данных при их обработке Общество принимает необходимые и достаточные правовые, организационные и технические меры для защиты персональных данных от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения персональных данных, а также от иных неправомерных действий в отношении персональных данных. </p>
        <p>Общество добивается того, чтобы все реализуемые мероприятия по организационной и технической защите персональных данных осуществлялись на законных основаниях, в том числе в соответствии с требованиями законодательства Российской Федерации по вопросам обработки персональных данных. </p>
        <p>Руководство Общества осознает важность и необходимость обеспечения безопасности персональных данных и поощряет постоянное совершенствование системы защиты персональных данных, обрабатываемых в рамках выполнения основной деятельности компании. </p>
        <p>В Обществе назначено лицо, ответственное за организацию обработки персональных данных. </p>
        <p>Каждый новый работник Общества, непосредственно осуществляющий обработку персональных данных, подлежит ознакомлению с требованиями законодательства Российской Федерации по обработке и обеспечению безопасности персональных данных, с настоящей Политикой и другими локальными актами по вопросам обработки и реализации требований к защите персональных данных и несет ответственность за их соблюдение. </p>
        <p>В отношении всех категорий персональных данных хранение персональных данных осуществляется Обществом в форме, позволяющей определить Субъекта персональных данных, не дольше, чем этого требуют цели обработки персональных данных, если срок хранения персональных данных не установлен федеральным законом, договором, стороной которого, выгодоприобретателем или поручителем по которому является Субъект персональных данных. Обрабатываемые персональные данные подлежат уничтожению по достижении целей обработки или в случае утраты необходимости в достижении этих целей, если иное не предусмотрено федеральным законом. </p>
        <h3>5 Обработка запросов Субъектов персональных данных </h3>
        <p>Для обеспечения соблюдения установленных законодательством прав Субъектов персональных данных в Обществе разработан и введён порядок работы с обращениями и запросами Субъектов персональных данных, а также порядок предоставления Субъектам персональных данных информации, установленной законодательством РФ в области персональных данных. </p>
        <p>Запрос Субъекта персональных данных должен содержать номер основного документа, удостоверяющего личность Субъекта персональных данных или его законного представителя, сведения о дате выдачи указанного документа и выдавшем его органе, сведения, подтверждающие участие Субъекта персональных данных в отношениях с Обществом (номер договора и дата заключения договора с Обществом, и (или) иные сведения, копию (отсканированную фотографию) сообщения в форме письма или в электронной форме, в виде смс-сообщения, полученных от Общества и т.п.), либо сведения, иным образом подтверждающие факт обработки персональных данных Обществом, подпись Субъекта персональных данных или его представителя, дату обращения. </p>
        <p>Работники Общества не имеют право отвечать на вопросы, связанные с передачей или разглашением персональных данных по телефону или факсу в связи с тем, что в таком случае нет возможности идентифицировать личность обращающегося человека. </p>
        <p>Запросы Субъектов персональных данных должны быть направлены по почте по адресу: 105082, г. Москва, ул. Большая Почтовая, д.26 В, стр. 1. </p>
        <h3>6 Порядок уничтожения персональных данных </h3>
        <p>Для обеспечения соблюдения установленных законодательством прав Субъектов персональных данных в Обществе разработан и введён порядок уничтожения ПДн. </p>
        <p>ПДн подлежат уничтожению в следующих случаях: </p>
        <ul>
          <li>по достижении целей обработки или в случае утраты необходимости в их достижении;  </li>
          <li>при выявлении фактов неправомерной обработки персональных данных (в том числе при обращении субъекта персональных данных), когда обеспечить их правомерность не представляется возможным; </li>
          <li>при получении соответствующего предписания от уполномоченного органа по защите прав субъектов ПДн; </li>
          <li>в случае отзыва согласия субъекта на обработку ПДн, когда законных оснований продолжать такую обработку у Общества не имеется; </li>
          <li>при истечении предусмотренного согласием субъекта или установленного локальными нормативными актами Общества срока обработки ПДн; </li>
          <li>­при отсутствии оснований осуществлять архивное хранение материальных носителей, содержащих такие ПДн. </li>
        </ul>
        <p>Уничтожение ПДн должно производиться в течение 30 рабочих дней с момента обнаружения данных, подлежащих уничтожению, если иной срок не предусмотрен законодательством, соглашением с субъектом ПДн или согласием субъекта, за исключением ПДн, обрабатываемых неправомерно, которые должны быть уничтожены в срок, не превышающий 10 рабочих дней с даты выявления неправомерной обработки персональных данных.  </p>
        <p>В случае отсутствия возможности уничтожения ПДн в течение срока, указанного выше, Общество осуществляет блокирование таких ПДн или обеспечивает их блокирование (если обработка ПДн осуществляется другим лицом, действующим по поручению Общества) и последующее уничтожение ПДн в срок не более чем 6 месяцев. </p>
        <p>Материальные носители персональных данных, находящиеся на архивном хранении, ПДн, содержащиеся в электронных архивах, архивные копии Баз персональных данных, содержащих уничтоженные ПДн, уничтожаются в соответствии с нормами законодательства об архивном деле в Российской Федерации. </p>
        <h3>7 Заключительные положения </h3>
        <p>Иные обязанности и права Общества как оператора персональных данных и лица, организующего 	их 	обработку 	по 	поручению 	других 	операторов, 	определяются законодательством Российской Федерации в области персональных данных. </p>
        <p>Должностные лица и Работники Общества, виновные в нарушении норм, регулирующих обработку и защиту персональных данных, несут материальную, дисциплинарную, административную, гражданско-правовую и уголовную ответственность в соответствии с законодательством Российской Федерации. </p>
        <p>Положения Политики пересматриваются по мере необходимости. Обязательный пересмотр Политики проводится в случае существенных изменений обязательных для России норм международного права или действующего законодательства Российской Федерации в сфере персональных данных. </p>
        <p>При внесении изменений в положения Политики учитываются: </p>
        <ul>
          <li>изменения в информационной инфраструктуре и (или) в используемых Обществом информационных технологиях; </li>
          <li>сложившаяся 	в 	Российской 	Федерации 	практика правоприменения законодательства в области персональных данных; </li>
          <li>изменение условий и особенностей обработки персональных данных Обществом в связи с внедрением в его деятельность новых информационных систем, процессов и технологий</li>
        </ul>
        <h1>Приложение №1 к Политике</h1>
        <h3>Пользователи сайтов и приложений Vmarafone</h3>
        <table>
          <tr>
            <th>Цели обработки ПДн </th>
            <th>Категории и перечень обрабатываемых данных </th>
          </tr>
          <tr>
            <td>Использование 	сервисов сайтов и приложений Vmarafone. </td>
            <td>
              <ul>
                <li>сведения о местоположении пользователя (в том числе данные о местоположении, когда приложение Vmarafone находится в фоне и не используется пользователем напрямую, однако запись тренировки была начата и не была завершена);</li>
                <li>контактные данные (адрес электронной почты); </li>
                <li>сведения о продолжительности тренировок; </li>
                <li>сведения о наборе высоты; </li>
                <li>сведения о маршрутах тренировок. </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <p>Срок обработки и хранения персональных данных Пользователей сайтов и приложений составляет: </p>
              <ul>
                <li>
                30 дней со дня прекращений отношений с Пользователем сайтов и приложений в отношении сведений, не указанных выше. 
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <p>По истечении сроков обработки и хранения всех перечисленных выше категорий персональных данных, соответствующие персональные данные подлежат уничтожению в порядке, предусмотренном в разделе 6 настоящей Политики.  </p>
            </td>
          </tr>
        </table>
      </Fragment>
    ),
    deleteText: (
      <Fragment>
        <p>
          Для удаления аккаунта пользователя vmarafone.club можно воспользоваться кнопкой "Удалить аккаунт" в приложении Vmarafone для IOS или Android
        </p>
        <img className="delete-screen" src="/images/delete-screenshot.png" />
        <p>
          Или написать письмо на электронную почту <a href="mailto:help@vmarafone.club">help@vmarafone.club</a> с темой письма "Удаление аккаунта", в тексте письма указать логин атлета.
        </p>
        <p>
          Заявка будет обработана только в том случае, если письмо отправлено с адреса электронной почты, привязанного к соответствующей учетной записи пользователя.
        </p>
        <p>
          Удаление учетной записи пользователя приведет к удалению всех данных, связанных с этим аккаунтом, в том числе информации о тренировках, местах занятых в соревнованиях и полученных призах.
        </p>
        <p>
          Удаление учетной записи пользователя происходит в течении 30 дней с момента подтверждения заявки.
        </p>
      </Fragment>
    ),
  },
  en: {
    yandexLogin:   "Sign in with Yandex",
    googlelogin:   "Sign in with Google",
    applelogin:    "Sign in with Apple",
    marketingText:
      "Online fitness and sport motivation platform for athletes of any level of training. The ability to compete and perform challenges in 11 types of activity, regardless of time and geographical location. Personal and team competitions, medal and prize draws with valuable prizes among the project participants. More than 5,000 athletes since September 2020!",
    compete:      "Compete with vmarafone.club",
    activities:   "Various activity kinds",
    competitions: "Competitions",
    speed:        "best speed",
    distance:     "longest distance",
    duration:     "longest duration",
    elevation:    "best elevation gain",
    challenges:   "Challenges",
    quantity:     "highest number of trainings",
    contacts:     "Contacts",
    policy:       "Privacy policy",
    delete:       "Удаление аккаунта",
    policyURL:    "./policy?lang=en",
    companyInfo:  (
      <table id="rekviz_table">
        <tbody>
          <tr>
            <td id="rekviz_table_1">Full name</td>
            <td id="rekviz_table_2">
              The limited liability company «UR Events»
            </td>
          </tr>
          <tr>
            <td>Abbreviated name</td>
            <td>LLC «UR Events»</td>
          </tr>
          <tr>
            <td>INN</td>
            <td>7714845133</td>
          </tr>
          <tr>
            <td>CAT</td>
            <td>770101001</td>
          </tr>
          <tr>
            <td>Bin</td>
            <td>1117746550239</td>
          </tr>
          <tr>
            <td>Legal address</td>
            <td>105082, Moscow, Bolshaya pochtovaya st., 26 В, building 1</td>
          </tr>
          <tr>
            <td>Email</td>
            <td>
              <a href="mailto:info@vmarafone.club">info@vmarafone.club</a>
            </td>
          </tr>
        </tbody>
      </table>
    ),
    policyText: (
      <Fragment>
        <h1>Политика ООО «ЮР Эвентс» в отношении обработки персональных данных и сведения о реализуемых требованиях к защите персональных данных, в том числе для пользователей мобильного приложения Vmarafone</h1>
        <h3>Общие положения</h3>
        <h5>1.1 Назначение документа</h5>
        <p>Политика ООО «ЮР Эвентс» в отношении обработки персональных данных и сведения о реализуемых требованиях к защите персональных данных (далее – Политика) является основополагающим локальным актом компании, регулирующим вопросы обработки персональных данных в ООО «ЮР Эвентс» (далее – Общество). </p>
        <p>Настоящая Политика разработана в соответствии с п.2 ч.1 статьи 18.1 Федерального закона от 27 июля 2006 года № 152 «О персональных данных» и предназначена для ознакомления неограниченного круга лиц, в том числе – путём опубликования на вебсайтах Общества. </p>
        <p>Политика раскрывает основные категории субъектов, персональные данные которых обрабатываются Обществом, цели, способы и принципы обработки персональных данных, права субъектов персональных данных, а также перечень мер, применяемых Обществом в целях обеспечения безопасности персональных данных при их обработке. </p>
        <p>Положения настоящей Политики являются основой для разработки локальных актов Общества, регламентирующих вопросы обработки и защиты персональных данных в Обществе.</p>
        <h5>1.2 Область действия </h5>
        <p>Действие настоящей Политики распространяется на все процессы Общества, в рамках которых осуществляется обработка персональных данных как с использованием средств вычислительной техники, в том числе с использованием информационнотелекоммуникационных сетей, так и без использования таких средств. </p>
        <h5>1.3 Основные понятия </h5>
        <p>В настоящей Политике используются следующие основные понятия: </p>
        <p>Конфиденциальность персональных данных – обязательное для соблюдения оператором или иным получившим доступ к персональным данным лицом требование не раскрывать третьим лицам и не распространять персональные данные без согласия субъекта персональных данных, если иное не предусмотрено федеральным законом. </p>
        <p>Обработка персональных данных – любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных. </p>
        <p>Оператор – государственный орган, муниципальный орган, юридическое или физическое лицо, самостоятельно или совместно с другими лицами организующие и (или) осуществляющие обработку персональных данных, а также определяющие цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными. </p>
        <p>Персональные данные – любая информация, относящаяся прямо или косвенно к определённому или определяемому физическому лицу (субъекту персональных данных). </p>
        <p>Субъект персональных данных – физическое лицо, которое прямо или косвенно определено или определяемо с помощью персональных данных. </p>
        <p>Трансграничная передача персональных данных – передача персональных данных на территорию иностранного государства органу власти иностранного государства, иностранному физическому лицу или иностранному юридическому лицу. </p>
        <h5>1.4 Утверждение и пересмотр </h5>
        <p>Настоящая Политика вступает в силу с 1 сентября 2023 года и действует до принятия ее новой редакции. </p>
        <p>Общество проводит пересмотр положений настоящей Политики и их актуализацию по мере необходимости при наступлении следующих условий: </p>
        <ul>
          <li>при изменении нормативной базы, затрагивающей принципы и (или) процессы обработки персональных данных в Обществе; </li>
          <li>при создании новых или внесении изменений в существующие процессы обработки персональных данных. </li>
        </ul>
        <h3>2 Цели сбора и обработки персональных данных </h3>
        <p>С целью поддержания деловой репутации и обеспечения выполнения требований Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных» и принятых в соответствии с ним нормативных правовых актов, Общество, являясь оператором персональных данных с соответствующими правами и обязанностями, обеспечивает соответствие обработки персональных данных требованиям законодательства Российской Федерации, а также надлежащий уровень безопасности обрабатываемых персональных данных. </p>
        <p>Обработка персональных данных в Обществе осуществляется на законной и справедливой основе и ограничивается достижением конкретных, заранее определенных и законных целей. Обработке подлежат только персональные данные, которые отвечают целям их обработки. Содержание и объем обрабатываемых персональных данных соответствуют заявленным целям обработки, избыточность обрабатываемых данных не допускается. </p>
        <p>При обработке персональных данных обеспечивается точность персональных данных, их достаточность и, в необходимых случаях, актуальность по отношению к целям обработки персональных данных. Общество принимает и обеспечивает принятие необходимых мер по удалению или уточнению неполных или неточных персональных данных.  </p>
        <p>Хранение персональных данных осуществляется в форме, позволяющей определить субъекта персональных данных, не дольше, чем этого требуют цели обработки персональных данных, и они подлежат уничтожению по достижению целей обработки или в случае утраты необходимости в их достижении, если иное не предусмотрено законодательством. </p>
        <p>Общество в рамках выполнения своей основной деятельности осуществляет обработку персональных данных пользователей мобильных приложений и сайтов Общества в сети Интернет. </p>
        <p>Цели обработки ПДн, категории и перечень обрабатываемых ПДн, категории субъектов ПДн, способы, сроки их обработки и хранения приведены в Приложении 1 к Политике. </p>
        <h3>3 Правовые основания обработки персональных данных </h3>
        <p>Обработка персональных данных Обществом допускается в следующих случаях: </p>
        <ul>
          <li>при наличии согласия Субъекта персональных данных на обработку его персональных данных;  </li>
          <li>обработка необходима для достижения целей, предусмотренных законодательством, а также для осуществления и выполнения возложенных законодательством на Общество функций, полномочий и обязанностей;  </li>
          <li>обработка необходима для осуществления прав и законных интересов Общества и/или третьих лиц либо для достижения общественно значимых целей при условии, что при этом не нарушаются права и свободы Субъектов персональных данных;  </li>
          <li>обработка осуществляется в статистических или иных исследовательских целях при условии обязательного обезличивания персональных данных; </li>
          <li>обработка персональных данных, разрешенных Субъектом персональных данных для распространения;  </li>
          <li>персональные данные подлежат опубликованию или обязательному раскрытию в соответствии с законодательством. </li>
        </ul>
        <h3>4 Порядок и условия обработки персональных данных </h3>
        <p>В отношении персональных данных Общество осуществляет обработку смешанным способом (с использованием средств автоматизации, а также без использования средств автоматизации).  </p>
        <p>Общество осуществляет следующие действия (операции) с персональными данными: сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передача (распространение, предоставление, доступ), блокирование, удаление, уничтожение персональных данных. </p>
        <p>В отношении всех категорий персональных данных Общество при сборе персональных данных обеспечивает запись, систематизацию, накопление, хранение, уточнение (обновление, изменение) и извлечение персональных данных с использованием баз данных, находящихся на территории Российской Федерации. </p>
        <p>Общество не размещает персональные данные Субъекта персональных данных в общедоступных источниках и не распространяет персональные данные неограниченному кругу лиц без предварительного согласия Субъекта персональных данных. </p>
        <p>В целях обеспечения адекватной защиты персональных данных Общество проводит оценку вреда, который может быть причинен Субъектам персональных данных в случае нарушения безопасности их персональных данных, а также определяет актуальные угрозы безопасности персональных данных при их обработке в информационных системах персональных данных. </p>
        <p>С целью обеспечения безопасности персональных данных при их обработке Общество принимает необходимые и достаточные правовые, организационные и технические меры для защиты персональных данных от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения персональных данных, а также от иных неправомерных действий в отношении персональных данных. </p>
        <p>Общество добивается того, чтобы все реализуемые мероприятия по организационной и технической защите персональных данных осуществлялись на законных основаниях, в том числе в соответствии с требованиями законодательства Российской Федерации по вопросам обработки персональных данных. </p>
        <p>Руководство Общества осознает важность и необходимость обеспечения безопасности персональных данных и поощряет постоянное совершенствование системы защиты персональных данных, обрабатываемых в рамках выполнения основной деятельности компании. </p>
        <p>В Обществе назначено лицо, ответственное за организацию обработки персональных данных. </p>
        <p>Каждый новый работник Общества, непосредственно осуществляющий обработку персональных данных, подлежит ознакомлению с требованиями законодательства Российской Федерации по обработке и обеспечению безопасности персональных данных, с настоящей Политикой и другими локальными актами по вопросам обработки и реализации требований к защите персональных данных и несет ответственность за их соблюдение. </p>
        <p>В отношении всех категорий персональных данных хранение персональных данных осуществляется Обществом в форме, позволяющей определить Субъекта персональных данных, не дольше, чем этого требуют цели обработки персональных данных, если срок хранения персональных данных не установлен федеральным законом, договором, стороной которого, выгодоприобретателем или поручителем по которому является Субъект персональных данных. Обрабатываемые персональные данные подлежат уничтожению по достижении целей обработки или в случае утраты необходимости в достижении этих целей, если иное не предусмотрено федеральным законом. </p>
        <h3>5 Обработка запросов Субъектов персональных данных </h3>
        <p>Для обеспечения соблюдения установленных законодательством прав Субъектов персональных данных в Обществе разработан и введён порядок работы с обращениями и запросами Субъектов персональных данных, а также порядок предоставления Субъектам персональных данных информации, установленной законодательством РФ в области персональных данных. </p>
        <p>Запрос Субъекта персональных данных должен содержать номер основного документа, удостоверяющего личность Субъекта персональных данных или его законного представителя, сведения о дате выдачи указанного документа и выдавшем его органе, сведения, подтверждающие участие Субъекта персональных данных в отношениях с Обществом (номер договора и дата заключения договора с Обществом, и (или) иные сведения, копию (отсканированную фотографию) сообщения в форме письма или в электронной форме, в виде смс-сообщения, полученных от Общества и т.п.), либо сведения, иным образом подтверждающие факт обработки персональных данных Обществом, подпись Субъекта персональных данных или его представителя, дату обращения. </p>
        <p>Работники Общества не имеют право отвечать на вопросы, связанные с передачей или разглашением персональных данных по телефону или факсу в связи с тем, что в таком случае нет возможности идентифицировать личность обращающегося человека. </p>
        <p>Запросы Субъектов персональных данных должны быть направлены по почте по адресу: 105082, г. Москва, ул. Большая Почтовая, д.26 В, стр. 1. </p>
        <h3>6 Порядок уничтожения персональных данных </h3>
        <p>Для обеспечения соблюдения установленных законодательством прав Субъектов персональных данных в Обществе разработан и введён порядок уничтожения ПДн. </p>
        <p>ПДн подлежат уничтожению в следующих случаях: </p>
        <ul>
          <li>по достижении целей обработки или в случае утраты необходимости в их достижении;  </li>
          <li>при выявлении фактов неправомерной обработки персональных данных (в том числе при обращении субъекта персональных данных), когда обеспечить их правомерность не представляется возможным; </li>
          <li>при получении соответствующего предписания от уполномоченного органа по защите прав субъектов ПДн; </li>
          <li>в случае отзыва согласия субъекта на обработку ПДн, когда законных оснований продолжать такую обработку у Общества не имеется; </li>
          <li>при истечении предусмотренного согласием субъекта или установленного локальными нормативными актами Общества срока обработки ПДн; </li>
          <li>­при отсутствии оснований осуществлять архивное хранение материальных носителей, содержащих такие ПДн. </li>
        </ul>
        <p>Уничтожение ПДн должно производиться в течение 30 рабочих дней с момента обнаружения данных, подлежащих уничтожению, если иной срок не предусмотрен законодательством, соглашением с субъектом ПДн или согласием субъекта, за исключением ПДн, обрабатываемых неправомерно, которые должны быть уничтожены в срок, не превышающий 10 рабочих дней с даты выявления неправомерной обработки персональных данных.  </p>
        <p>В случае отсутствия возможности уничтожения ПДн в течение срока, указанного выше, Общество осуществляет блокирование таких ПДн или обеспечивает их блокирование (если обработка ПДн осуществляется другим лицом, действующим по поручению Общества) и последующее уничтожение ПДн в срок не более чем 6 месяцев. </p>
        <p>Материальные носители персональных данных, находящиеся на архивном хранении, ПДн, содержащиеся в электронных архивах, архивные копии Баз персональных данных, содержащих уничтоженные ПДн, уничтожаются в соответствии с нормами законодательства об архивном деле в Российской Федерации. </p>
        <h3>7 Заключительные положения </h3>
        <p>Иные обязанности и права Общества как оператора персональных данных и лица, организующего 	их 	обработку 	по 	поручению 	других 	операторов, 	определяются законодательством Российской Федерации в области персональных данных. </p>
        <p>Должностные лица и Работники Общества, виновные в нарушении норм, регулирующих обработку и защиту персональных данных, несут материальную, дисциплинарную, административную, гражданско-правовую и уголовную ответственность в соответствии с законодательством Российской Федерации. </p>
        <p>Положения Политики пересматриваются по мере необходимости. Обязательный пересмотр Политики проводится в случае существенных изменений обязательных для России норм международного права или действующего законодательства Российской Федерации в сфере персональных данных. </p>
        <p>При внесении изменений в положения Политики учитываются: </p>
        <ul>
          <li>изменения в информационной инфраструктуре и (или) в используемых Обществом информационных технологиях; </li>
          <li>сложившаяся 	в 	Российской 	Федерации 	практика правоприменения законодательства в области персональных данных; </li>
          <li>изменение условий и особенностей обработки персональных данных Обществом в связи с внедрением в его деятельность новых информационных систем, процессов и технологий</li>
        </ul>
        <h1>Приложение №1 к Политике</h1>
        <h3>Пользователи сайтов и приложений Vmarafone</h3>
        <table>
          <tr>
            <th>Цели обработки ПДн </th>
            <th>Категории и перечень обрабатываемых данных </th>
          </tr>
          <tr>
            <td>Использование 	сервисов сайтов и приложений Vmarafone. </td>
            <td>
              <ul>
                <li>сведения о местоположении пользователя (в том числе данные о местоположении, когда приложение Vmarafone находится в фоне и не используется пользователем напрямую, однако запись тренировки была начата и не была завершена);</li>
                <li>контактные данные (адрес электронной почты); </li>
                <li>сведения о продолжительности тренировок; </li>
                <li>сведения о наборе высоты; </li>
                <li>сведения о маршрутах тренировок. </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <p>Срок обработки и хранения персональных данных Пользователей сайтов и приложений составляет: </p>
              <ul>
                <li>
                  30 дней со дня прекращений отношений с Пользователем сайтов и приложений в отношении сведений, не указанных выше. 
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <p>По истечении сроков обработки и хранения всех перечисленных выше категорий персональных данных, соответствующие персональные данные подлежат уничтожению в порядке, предусмотренном в разделе 6 настоящей Политики.  </p>
            </td>
          </tr>
        </table>
      </Fragment>
    ),
    deleteText: (
      <Fragment>
        <p>
          Для удаления аккаунта пользователя vmarafone.club можно воспользоваться кнопкой "Удалить аккаунт" в приложении Vmarafone для IOS или Android
        </p>
        <img className="delete-screen" src="/images/delete-screenshot.png" />
        <p>
          Или написать письмо на электронную почту <a href="mailto:help@vmarafone.club">help@vmarafone.club</a> с темой письма "Удаление аккаунта", в тексте письма указать логин атлета.
        </p>
        <p>
          Заявка будет обработана только в том случае, если письмо отправлено с адреса электронной почты, привязанного к соответствующей учетной записи пользователя.
        </p>
        <p>
          Удаление учетной записи пользователя приведет к удалению всех данных, связанных с этим аккаунтом, в том числе информации о тренировках, местах занятых в соревнованиях и полученных призах.
        </p>
        <p>
          Удаление учетной записи пользователя происходит в течении 30 дней с момента подтверждения заявки.
        </p>
      </Fragment>
    ),
  },
};

const Login = () => {
  const { rootStore, accountStore } = useStores();
  const { type } = rootStore;
  const [showLogin, setShowLogin] = useState(false);
  const [lang, setLang] = useState("ru");

  useEffect(() => {
    if (accountStore.token) {
      return false;
    }
    const hash = location.hash;
    if (!hash) {
      return false;
    }
    const result = {};
    hash.split("&").forEach((item) => {
      result[item.split("=")[0]] = decodeURIComponent(item.split("=")[1]);
    });
    if (result.id_token && result.id_token.length) {
      accountStore.setAppleToken(result.id_token, result["#code"]);
      // location.href = "/";
    } else {
      console.warn(result);
    }
    // Perform actions based on the changed hash
  }, [accountStore.token, window.location.hash]);

  // const onGoLogin = useCallback(() => {
  //   setShowLogin(true);
  // }, []);

  const onGoHome = useCallback(() => {
    setShowLogin(false);
  }, []);

  const onRU = useCallback(() => {
    setLang("ru");
  }, []);

  const onEN = useCallback(() => {
    setLang("en");
  }, []);

  useEffect(() => {
    if (window.location.search === "?lang=en" && lang !== "en") {
      setLang("en");
    }
    if (window.location.search === "?lang=ru" && lang !== "ru") {
      setLang("ru");
    }
  }, [window.location.search]);

  const isPolicy = useMemo(() => {
    return window.location.pathname === "/policy";
  }, [window.location.pathname]);

  const isDelete = useMemo(() => {
    return window.location.pathname === "/delete-account";
  }, [window.location.pathname]);

  if (isPolicy) {
    return (
      <div id="outer-container" className={`${type}`}>
        <div id="page-wrap" className={`info-page main ${type}`}>
          <div className="wrapper login">
            <a href="/">
              <ClubSwitch disabled={true} />
            </a>
            <div className="lang-holder">
              <div
                className={`lang ru color ${lang === "ru" ? "run" : ""}`}
                onClick={onRU}
              >
                RU
              </div>
              <div
                className={`lang en color ${lang === "en" ? "run" : ""}`}
                onClick={onEN}
              >
                EN
              </div>
            </div>

            <div className="container">
              <p className="marketing-text-half">
                <h3 className={`header-text color ${type}`}>
                  {translation[lang].policy}
                </h3>
                <div className="compete-holder padding">
                  {translation[lang].policyText}
                </div>
              </p>
              <div className="pre-footer gradient ride"></div>
              <h3 className={"header-text color white"}>
                {translation[lang].contacts}
              </h3>
              <div className="contacts">
                <a
                  className={"gradient button run"}
                  href="mailto:info@vmarafone.club"
                >
                  <IoIosMail /> info@vmarafone.club
                </a>
                <a
                  className={"gradient button ski"}
                  href="https://t.me/vmarafone"
                >
                  <FaTelegram /> @vmarafone
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (isDelete) {
    return (
      <div id="outer-container" className={`${type}`}>
        <div id="page-wrap" className={`info-page main ${type}`}>
          <div className="wrapper login">
            <a href="/">
              <ClubSwitch disabled={true} />
            </a>
            <div className="lang-holder">
              <div
                className={`lang ru color ${lang === "ru" ? "run" : ""}`}
                onClick={onRU}
              >
                RU
              </div>
              <div
                className={`lang en color ${lang === "en" ? "run" : ""}`}
                onClick={onEN}
              >
                EN
              </div>
            </div>

            <div className="container">
              <p className="marketing-text-half">
                <h3 className={`header-text color ${type}`}>
                  {translation[lang].delete}
                </h3>
                <div className="compete-holder padding">
                  {translation[lang].deleteText}
                </div>
              </p>
              <div className="pre-footer gradient ride"></div>
              <h3 className={"header-text color white"}>
                {translation[lang].contacts}
              </h3>
              <div className="contacts">
                <a
                  className={"gradient button run"}
                  href="mailto:info@vmarafone.club"
                >
                  <IoIosMail /> info@vmarafone.club
                </a>
                <a
                  className={"gradient button ski"}
                  href="https://t.me/vmarafone"
                >
                  <FaTelegram /> @vmarafone
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (!showLogin) {
    return (
      <div id="outer-container" className={`${type}`}>
        <div id="page-wrap" className={`info-page main ${type}`}>
          <div className="wrapper login">
            <ClubSwitch disabled={true} />
            <div className="lang-holder">
              <div
                className={`lang ru color ${lang === "ru" ? "run" : ""}`}
                onClick={onRU}
              >
                RU
              </div>
              <div
                className={`lang en color ${lang === "en" ? "run" : ""}`}
                onClick={onEN}
              >
                EN
              </div>
            </div>
            {DEV && (
              <div className="misc">
                <IoIosPerson
                  className="fake-login"
                  onClick={async() => {
                    await accountStore.login();
                    window.location.reload(true);
                  }}
                />
                <IoIosWalk
                  className="fake-login"
                  onClick={async() => {
                    await accountStore.loginStrava();
                    window.location.reload(true);
                  }}
                />
              </div>
            )}
            <div className="button-holder">
              <a className="button-link" href={appleLink}>
                <div className="login-button yandex">
                  <svg
                    className="y-icon"
                    viewBox="-500 -300 1800 1800"
                    id="ya"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g fill="none" fillRule="evenodd">
                      <path fill="#FFF" d="M0 0h34v34H0z"></path>
                      <path fill="#000" d="M788.1 340.9c-5.8 4.5-108.2 62.2-108.2 190.5 0 148.4 130.3 200.9 134.2 202.2-.6 3.2-20.7 71.9-68.7 141.9-42.8 61.6-87.5 123.1-155.5 123.1s-85.5-39.5-164-39.5c-76.5 0-103.7 40.8-165.9 40.8s-105.6-57-155.5-127C46.7 790.7 0 663 0 541.8c0-194.4 126.4-297.5 250.8-297.5 66.1 0 121.2 43.4 162.7 43.4 39.5 0 101.1-46 176.3-46 28.5 0 130.9 2.6 198.3 99.2zm-234-181.5c31.1-36.9 53.1-88.1 53.1-139.3 0-7.1-.6-14.3-1.9-20.1-50.6 1.9-110.8 33.7-147.1 75.8-28.5 32.4-55.1 83.6-55.1 135.5 0 7.8 1.3 15.6 1.9 18.1 3.2.6 8.4 1.3 13.6 1.3 45.4 0 102.5-30.4 135.5-71.3z" />              </g>
                  </svg>
                  <span className="y-text">{translation[lang].applelogin}</span>
                </div>
              </a>      
              <a className="button-link" href="/accounts/yandex/login/">
                <div className="login-button yandex">
                  <svg
                    className="y-icon"
                    viewBox="0 0 34 34"
                    id="ya"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g fill="none" fillRule="evenodd">
                      <path fill="#FFF" d="M0 0h34v34H0z"></path>
                      <path
                        fill="red"
                        fillRule="nonzero"
                        d="M18.811 19.477v7.52h2.187V7.002h-3.266c-3.21 0-5.908 2.168-5.908 6.392 0 3.014 1.193 4.648 2.982 5.549L11 26.997h2.528l3.465-7.52h1.818zm-.007-1.754H17.64c-1.903 0-3.466-1.042-3.466-4.28 0-3.352 1.704-4.548 3.466-4.548h1.164v8.828z"
                      ></path>
                    </g>
                  </svg>
                  <span className="y-text">
                    {translation[lang].yandexLogin}
                  </span>
                </div>
              </a>
              <a className="button-link" href="/accounts/google/login/">
                <div className="login-button google">
                  <svg
                    className="g-icon"
                    viewBox="0 0 40 40"
                    id="google-mobile-2"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g fill="none" fillRule="evenodd">
                      <rect
                        width="38"
                        height="38"
                        rx="2"
                        transform="translate(1 1)"
                        fill="#FFF"
                      ></rect>
                      <g fillRule="nonzero">
                        <path
                          d="M29.6 20.227c0-.709-.064-1.39-.182-2.045H20v3.868h5.382a4.6 4.6 0 01-1.996 3.018v2.51h3.232c1.891-1.742 2.982-4.305 2.982-7.35z"
                          fill="#4285F4"
                        ></path>
                        <path
                          d="M20 30c2.7 0 4.964-.895 6.618-2.423l-3.232-2.509c-.895.6-2.04.955-3.386.955-2.605 0-4.81-1.76-5.595-4.123h-3.341v2.59A9.996 9.996 0 0020 30z"
                          fill="#34A853"
                        ></path>
                        <path
                          d="M14.405 21.9c-.2-.6-.314-1.24-.314-1.9 0-.66.114-1.3.314-1.9v-2.59h-3.341A9.996 9.996 0 0010 20c0 1.614.386 3.14 1.064 4.49l3.34-2.59z"
                          fill="#FBBC05"
                        ></path>
                        <path
                          d="M20 13.977c1.468 0 2.786.505 3.823 1.496l2.868-2.868C24.959 10.99 22.695 10 20 10c-3.91 0-7.29 2.24-8.936 5.51l3.34 2.59c.787-2.364 2.991-4.123 5.596-4.123z"
                          fill="#EA4335"
                        ></path>
                      </g>
                    </g>
                  </svg>
                  <span className="g-text">
                    {translation[lang].googlelogin}
                  </span>
                </div>
              </a>
            </div>
            <div className="policy-link-holder">
              <a
                className="policy-link"
                traget="blank"
                href={translation[lang].policyURL}
              >
                <FaEye />
                <span className="g-text">{translation[lang].policy}</span>
              </a>
            </div>
            <div className="container">
              <div className="pre-header gradient run"></div>
              <PhotoGallery />
              <p className="marketing-text">
                {translation[lang].marketingText}
              </p>
              <h3 className={"header-text color white"}>
                {translation[lang].compete}
              </h3>
              <Motivation lang={lang} />
              <h3 className={`header-text color ${type}`}>
                {translation[lang].activities}
              </h3>
              <ActivityTypes lang={lang} withBack={true} />
              <p className="marketing-text-half">
                <h3 className={`header-text color ${type}`}>
                  {translation[lang].competitions}
                </h3>
                <div className="compete-holder">
                  <div className="compete-by gradient run">
                    <div className="icon">
                      <IoIosSpeedometer />
                    </div>
                    <div className="text">{translation[lang].speed}</div>
                  </div>
                  <div className="compete-by gradient run">
                    <div className="icon">
                      <RiRouteLine />
                    </div>
                    <div className="text">{translation[lang].distance}</div>
                  </div>
                  <div className="compete-by gradient run">
                    <div className="icon">
                      <IoIosTime />
                    </div>
                    <div className="text">{translation[lang].duration}</div>
                  </div>
                  <div className="compete-by gradient run">
                    <div className="icon">
                      <GiMountainRoad />
                    </div>
                    <div className="text">{translation[lang].elevation}</div>
                  </div>
                </div>
              </p>
              <p className="marketing-text-half">
                <h3 className={`header-text color ${type}`}>
                  {translation[lang].challenges}
                </h3>
                <div className="compete-holder">
                  <div className="compete-by gradient ski">
                    <div className="icon">
                      <IoIosDoneAll />
                    </div>
                    <div className="text">{translation[lang].quantity}</div>
                  </div>
                  <div className="compete-by gradient ski">
                    <div className="icon">
                      <RiRouteLine />
                    </div>
                    <div className="text">{translation[lang].distance}</div>
                  </div>
                  <div className="compete-by gradient ski">
                    <div className="icon">
                      <IoIosTime />
                    </div>
                    <div className="text">{translation[lang].duration}</div>
                  </div>
                </div>
              </p>
              <div className="pre-footer gradient ride"></div>
              <h3 className={"header-text color white"}>
                {translation[lang].contacts}
              </h3>
              <div className="contacts">
                <a
                  className={"gradient button run"}
                  href="mailto:info@vmarafone.club"
                >
                  <IoIosMail /> info@vmarafone.club
                </a>
                <a
                  className={"gradient button ski"}
                  href="https://t.me/vmarafone"
                >
                  <FaTelegram /> @vmarafone
                </a>
              </div>
              <div className="company-info marketing-text-half">
                <div className="compete-holder">
                  {translation[lang].companyInfo}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }

  return (
    <div id="container" className="container">
      <div className="top"></div>
      <div className="bottom"></div>
      <div className="center">
        <div className="logo-holder">
          <div className="logo" />
          <div className="icons">
            <Run />
            <Ride />
            <Ski />
          </div>
        </div>
        {
          <a
            className="button-link"
            target="_blank"
            href={"/api/v0/docs/presentation"}
          >
            <div className="login-button presentation">
              <div className="p-icon">
                <IoIosTv />
              </div>
              <span className="p-text">Презентация проекта</span>
            </div>
          </a>
        }
        <h2 className="header">
          {DEV && (
            <IoIosPerson
              className="fake-login"
              onClick={async() => {
                await accountStore.login();
                window.location.reload(true);
              }}
            />
          )}
          Войти
          {DEV && (
            <IoIosWalk
              className="fake-login"
              onClick={async() => {
                await accountStore.loginStrava();
                window.location.reload(true);
              }}
            />
          )}
        </h2>
        <a className="button-link" href={appleLink}>
          <div className="login-button apple">
            <svg
              className="y-icon"
              viewBox="0 0 34 34"
              id="ya"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g fill="none" fillRule="evenodd">
                <path fill="#FFF" d="M0 0h34v34H0z"></path>
                <path xmlns="http://www.w3.org/2000/svg" d="M788.1 340.9c-5.8 4.5-108.2 62.2-108.2 190.5 0 148.4 130.3 200.9 134.2 202.2-.6 3.2-20.7 71.9-68.7 141.9-42.8 61.6-87.5 123.1-155.5 123.1s-85.5-39.5-164-39.5c-76.5 0-103.7 40.8-165.9 40.8s-105.6-57-155.5-127C46.7 790.7 0 663 0 541.8c0-194.4 126.4-297.5 250.8-297.5 66.1 0 121.2 43.4 162.7 43.4 39.5 0 101.1-46 176.3-46 28.5 0 130.9 2.6 198.3 99.2zm-234-181.5c31.1-36.9 53.1-88.1 53.1-139.3 0-7.1-.6-14.3-1.9-20.1-50.6 1.9-110.8 33.7-147.1 75.8-28.5 32.4-55.1 83.6-55.1 135.5 0 7.8 1.3 15.6 1.9 18.1 3.2.6 8.4 1.3 13.6 1.3 45.4 0 102.5-30.4 135.5-71.3z" />              </g>
            </svg>
            <span className="y-text">Вход с Apple</span>
          </div>
        </a>
        <a className="button-link" href="/accounts/yandex/login/">
          <div className="login-button yandex">
            <svg
              className="y-icon"
              viewBox="0 0 34 34"
              id="ya"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g fill="none" fillRule="evenodd">
                <path fill="#FFF" d="M0 0h34v34H0z"></path>
                <path
                  fill="red"
                  fillRule="nonzero"
                  d="M18.811 19.477v7.52h2.187V7.002h-3.266c-3.21 0-5.908 2.168-5.908 6.392 0 3.014 1.193 4.648 2.982 5.549L11 26.997h2.528l3.465-7.52h1.818zm-.007-1.754H17.64c-1.903 0-3.466-1.042-3.466-4.28 0-3.352 1.704-4.548 3.466-4.548h1.164v8.828z"
                ></path>
              </g>
            </svg>
            <span className="y-text">Вход с Яндекс</span>
          </div>
        </a>
        <a className="button-link" href="/accounts/google/login/">
          <div className="login-button google">
            <svg
              className="g-icon"
              viewBox="0 0 40 40"
              id="google-mobile-2"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g fill="none" fillRule="evenodd">
                <rect
                  width="38"
                  height="38"
                  rx="2"
                  transform="translate(1 1)"
                  fill="#FFF"
                ></rect>
                <g fillRule="nonzero">
                  <path
                    d="M29.6 20.227c0-.709-.064-1.39-.182-2.045H20v3.868h5.382a4.6 4.6 0 01-1.996 3.018v2.51h3.232c1.891-1.742 2.982-4.305 2.982-7.35z"
                    fill="#4285F4"
                  ></path>
                  <path
                    d="M20 30c2.7 0 4.964-.895 6.618-2.423l-3.232-2.509c-.895.6-2.04.955-3.386.955-2.605 0-4.81-1.76-5.595-4.123h-3.341v2.59A9.996 9.996 0 0020 30z"
                    fill="#34A853"
                  ></path>
                  <path
                    d="M14.405 21.9c-.2-.6-.314-1.24-.314-1.9 0-.66.114-1.3.314-1.9v-2.59h-3.341A9.996 9.996 0 0010 20c0 1.614.386 3.14 1.064 4.49l3.34-2.59z"
                    fill="#FBBC05"
                  ></path>
                  <path
                    d="M20 13.977c1.468 0 2.786.505 3.823 1.496l2.868-2.868C24.959 10.99 22.695 10 20 10c-3.91 0-7.29 2.24-8.936 5.51l3.34 2.59c.787-2.364 2.991-4.123 5.596-4.123z"
                    fill="#EA4335"
                  ></path>
                </g>
              </g>
            </svg>
            <span className="g-text">Вход с Google</span>
          </div>
        </a>
        <a className="button-link" onClick={onGoHome} href="#">
          <div className={`home-button button gradient ${type}`}>
            <IoIosHome />
            <span className="g-text">На главную</span>
          </div>
        </a>
      </div>
      <div className="powered" />
    </div>
  );
};

export default Login;
