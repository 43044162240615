import React, { useState } from "react";
import { observer } from "mobx-react";
import AsyncSelect from "react-select/async";

import useStores from "~/hooks";
import Athlete from "./Athlete";

const AthleteSearch = observer(({ closeModal }) => {
  const { athletesStore, rootStore } = useStores();
  const { type } = rootStore;

  const [athlete, setAthlete] = useState();

  const loadOptions = async(inputValue, callback) => {
    try {
      const data = await athletesStore.getFilteredAthletes(inputValue);
      callback(data);
    } catch (error) {
      console.warn(error);
    }
  };

  const handleInputChange = (value) => {
    setAthlete(value);
  };

  const onConnectProfile = async() => {
    await athletesStore.connectProfile(athlete.id);
    closeModal();
  };

  return (
    <div className={`athlete-search  ${type}`}>
      <AsyncSelect
        placeholder="Выберите атлета"
        loadingMessage={() => {
          return "Загрузка...";
        }}
        noOptionsMessage={() => {
          return "Нет подходящих результатов";
        }}
        classNamePrefix={"athlete-search"}
        cacheOptions={true}
        defaultOptions={true}
        loadOptions={loadOptions}
        onChange={handleInputChange}
      />
      {athlete && (
        <div className="athlete-holder">
          <Athlete noNumbers={true} noPlaces={true} id={athlete.id} />
          <div className="form-text" style={{ margin: "1rem", textAlign: "center" }}>
            Убедитесь пожалуйста, что это ваш профиль. Проверяйте внимательно,
            это очень важно.
          </div>
          <div className="buttons">
            <div
              className={`button gradient ${type}`}
              onClick={onConnectProfile}
            >
              Связать профиль
            </div>
          </div>
        </div>
      )}
    </div>
  );
});

export default AthleteSearch;
