import React, { useEffect } from "react";
import { observer } from "mobx-react";

import { IoIosPodium } from "react-icons/io";

import useStores from "~/hooks";

import { NavLink, Preloader, Top } from "~/components/common";
import { TeamTop } from "~/components/team";

const RatingCard = observer(({ rating, isTeam }) => {
  const { rootStore } = useStores();
  const { type, sex } = rootStore;

  const { id, name, isPending, top, isAnnotationPending } = rating;

  useEffect(() => {
    rating.getAnnotation(sex);
  }, [sex, rating]);

  if (isPending || isAnnotationPending) {
    return <Preloader />;
  }

  const footer = null;

  let content = null;
  const ids = top[sex];

  content = <Top key="top" ids={ids} linkPrefix={"/"} />;

  if (isTeam) {
    content = <TeamTop key="top" ids={ids} linkPrefix={"/"} />;
  }

  return (
    <div className={`challenge-card ${type}`}>
      <NavLink
        className={`gradient ${type}`}
        to={`/${isTeam ? "team-" : ""}rating/${id}/`}
      >
        <div className="name">
          <IoIosPodium />
          {name}
        </div>
      </NavLink>
      <div className="info">
        {content}
        <div className="status-holder">{footer}</div>
      </div>
    </div>
  );
});

export default RatingCard;
