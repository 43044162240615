import React, { useEffect } from "react";
import { IoIosStar, IoIosClose } from "react-icons/io";
import { observer } from "mobx-react";

import useStores from "~/hooks";

import { Preloader, ActivitiesList } from "~/components/common";
import MultiSwitch from "~/components/multiSwitch";
import ChallengeCard from "./ChallengeCard";

const AthleteInChallenge = observer(({ challengeId, athleteId }) => {
  const { rootStore, seasonsStore, accountStore } = useStores();
  const { athleteId: accountId } = accountStore;
  const { kind, sex } = rootStore;
  const { currentSeason, currentSeasonId } = seasonsStore;
  const isMale = sex !== "females";

  if (!currentSeason) {
    return (
      <div className="challenges page">
        <div className="page-header"><IoIosStar /> Вызов</div>
        <div className="page-warning"><IoIosClose /> Не выбран сезон</div>
      </div>
    );
  }

  const { challengesStore } = currentSeason;
  const { isPending } = challengesStore;

  useEffect(() => {
    seasonsStore.initChallengesStore();
  }, [kind, currentSeasonId]);

  const challenge = challengesStore.challenges.get(`${challengeId}`);
  useEffect(() => {
    challenge && challenge.getActivitiesInfo(athleteId);
  }, [kind, currentSeasonId, challengeId, challenge, isMale]);

  if (isPending) {
    return <Preloader />;
  }

  if (!challenge) {
    return <Preloader />;
  }  

  const { isActivitiesPending } = challenge;
  const activities = challenge.getActivities(athleteId);
  const activitiesForCalendar = challenge.getActivitiesForCalendar(athleteId);

  return (
    <div className="challenges info page">
      <MultiSwitch
        page="challenges" 
        id={challengeId}
        linkPrefix={"/"}
        noSport={true} 
        noMonth={true}
        noSeason={true}
      />
      <ChallengeCard 
        challenge={challenge}
        showConditions={true}
        isAnnotation={true}
        isDirectAthleteLink={true}
        athleteId={athleteId || accountId}
      />
      <ActivitiesList
        challenge={challenge}
        athleteId={athleteId}
        activities={activities}
        activitiesForCalendar={activitiesForCalendar}
        isPending={isActivitiesPending}
      />
    </div>
  );
});

export default AthleteInChallenge;
