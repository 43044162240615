import { observable, action, computed } from "mobx";

import AccountStore from "./AccountStore";
import AthletesStore from "./AthletesStore";
import SeasonsStore from "./SeasonsStore";
import Api from "../api/Api";
import CompaniesStore from "./CompaniesStore";
import NewsStore from "./NewsStore";

import PageInfo from "../models/PageInfo";

const allowedTypes = new Set([
  "all",
  "run",
  "ride",
  "ski",
  "walk",
  "treadmill",
  "exercycle",
  "bikestation",
  "mountainbike",
  "mountain",
  "pool",
  "swim",
  "multisport",
  "skating",
  "various",
]);

export default class RootStore {
  @observable
  counter = 0;
  @observable
  accountStore = null;
  @observable
  companiesStore = null;
  @observable
  newsStore = null;
  @observable
  athletesStore = null;
  @observable
  challengesStore = null;
  @observable
  seasonsStore = null;

  @observable
  api = null;

  @observable
  open = false;

  @observable
  withCompetitions = false;

  @observable
  kind = "run";

  @observable
  sex = "males";

  @observable
  faqData = null;
  @observable
  faqPending = false;

  @observable
  pageInfo = null;
  @observable
  pageInfoPending = false;
  @observable
  gapPending = false;
  @observable
  gap = 0;

  constructor() {
    const { season, type, month, sex, club } = this.processURLSearch();

    this.api = new Api(this);
    // разместить до AccountStore чтоб не было гонок с его инициализацией
    this.newsStore = new NewsStore(this);
    this.companiesStore = new CompaniesStore(this);
    this.seasonsStore = new SeasonsStore(this, season, month);
    this.athletesStore = new AthletesStore(this);
    
    this.accountStore = new AccountStore(this, club);
    if (type && allowedTypes.has(type)) {
      this.setType(type);
    }

    this.sex = this.accountStore.isMale ? "males" : "females";
    if (sex) {
      this.setSex(sex);
    }
  }

  async sleep(m) {
    return new Promise((r) => {
      return setTimeout(r, m);
    });
  }

  async getPageInfo(page, id, kind, sex, from, to, company) {
    this.setPageInfoPending(true);
    try {
      const info = await this.api.getPageInfo(
        page,
        id,
        kind,
        sex[0].toUpperCase(),
        from,
        to,
        company
      );
      this.pageInfo = new PageInfo(info, this);
    } catch (error) {
      console.warn("No page info");
    }
    this.setPageInfoPending();
  }

  async getManualActivityUploadGap() {
    this.setGapPending(true);
    const data = await this.api.getAthlete("me");
    if (data && data.manualActivityUploadGap !== undefined) {
      this.gap = data.manualActivityUploadGap;
    }
    this.setGapPending(false);
  }

  @action
  setPageInfoPending(pending = false) {
    this.pageInfoPending = pending;
  }

  @action
  setGapPending(pending = false) {
    this.gapPending = pending;
  }

  async getFaq() {
    if (!this.isFaqPending) {
      this.setFaqPending(true);
      const faqData = await this.api.getFaq();
      this.setFaqData(faqData);
      this.setFaqPending();
    }
  }

  @action
  setFaqData(data) {
    this.faqData = (data && data.content) || null;
  }

  @action
  setFaqPending(pending = false) {
    this.faqPending = pending;
  }

  @computed
  get isFaqPending() {
    return this.faqPending;
  }

  @computed
  get isGapPending() {
    return this.gapPending;
  }

  @computed
  get manualActivityGap() {
    return this.gap;
  }

  @computed
  get isPageInfoPending() {
    return this.pageInfoPending;
  }

  @computed
  get faqContent() {
    return this.faqData;
  }

  @action
  processURLSearch() {
    const urlParams = new URLSearchParams(window.location.search);
    const type = urlParams.get("kind");
    const season = urlParams.get("season");
    const month = urlParams.get("month");
    const sex = urlParams.get("sex");
    const club = urlParams.get("club");
    return { season, type, month, sex, club };
  }

  @action
  changeURLSearch() {
    const { club } = this.processURLSearch();
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set("kind", this.kind);
    urlParams.set("season", this.seasonsStore.currentSeasonId);
    urlParams.set("month", this.seasonsStore.initialMonthId);
    urlParams.set("sex", this.sex);
    if (this.accountStore) {
      urlParams.set("club", this.accountStore.currentClubId || "vmarafone");
    } else {
      urlParams.set("club", club);
    }
    if (history.replaceState) {
      const newurl = `${window.location.origin}${window.location.pathname}?${urlParams}`;
      window.history.replaceState({ path: newurl }, "", newurl);
    }
  }

  @action
  init(token) {
    this.accountStore.setToken(token);
  }

  @action
  setIsOpen(open = false) {
    if (window.navigator.vibrate) {
      window.navigator.vibrate([30]);
    }

    this.open = open;
  }

  @action
  setType(type) {
    this.kind = type;
    this.changeURLSearch();
  }

  @action
  setSex(sex = "males") {
    this.sex = sex;
    this.changeURLSearch();
  }

  @computed
  get doubleCount() {
    return this.counter * 2;
  }

  @computed
  get currentSex() {
    if (this.sex) {
      return this.sex;
    }
    return this.accountStore.isMale ? "males" : "females";
  }

  @computed
  get isOpen() {
    return this.open;
  }

  getTypeByKind(kind) {
    const typesByKind = [
      {
        id:    "run",
        items: new Set(["run", "walk", "treadmill", "all", "various"]),
      },
      {
        id:    "ride",
        items: new Set(["ride", "exercycle", "bikestation", "mountainbike"]),
      },
      {
        id:    "ski",
        items: new Set([
          "ski",
          "mountain",
          "swim",
          "pool",
          "skating",
          "multisport",
        ]),
      },
    ];
    let type = kind.toLowerCase();
    typesByKind.forEach((record) => {
      if (record.items.has(kind)) {
        type = record.id;
      }
    });
    return type;
  }

  @computed
  get type() {
    return this.getTypeByKind(this.kind);
  }

  @computed
  get token() {
    return this.accountStore && this.accountStore.token;
  }

  @computed
  get isPending() {
    return (
      this.seasonsStore.isPending ||
      this.companiesStore.isPending ||
      this.accountStore.isPending
    );
  }
}
