import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { IoIosClose } from "react-icons/io";

import useStores from "~/hooks";

import { NavLink, Preloader } from "~/components/common";
import { ChallengeCard } from "~/components/challenge";
import { CompetitionCard } from "~/components/competition";
import { TeamChallengeCard } from "~/components/team";

const ChallengesList = observer(({ store, currentSeasonId, isAnnotation, athleteId, companyId }) => {
  const { rootStore } = useStores();
  const { type, kind } = rootStore;
  const { isPending, rangedChallengeList } = store;
  useEffect(() => {
    store.init();
  }, [kind, currentSeasonId]);

  if (
    !isPending 
    && (!rangedChallengeList || rangedChallengeList.length < 1) 
  ) {
    return (
      <div className="challenges-warn">
        <div className="page-warning">
          <IoIosClose /> Нет вызовов
        </div>
      </div>
    );
  }

  const team = [];
  const competitions = [];
  const challenges = [];
  const multisportCompetitions = [];

  rangedChallengeList.forEach((challenge) => {
    const card = null;
    switch (challenge.class) {
    case "team": 
      team.push(
        <TeamChallengeCard
          companyId={companyId}
          isAnnotation={isAnnotation} 
          key={`${challenge.class}-${challenge.id}`} 
          challenge={challenge} 
          isDirectCompanyLink={true}
        />
      );       
      break;
    case "multisport-competition":        
      multisportCompetitions.push(
        <CompetitionCard
          isDirectAthleteLink={true}
          athleteId={athleteId}
          isAnnotation={isAnnotation} 
          key={`${challenge.class}-${challenge.id}`} 
          challenge={challenge}
        />
      );
      break;
    case "competition":        
      competitions.push(
        <CompetitionCard
          athleteId={athleteId}
          isAnnotation={isAnnotation} 
          key={`${challenge.class}-${challenge.id}`} 
          challenge={challenge}
        />
      );
      break;
    case "challenge":        
      challenges.push(
        <ChallengeCard
          athleteId={athleteId}
          isAnnotation={isAnnotation} 
          key={`${challenge.class}-${challenge.id}`} 
          challenge={challenge} 
        />
      );
      break;
    default:
      challenges.push(
        <ChallengeCard
          athleteId={athleteId}
          isAnnotation={isAnnotation} 
          key={`${challenge.class}-${challenge.id}`} 
          challenge={challenge} 
        />
      );
      break;
    }
    return card;
  });

  return (
    <div className={`challenges-list ${type}`}>
      {isPending && <Preloader />}
      {!isPending && !!team.length && (
        <div className="team-challenge-widget-holder">
          <NavLink to={"/"}>
            <div className="name">Командный объем</div>
          </NavLink>
          <div className="team-challenge-card-holder">
            {team}
          </div>
        </div>
      )}
      {!isPending && !!multisportCompetitions.length && (
        <div className="competitions-widget-holder">
          <NavLink to={"/"}>
            <div className="name">Мультиспорт</div>
          </NavLink>
          <div className="competitions-card-holder">
            {multisportCompetitions}
          </div>
        </div>
      )}
      {!isPending && !!competitions.length && (
        <div className="competitions-widget-holder">
          <NavLink to={"/"}>
            <div className="name">Соревнования</div>
          </NavLink>
          <div className="competitions-card-holder">
            {competitions}
          </div>
        </div>
      )}
      {!isPending && !!challenges.length && (
        <div className="challenges-widget-holder">
          <NavLink to={"/"}>
            <div className="name">Вызовы</div>
          </NavLink>
          <div className="challenges-card-holder">
            {challenges}
          </div>
        </div>
      )}
    </div>
  );
});
export default ChallengesList;
