import React from "react";
import { observer } from "mobx-react";

import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

import useStores from "~/hooks";

import Month from "./Month";

const MonthsSwitch = observer(({ withEmptyMonth }) => {
  const { seasonsStore, rootStore } = useStores();
  const { type } = rootStore;
  const { currentSeason } = seasonsStore;

  if (!currentSeason) {
    return null;
  }

  const { currentMonth } = currentSeason;

  const onNext = () => {
    currentSeason.onSwitch(1, withEmptyMonth);
    if (window.navigator.vibrate) {
      window.navigator.vibrate([30]);
    }
  };

  const onPrev = () => {
    currentSeason.onSwitch(-1, withEmptyMonth);
    if (window.navigator.vibrate) {
      window.navigator.vibrate([30]);
    }
  };

  return (
    <div className="season-holder">
      <div className={`seasons ${type}`} >
        <IoIosArrowBack onClick={onPrev} />
        <Month month={currentMonth} />
        <IoIosArrowForward onClick={onNext} />
      </div>
    </div>
  );
});
export default MonthsSwitch;
