import React, { useCallback, useMemo, useState } from "react";
import { observer } from "mobx-react";
import moment from "moment";

import useStores from "~/hooks";

import { NavLink } from "~/components/common";
import Switch from "../components/common/Switch";
import { IoIosMan, IoIosWoman } from "react-icons/io";

const InitialForm = observer(() => {
  const { rootStore, accountStore } = useStores();
  const { type, isPending } = rootStore;
  const { profile, personalDataAgreementDate } = accountStore;
  if (isPending) {
    return <Preloader />;
  }

  const [date, setDate] = useState(
    profile && profile.birthday && profile.birthday.format("YYYY-MM-DD")
  );
  const [email, setEmail] = useState((profile && profile.email) || "");
  const [firstName, setFirstName] = useState(profile && profile.firstName);
  const [lastName, setLastName] = useState(profile && profile.lastName);
  const [reminder, setReminder] = useState();
  const [personal, setPersonal] = useState(!!personalDataAgreementDate);
  const [sexValue, setSexValue] = useState(profile && profile.isMale);
  
  const onCheck = useCallback(() => {
    setReminder(!reminder);
  }, [reminder]);

  const onCheckPersonal = useCallback(() => {
    setPersonal(!personal);
  }, []);

  const onInput = (e) => {
    setDate(e.target.value);
  };

  const onSetEmail = useCallback((e) => {
    setEmail(e.target.value);
  }, []);

  const onFirstName = useCallback((e) => {
    setFirstName(e.target.value);
  }, []);

  const onLastName = useCallback((e) => {
    setLastName(e.target.value);
  }, []);
  const canSend = useMemo(() => {
    return !!firstName && !!lastName && !!personal && !!email;
  }, [firstName, lastName, personal, email]);

  const onSwitch = useCallback(() => {
    setSexValue(!sexValue);
  }, [sexValue]);

  const onSend = useCallback(() => {
    if (canSend) {
      accountStore.sendInfo(
        date && moment(date),
        email,
        firstName,
        lastName,
        sexValue ? "M" : "F",
        personal,
        reminder
      );
    }
  }, [date, email, firstName, lastName, personal, sexValue, reminder]);

  return (
    <div id="outer-container" className={`${type}`}>
      <div id="page-wrap" className={`main ${type}`}>
        <div className="wrapper">
          <div className={`header ${type} gradient`}>
            <div className="angle-holder">
              <div className="angle"></div>
            </div>
            <NavLink to="/">
              <div className="logo"></div>
            </NavLink>
          </div>
          <div className="container">
            <div className={`${type} user-form`}>
              <div className={`${type} before gradient`}></div>
              <div className="form-holder">
                <div className={`form-title ${type} gradient`}>
                  Добро пожаловать в клуб!
                </div>
                <div className="form-content">
                  <div className="form-text">
                    Пожалуйста введите следующие данные согласно заявке вашей
                    компании:
                  </div>
                  <div className="fields-holder">
                    <div className="field-holder">
                      <div className="title">* Email</div>
                      <input
                        placeholder="Введите email"
                        onChange={onSetEmail}
                        name="email"
                        value={email}
                        type="text"
                        inputMode="email"
                        className="field"
                      />
                    </div>
                    <div className="field-holder">
                      <div className="title">Пол</div>
                      <Switch
                        value={sexValue}
                        type={type}
                        titles={[
                          <span className="switch-body-render" key="f">
                            <IoIosWoman /> Женский
                          </span>,
                          <span className="switch-body-render" key="f">
                            <IoIosMan /> Мужской
                          </span>,
                        ]}
                        onChange={onSwitch}
                      />
                    </div>
                    <div className="field-holder">
                      <div className="title">Дата рождения</div>
                      <input
                        placeholder="Введите дату"
                        onChange={onInput}
                        value={date}
                        type="date"
                        className="field"
                      />
                    </div>
                    <div className="field-holder">
                      <div className="title">* Имя</div>
                      <input
                        placeholder="Введите имя"
                        onChange={onFirstName}
                        value={firstName}
                        type="text"
                        className="field"
                      />
                    </div>
                    <div className="field-holder">
                      <div className="title">* Фамилия</div>
                      <input
                        placeholder="Введите фамилию"
                        onChange={onLastName}
                        value={lastName}
                        type="text"
                        className="field"
                      />
                    </div>
                    <div className="field-holder">
                      <div className="title"></div>
                      <p className="field">
                        <input
                          type="checkbox"
                          className="checkbox"
                          name="reminder"
                          id="reminder"
                          checked={reminder ? "checked" : null}
                          onChange={onCheck}
                        />
                        <label htmlFor="reminder">Больше не спрашивать</label>
                      </p>
                    </div>
                    <div className="field-holder">
                      <div className="title"></div>
                      <p className="field">
                        <input
                          type="checkbox"
                          className="checkbox"
                          name="personal"
                          id="personal"
                          checked={personal ? "checked" : null}
                          onChange={onCheckPersonal}
                        />
                        <label htmlFor="personal">
                          <p className="personal-label">
                            Даю согласие на{" "}
                            <a target="_blank" href="/personal/">
                              обработку персональных данных
                            </a>
                          </p>
                        </label>
                      </p>
                    </div>
                    <div
                      onClick={onSend}
                      className={`button gradient ${canSend && type} ${
                        canSend ? "" : "disabled"
                      }`}
                    >
                      Ok
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer">
              <div className="left"></div>
              <div className="middle"></div>
              <div className={`right ${type} gradient`}>
                <div className="powered" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default InitialForm;
