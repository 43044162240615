import React, { useEffect } from "react";
import { observer } from "mobx-react";

import { IoIosCloseCircle, IoIosPeople } from "react-icons/io";

import useStores from "~/hooks";

import MultiSwitch from "~/components/multiSwitch";
import { Top } from "~/components/common";


const TeamInChallenge = observer(({ teamId, challengeId }) => {
  const { rootStore, companiesStore, seasonsStore } = useStores();
  const { currentSeasonId } = seasonsStore;
  const company = companiesStore.getCompany(`${teamId}`);
  const { type } = rootStore;

  if (!company) {
    return (
      <div className={`team page ${type}`}>
        <div className="page-header"><IoIosCloseCircle /> Нет команды</div>
      </div>
    );  
  }

  useEffect(() => {
    company.getAthletesForChallenge(challengeId);
  }, [teamId, challengeId]);

  const { isPendingAthletes, top } = company;

  return (
    <div className={`team page ${type}`}>
      <MultiSwitch
        page="seasons" 
        linkPrefix={"/"}
        id={currentSeasonId}
        companyId={teamId}
      />
      <div className="page-header"><IoIosPeople />{company.name}</div>
      <Top
        ids={top}
        noNumbers={false}
        noPlaces={true}
        showPoints={true}
        starsOnly={false}
        linkPrefix="/"
        isPending={isPendingAthletes} 
        isPagePending={false} 
      />
    </div>
  );
});

export default TeamInChallenge;
