import React from "react";
import { observer } from "mobx-react";
import {
  IoIosFitness,
  IoIosStar,
  IoIosTimer,
  IoIosTrophy,
} from "react-icons/io";
import { GiMountainRoad, GiTwoCoins } from "react-icons/gi";
import { RiRouteLine } from "react-icons/ri";

import useStores from "~/hooks";

import { Preloader } from "~/components/common";

const Statistics = observer(({ athlete, isPending }) => {
  if (!athlete && !isPending) {
    return null;
  }

  const { rootStore } = useStores();
  const { type } = rootStore;
  const { athleteStatistics } = athlete;

  let time = "";
  const seconds = Math.floor(athleteStatistics.movingTime % 60);
  const minutes = Math.floor(athleteStatistics.movingTime / 60) % 60;
  const hours = Math.floor(athleteStatistics.movingTime / 3600);

  if (athleteStatistics.movingTime) {
    time = `${hours}:${minutes < 10 ? `0${minutes}` : minutes}:${
      seconds < 10 ? `0${seconds}` : seconds
    }`;
    if (hours > 48) {
      time = `${~~(hours / 24)} дн.`;
    }
  }

  const distanceKM = athleteStatistics.distance && Number(athleteStatistics.distance / 1000).toFixed(2);
  return (
    <div className={`statistics ${type} color`}>
      {isPending && <Preloader />}
      {!isPending && (
        <div className="athlete-statistics">
          {!!athleteStatistics.stars && (
            <div className="distance">
              <IoIosStar /> {athleteStatistics.stars}
            </div>
          )}
          {!!athleteStatistics.cups && (
            <div className="distance">
              <IoIosTrophy /> {athleteStatistics.cups}
            </div>
          )}
          {!!athleteStatistics.points && (
            <div className="distance">
              <GiTwoCoins /> {athleteStatistics.points}
            </div>
          )}
          {!!athleteStatistics.activitiesCount && (
            <div className="distance">
              <IoIosFitness /> {athleteStatistics.activitiesCount}
            </div>
          )}
          {!!athleteStatistics.movingTime && (
            <div className="distance">
              <IoIosTimer /> {time}
            </div>
          )}
          {!!athleteStatistics.distance && (
            <div className="distance">
              <RiRouteLine /> {distanceKM} км
            </div>
          )}
          {!!athleteStatistics.elevationGain && (
            <div className="distance">
              <GiMountainRoad />
              {athleteStatistics.elevationGain > 5000
                ? `${~~(athleteStatistics.elevationGain / 1000)} км`
                : `${athleteStatistics.elevationGain} м`}
            </div>
          )}
        </div>
      )}
    </div>
  );
});

export default Statistics;
