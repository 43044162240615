import React from "react";
import { observer } from "mobx-react";

const PhotoGallery = observer(() => {
  return (
    <div className="photos">
      <div className="photos-holder">
        <div
          style={{ backgroundImage: "url(/images/athletes/1.webp)" }}
          id="photo-1"
          className="photo-item"
        ></div>
        <div
          style={{ backgroundImage: "url(/images/athletes/3.jpg)" }}
          id="photo-3"
          className="photo-item"
        ></div>
        <div
          style={{ backgroundImage: "url(/images/athletes/5.jpg)" }}
          id="photo-5"
          className="photo-item"
        ></div>
        <div
          style={{ backgroundImage: "url(/images/athletes/6.jpg)" }}
          id="photo-6"
          className="photo-item"
        ></div>
        <div
          style={{ backgroundImage: "url(/images/athletes/4.jpg)" }}
          id="photo-4"
          className="photo-item"
        ></div>
        <div
          style={{ backgroundImage: "url(/images/athletes/9.jpg)" }}
          id="photo-9"
          className="photo-item"
        ></div>
        <div
          style={{ backgroundImage: "url(/images/athletes/10.jpg)" }}
          id="photo-10"
          className="photo-item"
        ></div>
      </div>
    </div>
  );
});

export default PhotoGallery;
