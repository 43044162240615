import React from "react";
import { observer } from "mobx-react";
import handleViewport from "react-in-viewport";

import { IoIosClose } from "react-icons/io";

import { Preloader } from "~/components/common";
import Team from "./Team";


const TeamTop = observer((props) => {
  const { 
    title, 
    ids,
    starsOnly,
    linkPrefix,
    isChampionship,
    getNextPage, 
    isPending, 
    isPagePending, 
    hasNextPage, 
    noNumbers,
  } = props;

  if (!ids) {
    return null;
  }

  if (isPending) {
    return (
      <Preloader />
    );
  }

  const items = [];
  ids.forEach((item, index) => {
    items.push(
      <Team
        linkPrefix={linkPrefix}
        isChampionship={isChampionship}
        starsOnly={starsOnly} 
        key={item.id} 
        noNumbers={noNumbers}
        id={item.id}
        rank={item.rank}
        distanceKM={item.distanceKM}
        elevationM={item.elevationM}
        hours={item.hours}
        minutes={item.minutes}
        seconds={item.seconds}
        points={item.points}
        index={index}
      />
    );
  });

  const AdditionalLoader = handleViewport(Preloader);
  
  if (getNextPage && !isPending && !isPagePending && ids.length && hasNextPage) {
    items.push(
      <AdditionalLoader
        key={"loader"}
        onEnterViewport={() => {
          getNextPage();
        }}
      />
    );
  }

  if (getNextPage && !isPending && isPagePending) {
    items.push(
      <Preloader key="preloader" />
    );
  }

  if (!ids.length) {
    items.push(
      <div key="warning" className="rules-holder">
        <div className="conditions">
          <div className="rule">
            <IoIosClose /> Нет атлетов с подходящими результатами
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="table">
      {title && (<div className="title">{title}</div>)}
      <div className="lines">
        {items}
      </div>
    </div>
  );
});

export default TeamTop;
