import React from "react";
import { observer } from "mobx-react";

import { IoIosClose } from "react-icons/io";

import useStores from "~/hooks";

import MultiSwitch from "~/components/multiSwitch";
import { ChallengesList, Banner } from "~/components/common";
import { NewsWidget } from "~/components/news";
import AssignButton from "../components/common/AssignButton";

const Main = observer(() => {
  const { seasonsStore, accountStore } = useStores();
  const { currentSeason, currentSeasonId } = seasonsStore;
  const { athleteId } = accountStore;

  if (!currentSeason) {
    return (
      <div className="challenges page">
        <div className="page-warning"><IoIosClose /> Нет доступных сезонов</div>
      </div>
    );
  }

  const { challengesStore } = currentSeason;

  return (
    <div className="main page">
      <MultiSwitch page="seasons" id={currentSeasonId}  />
      <AssignButton />
      <Banner />
      <NewsWidget limit={3} />
      <ChallengesList
        athleteId={athleteId}
        store={challengesStore} 
        currentSeasonId={currentSeasonId} 
        isAnnotation={true} 
      />
    </div>
  );
});

export default Main;
