import { observable } from "mobx";

const points = {
  getAthlete: ({ id, type, season, from, to }) => {
    if (!id) {
      return null;
    }
    const urlParams = new URLSearchParams();
    type && type !== "all" && urlParams.set("kind", type);
    season && urlParams.set("season", season);
    from && urlParams.set("from", from);
    to && urlParams.set("to", to);
    return `api/v0/users/${id}?${urlParams}`;
  },
  getChallenges: ({ seasonId, discipline }) => {
    const urlParams = new URLSearchParams();
    discipline && urlParams.set("discipline", discipline);
    return `api/v0/seasons/${seasonId}/challenges?${urlParams}`;
  },
  getTeamChallenges: ({ seasonId, discipline }) => {
    const urlParams = new URLSearchParams();
    discipline && urlParams.set("discipline", discipline);
    return `api/v0/seasons/${seasonId}/team-challenges?${urlParams}`;
  },
  getCompetitions: ({ seasonId, discipline }) => {
    const urlParams = new URLSearchParams();
    discipline && urlParams.set("discipline", discipline);
    return `api/v0/seasons/${seasonId}/competitions?${urlParams}`;
  },
  getMultisportCompetitions: ({ seasonId }) => {
    // const urlParams = new URLSearchParams();
    // discipline && urlParams.set("discipline", discipline);
    return `api/v0/seasons/${seasonId}/multisport-competitions`;
  },
  registerChallenge: ({ challengeId }) => {
    return `api/v0/challenges/${challengeId}/registrations`;
  },
  registerCompetition: ({ id }) => {
    return `api/v0/competitions/${id}/registrations`;
  },
  registerMultisportCompetition: ({ id }) => {
    return `api/v0/multisport-competitions/${id}/registrations`;
  },
  getProfile: () => {
    return "api/v0/users/me";
  },
  getPolarEndpoint: () => {
    return "api/v0/provider-polar/auth-endpoint";
  },
  getFaq: () => {
    return "api/v0/faq";
  },
  getPersonal: () => {
    return "api/v0/personal-data/agreement";
  },
  getSeasons: ({ club }) => {
    const hasClub = club && club !== "null";
    return `api/v0/${hasClub ? `clubs/${club}/` : ""}seasons`;
  },
  getCompanies: () => {
    return "api/v0/companies";
  },
  getSeasonCompanies: ({ id }) => {
    return `api/v0/seasons/${id}/companies`;
  },
  getCompany: ({ id }) => {
    return `api/v0/companies/${id}`;
  },
  getSeason: ({ seasonId }) => {
    return `api/v0/seasons/${seasonId}`;
  },
  sendPhoto: () => {
    return "api/v0/users/me/avatar";
  },
  getStat: ({ type, id, discipline, company, sex, from, to }) => {
    // /api/v0/competitions/<int:competition_id>/stats
    // /api/v0/multisport-competitions/<int:competition_id>/stats
    // /api/v0/challenges/<int:challenge_id>/stats
    // /api/v0/seasons/<int:season_id>/stats

    const urlParams = new URLSearchParams();
    sex && urlParams.set("sex", sex);
    discipline &&
      discipline !== "all" &&
      urlParams.set("discipline", discipline);
    company && urlParams.set("company", company);
    from && urlParams.set("from", from);
    to && urlParams.set("to", to);
    return `api/v0/${type}/${id}/stats?${urlParams}`;
  },
  getClubs: () => {
    return "api/v0/clubs";
  },
  fetchCompanyAthletes: ({ id, sex, season }) => {
    const urlParams = new URLSearchParams();
    sex && urlParams.set("sex", sex);
    season && urlParams.set("season", season);
    return `api/v0/companies/${id}/athletes?${urlParams}`;
  },
  getRatingTops: ({
    id,
    sex,
    page,
    perPage,
    discipline,
    companyId,
    from,
    to,
  }) => {
    const urlParams = new URLSearchParams();
    sex && urlParams.set("sex", sex);
    page && urlParams.set("page", page);
    perPage && urlParams.set("perPage", perPage);
    companyId && urlParams.set("company", companyId);
    from && urlParams.set("from", from);
    to && urlParams.set("to", to);
    discipline &&
      discipline !== "all" &&
      urlParams.set("discipline", discipline);
    return `api/v0/ratings/${id}/top?${urlParams}`;
  },
  getTeamRatingTops: ({
    id,
    sex,
    page,
    perPage,
    discipline,
    companyId,
    from,
    to,
  }) => {
    const urlParams = new URLSearchParams();
    sex && urlParams.set("sex", sex);
    page && urlParams.set("page", page);
    perPage && urlParams.set("perPage", perPage);
    companyId && urlParams.set("company", companyId);
    from && urlParams.set("from", from);
    to && urlParams.set("to", to);
    discipline &&
      discipline !== "all" &&
      urlParams.set("discipline", discipline);
    return `api/v0/team-ratings/${id}/top?${urlParams}`;
  },
  fetchCompanyAthletesForChallenge: ({ id, challengeId }) => {
    return `api/v0/team-challenges/${challengeId}/companies/${id}/athletes`;
  },
  getTops: ({
    seasonId,
    type,
    sex,
    limit,
    company,
    page,
    perPage,
    from,
    to,
  }) => {
    const urlParams = new URLSearchParams();
    sex && urlParams.set("sex", sex);
    page && urlParams.set("page", page);
    perPage && urlParams.set("perPage", perPage);
    company && urlParams.set("company", company);
    limit && urlParams.set("limit", limit);
    from && urlParams.set("from", from);
    to && urlParams.set("to", to);
    return `api/v0/seasons/${seasonId}/top/${type}?${urlParams}`;
  },
  // getRatingTops: ({
  //   sex,
  //   discipline,
  //   clubId,
  //   seasonId,
  //   companyId,
  //   from,
  //   to,
  //   isTeamRating,
  //   page,
  //   perPage,
  //   limit,
  // }) => {
  //   const urlParams = new URLSearchParams();
  //   sex && urlParams.set("sex", sex);
  //   discipline && discipline !== "all" && urlParams.set("discipline", discipline);
  //   clubId && urlParams.set("club", clubId);
  //   seasonId && seasonId !== "all"  && urlParams.set("season", seasonId);
  //   companyId && urlParams.set("company", companyId);
  //   from && urlParams.set("from", from);
  //   to && urlParams.set("to", to);
  //   isTeamRating && urlParams.set("isTeamRating", isTeamRating);
  //   page && urlParams.set("page", page);
  //   perPage && urlParams.set("perPage", perPage);
  //   limit && urlParams.set("limit", limit);
  //   return `api/v0/ratings/personal?${urlParams}`;
  // },
  getChallengeTops: ({
    challengeId,
    sex,
    company,
    page,
    perPage,
    limit,
    athlete,
  }) => {
    const urlParams = new URLSearchParams();
    sex && urlParams.set("sex", sex);
    page && urlParams.set("page", page);
    perPage && urlParams.set("perPage", perPage);
    company && urlParams.set("company", company);
    limit && urlParams.set("limit", limit);
    athlete && urlParams.set("athlete", athlete);
    return `api/v0/challenges/${challengeId}/top?${urlParams}`;
  },
  getCompetitionTops: ({
    challengeId,
    sex,
    company,
    page,
    perPage,
    limit,
    athlete,
  }) => {
    const urlParams = new URLSearchParams();
    sex && urlParams.set("sex", sex);
    page && urlParams.set("page", page);
    perPage && urlParams.set("perPage", perPage);
    company && urlParams.set("company", company);
    limit && urlParams.set("limit", limit);
    athlete && urlParams.set("athlete", athlete);
    return `api/v0/competitions/${challengeId}/top?${urlParams}`;
  },
  getMultisportCompetitionTops: ({
    challengeId,
    company,
    page,
    perPage,
    limit,
    athlete,
  }) => {
    const urlParams = new URLSearchParams();
    page && urlParams.set("page", page);
    perPage && urlParams.set("perPage", perPage);
    company && urlParams.set("company", company);
    limit && urlParams.set("limit", limit);
    athlete && urlParams.set("athlete", athlete);
    return `api/v0/multisport-competitions/${challengeId}/top?${urlParams}`;
  },
  getTeamChallengeTops: ({
    challengeId,
    sex,
    company,
    page,
    perPage,
    limit,
  }) => {
    const urlParams = new URLSearchParams();
    sex && urlParams.set("sex", sex);
    page && urlParams.set("page", page);
    perPage && urlParams.set("perPage", perPage);
    company && urlParams.set("company", company);
    limit && urlParams.set("limit", limit);
    return `api/v0/team-challenges/${challengeId}/top?${urlParams}`;
  },
  getAthletesBySearch: ({ query }) => {
    const urlParams = new URLSearchParams();
    query && urlParams.set("query", query);
    return `api/v0/users/athletes?${urlParams}`;
  },
  sendActivity: ({ id }) => {
    return `api/v0/users/${id}/activities`;
  },
  sendGPX: () => {
    return "api/v0/gpx-files";
  },
  sendActivityPolar: ({ id }) => {
    return `api/v0/activities/${id}/elevation`;
  },
  connectProfile: () => {
    return "api/v0/users/athletes";
  },
  getProgress: ({ challengeId, athlete }) => {
    const urlParams = new URLSearchParams();
    athlete && urlParams.set("athlete", athlete);
    return `api/v0/challenges/${challengeId}/progress?${urlParams}`;
  },
  getActivities: ({ challengeId, athlete }) => {
    return `api/v0/challenges/${challengeId}/athletes/${athlete}/activities`;
  },
  getCompetitionActivities: ({ challengeId, athlete }) => {
    return `api/v0/competitions/${challengeId}/athletes/${athlete}/activities`;
  },
  getAthleteActivities: ({ athleteId, discipline, from, to }) => {
    const urlParams = new URLSearchParams();
    discipline && urlParams.set("discipline", discipline);
    from && urlParams.set("from", from);
    to && urlParams.set("to", to);
    return `api/v0/users/${athleteId}/activities?${urlParams}`;
  },
  fetchPromocodes: ({ discipline, season, from, to }) => {
    const urlParams = new URLSearchParams();
    discipline && urlParams.set("discipline", discipline);
    season && urlParams.set("season", season);
    from && urlParams.set("from", from);
    to && urlParams.set("to", to);
    return `api/v0/athletes/me/promocodes?${urlParams}`;
  },
  fetchAthleteStats: ({ athleteId, discipline, season, from, to }) => {
    const urlParams = new URLSearchParams();
    discipline && urlParams.set("discipline", discipline);
    athleteId && urlParams.set("athlete", athleteId);
    from && urlParams.set("from", from);
    to && urlParams.set("to", to);
    return `api/v0/seasons/${season}/stats?${urlParams}`;
  },
  getAthleteDisciplineStats: ({ athleteId, discipline, season, from, to }) => {
    const urlParams = new URLSearchParams();
    discipline && urlParams.set("discipline", discipline);
    season && urlParams.set("season", season);
    from && urlParams.set("from", from);
    to && urlParams.set("to", to);
    return `api/v0/users/${athleteId}/discipline-stats?${urlParams}`;
  },
  getRatings({ seasonId }) {
    return `api/v0/seasons/${seasonId}/ratings`;
  },
  getTeamRatings({ seasonId }) {
    return `api/v0/seasons/${seasonId}/team-ratings`;
  },
  getPrintables({ id, prefix, from, to, kind, currentClubId }) {
    const urlParams = new URLSearchParams();
    kind && urlParams.set("discipline", kind);
    from && urlParams.set("from", from);
    to && urlParams.set("to", to);
    currentClubId && urlParams.set("club", currentClubId);
    return `api/v0/${prefix}/${id}/publishing/available-materials?${urlParams}`;
  },
  getArchievements({ athlete, discipline, season, from, to }) {
    const urlParams = new URLSearchParams();
    discipline && urlParams.set("discipline", discipline);
    season && urlParams.set("season", season);
    from && urlParams.set("from", from);
    to && urlParams.set("to", to);
    return `api/v0/athletes/${athlete}/achievements?${urlParams}`;
  },
  getAthletes: ({ challengeId, sex, company, page, perPage }) => {
    const urlParams = new URLSearchParams();
    sex && urlParams.set("sex", sex);
    page && urlParams.set("page", page);
    perPage && urlParams.set("perPage", perPage);
    company && urlParams.set("company", company);
    return `api/v0/challenges/${challengeId}/athletes?${urlParams}`;
  },
  login: () => {
    return "accounts/login/";
  },
  accounts: () => {
    return "accounts/login/";
  },
  loginAdmin: () => {
    return "admin/login/";
  },
  logout: () => {
    return "accounts/logout/";
  },
  admin: () => {
    return "admin/";
  },
  getNews: ({ page, perPage, limit, season }) => {
    const urlParams = new URLSearchParams();
    limit && urlParams.set("limit", limit);
    season && urlParams.set("season", season);
    !limit && page && urlParams.set("page", page);
    !limit && perPage && urlParams.set("perPage", perPage);
    return `api/v0/news/stories?${urlParams}`;
  },
  getNewsStory: ({ id }) => {
    return `api/v0/news/stories/${id}`;
  },
  assgnTo({ seasonId }) {
    return `api/v0/seasons/${seasonId}/registrations`;
  },
};

export default class Api {
  @observable rootStore = null;

  constructor(store) {
    this.rootStore = store;
    this.domain = "/";
  }

  getMiddlewareToken(html) {
    const regex = /"csrfmiddlewaretoken".+value="(.+)"/i;
    const match = html.match(regex);
    const token = match && match[1];
    return token;
  }

  async processResponse(response, type, redirectError, refreshOnFail = false) {
    if (response.redirected && redirectError) {
      location.reload(true);
    }
    if (response.status === 401 || response.status === 403 || response.status === 302) {
      this.logout();
      throw "Token expired";
    } else if (response.status === 500) {
      throw "Internal server error 500";
    }
    if (type === "raw") {
      return response;
    }
    if (type === "text") {
      return await response.text();
    }
    try {
      const json = await response.json();
      return json;
    } catch (error) {
      console.warn(error);
      if (refreshOnFail) {
        location.reload(true);
      }
      throw error;
    }
  }

  async doFetch(
    url,
    method,
    fetchData,
    requestType = "json",
    redirectError = false
  ) {
    let contentType;
    let data = fetchData;
    if (requestType === "json") {
      contentType = "application/json";
      data = JSON.stringify(fetchData);
    }
    if (requestType === "form") {
      contentType = "application/x-www-form-urlencoded";
      data = new URLSearchParams();
      for (const name in fetchData) {
        data.append(name, fetchData[name]);
      }
    }
    if (requestType === "formData") {
      data = new FormData();
      for (const name in fetchData) {
        if ((name === "photo" || name === "gpx") && fetchData[name].length) {
          for (let i = 0; i < fetchData[name].length; i += 1) {
            data.append(`${name}${i}`, fetchData[name][i]);
          }
        } else {
          data.append(name, fetchData[name]);
        }
      }
    }
    try {
      const formData = {
        credentials: "include",
        method,
        redirect:    redirectError ? "error" : "follow",
        body:        data,
      };
      if (contentType) {
        formData.headers = {
          "Content-Type": contentType,
        };
      }
      const token = localStorage.getItem("token");

      if (token) {
        formData.headers = {
          ...(formData.headers || {}),
          Authorization: `Bearer ${token}`,
        };
      }
      const fetchedData = await fetch(url, formData);
      return fetchedData;
    } catch (error) {
      return error;
    }
  }

  async request({
    method,
    point,
    urlParams,
    data,
    requestType,
    type,
    redirectError,
    refreshOnFail,
  }) {
    const pointUrl = points[point];
    const url = `${this.domain}${pointUrl(urlParams)}`;
    try {
      const response = await this.doFetch(
        url,
        method,
        data,
        requestType,
        redirectError
      );
      return this.processResponse(response, type, redirectError, refreshOnFail);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getPolarEndpoint() {
    return this.request({
      method: "GET",
      point:  "getPolarEndpoint",
      type:   "json",
    });
  }

  async assignTo(seasonId, discipline, from, to) {
    return this.request({
      method:    "POST",
      point:     "assgnTo",
      type:      "json",
      urlParams: {
        seasonId,
      },
      data: {
        discipline,
        from,
        to,
      },
    });
  }

  async fetchPromocodes(discipline, season, from, to) {
    return this.request({
      method:    "GET",
      point:     "fetchPromocodes",
      urlParams: {
        discipline: discipline === "all" ? null : discipline,
        season,
        from,
        to,
      },
      type: "json",
    });
  }

  async fetchAthleteStats(athleteId, discipline, season, from, to) {
    return this.request({
      method:    "GET",
      point:     "fetchAthleteStats",
      urlParams: {
        athleteId,
        discipline: discipline === "all" ? null : discipline,
        season,
        from,
        to,
      },
      type: "json",
    });
  }

  async fetchAthleteActivities(athleteId, discipline, from, to) {
    return this.request({
      method:    "GET",
      point:     "getAthleteActivities",
      urlParams: {
        athleteId,
        discipline: discipline === "all" ? null : discipline,
        from,
        to,
      },
      type: "json",
    });
  }

  async getAthletesBySearch(query) {
    return this.request({
      method:    "GET",
      point:     "getAthletesBySearch",
      urlParams: {
        query,
      },
      type: "json",
    });
  }

  async fetchAthleteDisciplineStats(athleteId, discipline, season, from, to) {
    return this.request({
      method:    "GET",
      point:     "getAthleteDisciplineStats",
      urlParams: {
        athleteId,
        discipline,
        season,
        from,
        to,
      },
      type: "json",
    });
  }
  async getAthlete(id, type, season, from, to) {
    return this.request({
      method:    "GET",
      point:     "getAthlete",
      urlParams: {
        id,
        type:   type && type !== "null" && type,
        season: season && season !== "null" && season,
        from,
        to,
      },
      type: "json",
    });
  }

  async fetchCompanyAthletesForChallenge(id, challengeId) {
    return this.request({
      method:    "GET",
      point:     "fetchCompanyAthletesForChallenge",
      urlParams: {
        id,
        challengeId,
      },
      type: "json",
    });
  }

  async fetchCompanyAthletes(id, season, sex) {
    return this.request({
      method:    "GET",
      point:     "fetchCompanyAthletes",
      urlParams: {
        id,
        season,
        sex,
      },
      type: "json",
    });
  }

  async getProfile() {
    return this.request({
      method: "GET",
      point:  "getProfile",
      type:   "json",
    });
  }

  async getRatings({ seasonId }) {
    if (seasonId === "all") {
      return [];
    }
    return this.request({
      method:    "GET",
      point:     "getRatings",
      urlParams: {
        seasonId,
      },
      type: "json",
    });
  }

  async getTeamRatings({ seasonId }) {
    if (seasonId === "all") {
      return [];
    }
    return this.request({
      method:    "GET",
      point:     "getTeamRatings",
      urlParams: {
        seasonId,
      },
      type: "json",
    });
  }

  async getRatingTops(
    id,
    sex,
    page,
    perPage,
    discipline,
    companyId,
    from,
    to,
    isTeamRating
  ) {
    return this.request({
      method:    "GET",
      point:     isTeamRating ? "getTeamRatingTops" : "getRatingTops",
      urlParams: {
        id,
        sex,
        page,
        perPage,
        discipline,
        companyId,
        from,
        to,
      },
      type: "json",
    });
  }

  async getRatingAnnotation(id, sex, perPage, isTeam) {
    return this.request({
      method:    "GET",
      point:     isTeam ? "getTeamRatingTops" : "getRatingTops",
      urlParams: {
        id,
        sex,
        page: 1,
        perPage,
      },
      type: "json",
    });
  }

  async getFaq() {
    return this.request({
      method: "GET",
      point:  "getFaq",
      type:   "json",
    });
  }

  async getPersonal() {
    return this.request({
      method: "GET",
      point:  "getPersonal",
      type:   "json",
    });
  }

  async getPrintables(
    id,
    prefix = "competitions",
    from,
    to,
    kind,
    currentClubId
  ) {
    return this.request({
      method:    "GET",
      point:     "getPrintables",
      type:      "json",
      urlParams: {
        id,
        prefix,
        from,
        to,
        kind,
        currentClubId,
      },
    });
  }

  async getNews(urlParams) {
    return this.request({
      method: "GET",
      point:  "getNews",
      type:   "json",
      urlParams,
    });
  }

  async getNewsStory(id) {
    return this.request({
      method:    "GET",
      point:     "getNewsStory",
      type:      "json",
      urlParams: {
        id,
      },
    });
  }

  async getPageInfo(type, id, discipline, sex, from, to, company) {
    return this.request({
      method:    "GET",
      point:     "getStat",
      type:      "json",
      urlParams: {
        type,
        id,
        discipline,
        sex,
        from,
        to,
        company,
      },
    });
  }

  async getArchievements(athlete, discipline, season, from, to) {
    return this.request({
      method:    "GET",
      point:     "getArchievements",
      type:      "json",
      urlParams: {
        athlete,
        discipline: discipline === "all" ? null : discipline,
        season,
        from,
        to,
      },
    });
  }

  async sendInfo(
    email,
    birthday,
    firstname,
    lastname,
    sex,
    personalDataAgreementDate
  ) {
    return this.request({
      method: "PUT",
      point:  "getProfile",
      type:   "json",
      data:   {
        email,
        birthday,
        firstname,
        lastname,
        sex,
        personalDataAgreementDate,
      },
    });
  }

  async registerChallenge(challengeId) {
    return this.request({
      method:    "POST",
      point:     "registerChallenge",
      type:      "json",
      urlParams: { challengeId },
    });
  }

  async connectProfile(athleteId) {
    return this.request({
      method: "POST",
      point:  "connectProfile",
      type:   "json",
      data:   { athleteId },
    });
  }

  async sendActivity(id, data) {
    return this.request({
      method:      "POST",
      point:       "sendActivity",
      requestType: "formData",
      type:        "multipart/form-data",
      urlParams:   { id },
      data,
    });
  }

  async sendGPX(data) {
    return this.request({
      method:      "POST",
      point:       "sendGPX",
      requestType: "formData",
      type:        "multipart/form-data",
      data,
    });
  }

  async sendPhoto(data) {
    return this.request({
      method:      "POST",
      point:       "sendPhoto",
      requestType: "formData",
      type:        "multipart/form-data",
      data,
    });
  }

  async sendActivityPolar(id, data) {
    return this.request({
      method:      "POST",
      point:       "sendActivityPolar",
      requestType: "formData",
      type:        "multipart/form-data",
      urlParams:   { id },
      data,
    });
  }

  async registerCompetition(id) {
    return this.request({
      method:    "POST",
      point:     "registerCompetition",
      type:      "json",
      urlParams: { id },
    });
  }

  async registerMultisportCompetition(id) {
    return this.request({
      method:    "POST",
      point:     "registerMultisportCompetition",
      type:      "json",
      urlParams: { id },
    });
  }

  async getSeasons({ club }) {
    return this.request({
      method:    "GET",
      point:     "getSeasons",
      type:      "json",
      urlParams: { club },
    });
  }

  async getClubs() {
    return this.request({
      method:        "GET",
      point:         "getClubs",
      type:          "json",
      redirectError: true,
      refreshOnFail: true,
    });
  }

  async getCompanies() {
    return this.request({
      method:        "GET",
      point:         "getCompanies",
      type:          "json",
      redirectError: true,
      refreshOnFail: false,
    });
  }

  async getSeasonCompanies(id) {
    if (id === "all") {
      return [];
    }
    return this.request({
      method:    "GET",
      point:     "getSeasonCompanies",
      type:      "json",
      urlParams: { id },
    });
  }

  async getCompany(id) {
    return this.request({
      method:    "GET",
      point:     "getCompany",
      urlParams: { id },
      type:      "json",
    });
  }

  async getSeason(seasonId) {
    if (!seasonId) {
      return null;
    }
    return this.request({
      method:    "GET",
      point:     "getSeason",
      type:      "json",
      urlParams: { seasonId },
    });
  }

  async getTops(seasonId, type, sex, limit, company, page, perPage, from, to) {
    if (!type || !seasonId) {
      return null;
    }
    return this.request({
      method:    "GET",
      point:     "getTops",
      type:      "json",
      urlParams: {
        seasonId,
        type,
        sex,
        limit,
        company,
        page,
        perPage,
        from,
        to,
      },
    });
  }

  async getProgress(challengeId, athlete) {
    if (!challengeId || !athlete) {
      return null;
    }
    return this.request({
      method:    "GET",
      point:     "getProgress",
      type:      "json",
      urlParams: { challengeId, athlete },
    });
  }

  async getActivities(challengeId, athlete) {
    if (!challengeId || !athlete) {
      return null;
    }
    return this.request({
      method:    "GET",
      point:     "getActivities",
      type:      "json",
      urlParams: { challengeId, athlete },
    });
  }

  async getCompetitionActivities(challengeId, athlete) {
    if (!challengeId || !athlete) {
      return null;
    }
    return this.request({
      method:    "GET",
      point:     "getCompetitionActivities",
      type:      "json",
      urlParams: { challengeId, athlete },
    });
  }

  async getAthletes(challengeId) {
    if (!challengeId) {
      return null;
    }
    return this.request({
      method:    "GET",
      point:     "getAthletes",
      type:      "json",
      urlParams: { challengeId },
    });
  }

  async getChallengeTops(
    challengeId,
    sex,
    company,
    page,
    perPage,
    isCompetition
  ) {
    if (!challengeId) {
      return null;
    }
    return this.request({
      method:    "GET",
      point:     isCompetition ? "getChallengeTops" : "getAthletes",
      type:      "json",
      urlParams: { challengeId, sex, company, page, perPage },
    });
  }

  async getCompetitionTops(challengeId, sex, company, page, perPage) {
    if (!challengeId) {
      return null;
    }
    return this.request({
      method:    "GET",
      point:     "getCompetitionTops",
      type:      "json",
      urlParams: { challengeId, sex, company, page, perPage },
    });
  }

  async getMultisportCompetitionTops(
    challengeId,
    company,
    page,
    perPage,
    athlete
  ) {
    if (!challengeId) {
      return null;
    }
    return this.request({
      method:    "GET",
      point:     "getMultisportCompetitionTops",
      type:      "json",
      urlParams: { challengeId, company, page, perPage, athlete },
    });
  }

  async getTeamChallengeTops(challengeId, sex, page, perPage) {
    if (!challengeId) {
      return null;
    }
    return this.request({
      method:    "GET",
      point:     "getTeamChallengeTops",
      type:      "json",
      urlParams: { challengeId, sex, page, perPage },
    });
  }

  async getChallengeAnnotation(challengeId, sex, company, athlete, limit) {
    if (!challengeId || !limit) {
      return null;
    }
    return this.request({
      method:    "GET",
      point:     "getChallengeTops",
      type:      "json",
      urlParams: { challengeId, sex, company, athlete, limit },
    });
  }

  async getCompetitionAnnotation(challengeId, sex, company, athlete, limit) {
    if (!challengeId || !limit) {
      return null;
    }
    return this.request({
      method:    "GET",
      point:     "getCompetitionTops",
      type:      "json",
      urlParams: { challengeId, sex, company, athlete, limit },
    });
  }

  async getMultisportCompetitionAnnotation(
    challengeId,
    company,
    athlete,
    limit
  ) {
    if (!challengeId || !limit) {
      return null;
    }
    return this.request({
      method:    "GET",
      point:     "getMultisportCompetitionTops",
      type:      "json",
      urlParams: { challengeId, company, athlete, limit },
    });
  }

  async getTeamChallengeAnnotation(challengeId, sex, company, limit) {
    if (!challengeId || !limit) {
      return null;
    }
    return this.request({
      method:    "GET",
      point:     "getTeamChallengeTops",
      type:      "json",
      urlParams: { challengeId, sex, company, limit },
    });
  }

  async getChallenges(seasonId, discipline) {
    if (!discipline || !seasonId) {
      return null;
    }
    return this.request({
      method:    "GET",
      point:     "getChallenges",
      type:      "json",
      urlParams: { seasonId, discipline },
    });
  }

  async getCompetitions(seasonId, discipline) {
    if (!discipline || !seasonId) {
      return null;
    }
    return this.request({
      method:    "GET",
      point:     "getCompetitions",
      type:      "json",
      urlParams: { seasonId, discipline },
    });
  }

  async getMultisportCompetitions(seasonId) {
    if (!seasonId) {
      return null;
    }
    return this.request({
      method:    "GET",
      point:     "getMultisportCompetitions",
      type:      "json",
      urlParams: { seasonId },
    });
  }

  async getTeamChallenges(seasonId, discipline) {
    if (!discipline || !seasonId) {
      return null;
    }
    return this.request({
      method:    "GET",
      point:     "getTeamChallenges",
      type:      "json",
      urlParams: { seasonId, discipline },
    });
  }

  async login(login, password) {
    const html = await this.request({
      method: "GET",
      point:  "accounts",
      type:   "text",
    });

    const csrfmiddlewaretoken = this.getMiddlewareToken(html);
    const data = { login, password, csrfmiddlewaretoken };
    return this.request({
      method:      "POST",
      point:       "login",
      data,
      requestType: "form",
      type:        "text",
    });
  }

  async logout() {
    localStorage.removeItem("token");
    await this.request({
      method:      "POST",
      point:       "logout",
      requestType: "form",
      type:        "json",
    });
    location.reload();
  }

  async loginAdmin(username = "admin", password = "admin") {
    const html = await this.request({
      method: "GET",
      point:  "admin",
      type:   "text",
    });
    const csrfmiddlewaretoken = this.getMiddlewareToken(html);
    const data = { username, password, csrfmiddlewaretoken, next: "/" };
    return this.request({
      method:      "POST",
      point:       "loginAdmin",
      data,
      requestType: "form",
      type:        "text",
    });
  }

  async sleep(m) {
    return new Promise((r) => {
      return setTimeout(r, m);
    });
  }
}
