import { observable, action, computed } from "mobx";
import TopItem from "./TopItem";

export default class Company {
  @observable
  store = null;
  @observable
  rootStore = null;
  @observable
  id = null;
  @observable
  pending = null;
  @observable
  pendingAthletes = null;
  @observable
  isPrivate = null;
  @observable
  athletes = new Map();
  @observable
  name = null; // название.
  @observable
  top = [];

  @observable
  distance = null;
  @observable
  movingTime = null;
  
  constructor(data, store) {
    this.id = data.id;
    this.setInfo(data);
    this.distance = data.distance || null;
    this.movingTime = data.movingTime || null;

    this.store = store;
    this.rootStore = this.store.rootStore;
    this.athletesStore = this.rootStore.athletesStore;
  }

  @action
  async getInfo() {
    if (!this.pending && !this.full) {
      this.pending = true;
      const info = await this.store.fetchCompany(this.id);
      this.setInfo(info);
    }
  }

  @action
  setInfo(data) {
    this.distance = data.distance || null;
    this.movingTime = data.movingTime || null;
    this.name = data.name || null;
    this.isPrivate = data.isPrivate || null;
    this.pending = false;
  }

  @action
  async getAthletes(currentSeasonId, sex) {
    if (!this.pendingAthletes) {
      this.pendingAthletes = true;
      const athletes = await this.store.fetchCompanyAthletes(this.id, currentSeasonId, sex);
      this.setAthletes(athletes);
      this.pendingAthletes = false;
    }
  }

  @action
  async getAthletesForChallenge(challengeId) {
    if (!this.pendingAthletes) {
      this.pendingAthletes = true;
      const athletes = await this.store.fetchCompanyAthletesForChallenge(this.id, challengeId);
      this.setAthletesTop(athletes);
      this.pendingAthletes = false;
    }
  }

  @action
  setAthletes(data) {
    data.forEach((athleteData) => {
      const athlete = this.athletesStore.createAthlete(athleteData);
      this.athletes.set(athlete.id, athlete);
    });
  }

  @action
  setAthletesTop(data) {
    this.top.clear();
    data.forEach((data) => {
      const athlete = this.athletesStore.createAthlete(data);
      const topItem = new TopItem({ 
        id: athlete.id, 
        ...data,
      });
      this.top.push(topItem);
    });
  }

  @computed
  get label() {
    return this.name;
  }

  @computed
  get isPublic() {
    return !this.isPrivate;
  }

  @computed
  get isPendingAthletes() {
    return this.pendingAthletes;
  }

  @computed
  get athleteIds() {
    return Array.from(this.athletes.values());
  }

  @computed
  get distanceKM() {
    return Number(this.distance / 1000).toFixed(2);
  }  
  
  @computed
  get hours() {
    return Math.floor(this.movingTime / 3600);
  }

  @computed
  get minutes() {
    return Math.floor(this.movingTime / 60) % 60;
  }  

  @computed
  get seconds() {
    return this.movingTime % 60;
  }  
  
  @computed
  get value() {
    return this.id;
  }
}
