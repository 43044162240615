import React, { useCallback } from "react";
import { observer } from "mobx-react";

import useStores from "~/hooks";
import { Preloader } from "~/components/common";

import KindCard from "../multiSwitch/KindCard";

const AssignButton = observer(() => {
  const { rootStore, seasonsStore } = useStores();
  const { currentSeason } = seasonsStore;
  const { currentMonth, canSign } = currentSeason;
  const { name, canAssignTo } = currentMonth;
  const { type, kind } = rootStore;

  const onPress = useCallback(() => {
    if (!currentSeason.isPending && canAssignTo) {
      currentSeason.assignTo(kind, currentMonth.from, currentMonth.to);
    }
  }, [currentSeason, currentMonth, canAssignTo]);

  if (canSign) {
    return null;
  }

  return (
    <div className={"button-assign-holder"} onClick={onPress}>
      <div
        className={`button-assign ${canAssignTo ? kind : ""} gradient ${canAssignTo ? type : ""}`}
        onClick={onPress}
      >
        {!currentSeason.isPending && (
          <KindCard iconOnly={true} active={true} kind={kind} />
        )}
        {currentSeason.isPending && (
          <Preloader />
        )}
        {" "}Записаться на{" "}
        {name}
      </div>
    </div>
  );
});
export default AssignButton;
