import { observable, action, computed } from "mobx";
import Athlete from "../models/Athlete";

export default class AthletesStore {
  @observable
  athletes = new Map();

  constructor(store) {
    this.rootStore = store;
    this.api = this.rootStore.api;
  }

  @action
  getAthlete(id, full, type, season, from, to) {
    let athlete = this.athletes.get(id);
    if (!athlete) {
      athlete = this.createAthlete({ id });
      athlete.getFullInfo(type, season, from, to);
    }
    if (full) {
      athlete.getFullInfo(type, season, from, to);
    }
    return athlete;
  }

  @action
  async connectProfile(id) {
    await this.api.connectProfile(id);
  }

  @action
  async getFilteredAthletes(inputValue) {
    const data = await this.api.getAthletesBySearch(inputValue);
    const outputArray = [];
    data.forEach((item) => {
      outputArray.push({
        label: `${item.firstname} ${item.lastname}`,
        value: item.id,
        ...item,
      });
    });
    return outputArray;
  }

  @action
  async fetchAthlete(id, type, season, from, to) {
    const result = await this.api.getAthlete(id, type, season, from, to);
    return result;
  }

  @action
  async fetchAthleteActivities(id, type, from, to) {
    const result = await this.api.fetchAthleteActivities(id, type, from, to);
    return result;
  }

  @action
  async fetchAthleteDisciplineStats(id) {
    const result = await this.api.fetchAthleteDisciplineStats(id);
    return result;
  }

  @action
  createAthlete(athleteData) {
    const athlete = new Athlete(athleteData, this);
    this.athletes.set(athlete.id, athlete);
    return this.athletes.get(athlete.id);
  }

  @computed
  get men() {
    const data = [];
    this.athletes.forEach((athlete) => {
      if (athlete.male) {
        data.push(athlete);
      }
    });
    return data;
  }

  @computed
  get women() {
    const data = [];
    this.athletes.forEach((athlete) => {
      if (!athlete.male) {
        data.push(athlete);
      }
    });
    return data;
  }
}
