import React, { useEffect } from "react";
import { observer } from "mobx-react";

import { IoIosStar, IoIosClose } from "react-icons/io";

import useStores from "~/hooks";

import { Preloader } from "~/components/common";
import TeamTop from "./TeamTop";
import TeamChallengeCard from "./TeamChallengeCard";

const TeamChallengeInfo = observer(({ challengeId,  companyId }) => {
  const { rootStore, seasonsStore } = useStores();
  const { kind, sex } = rootStore;
  const { currentSeason, currentSeasonId } = seasonsStore;
  const isMale = sex !== "females";

  if (!currentSeason) {
    return (
      <div className="challenges page">
        <div className="page-header"><IoIosStar /> Вызов</div>
        <div className="page-warning"><IoIosClose /> Не выбран сезон</div>
      </div>
    );
  }

  const { challengesStore } = currentSeason;
  const { isPending } = challengesStore;

  useEffect(() => {
    seasonsStore.initChallengesStore();
  }, [kind, currentSeasonId]);

  const challenge = challengesStore.teamChallenges.get(`${challengeId}`);

  useEffect(() => {
    challenge && challenge.getPageInit(sex);
  }, [kind, currentSeasonId, challengeId, challenge, isMale]);

  if (isPending) {
    return <Preloader />;
  }

  if (!challenge) {
    return <Preloader />;
  }

  const { isPagePending, hasNextPage, isCompetition, isChampionship, id } = challenge;

  const nextPage = () => {
    challenge.getNextPage(sex);
  };

  const ids = challenge.fullTop;
  
  return (
    <div className="challenges info page">
      <TeamChallengeCard
        showDownloadButtons={true}
        challenge={challenge} 
        companyId={companyId}
      />
      <TeamTop
        ids={ids}
        noNumbers={!isCompetition}
        isChampionship={isChampionship}
        linkPrefix={!isChampionship ? "/" : `/team-challenges/${id}/`}
        isPending={isPending} 
        isPagePending={isPagePending} 
        getNextPage={nextPage}
        hasNextPage={hasNextPage}
      />
    </div>
  );
});

export default TeamChallengeInfo;
