import React, { useState } from "react";
import { observer } from "mobx-react";
import ReactModal from "react-modal";

import { IoIosArrowBack, IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { AiOutlineCalendar } from "react-icons/ai";

import useStores from "~/hooks";

import Season from "./Season";
import SeasonsList from "./SeasonsList";

ReactModal.setAppElement("#root");

const SeasonsSwitch = observer(({ showTimeLast, withEmptySeason }) => {
  const { seasonsStore, rootStore } = useStores();
  const { type } = rootStore;
  const { currentSeason, canSwitch } = seasonsStore;

  const [isModalVisible, setModalVisible] = useState(false);

  if (!currentSeason) {
    return null;
  }

  const { start, finish } = currentSeason;

  const onNext = () => {
    seasonsStore.onSwitch(1);
  };

  const onSelectSeason = (season) => {
    if (season && season.id) {
      seasonsStore.setSeasonId(season.id);
    }
  };

  const onShowModal = () => {
    setModalVisible(true);
    if (window.navigator.vibrate) {
      window.navigator.vibrate([30]);
    }
  };

  const onCloseModal = () => {
    setModalVisible(false);
  };

  const onPrev = () => {
    seasonsStore.onSwitch(-1);
  };

  return (
    <div className="season-holder">
      <div className={`seasons ${type}`} onClick={onShowModal}>
        {showTimeLast && canSwitch && <IoIosArrowBack onClick={onPrev} />}
        <Season noSelection={true} season={currentSeason} />
        {!showTimeLast && canSwitch && <IoIosArrowDown />}
        {showTimeLast && canSwitch && <IoIosArrowForward onClick={onNext} />}
      </div>
      {showTimeLast && currentSeason && (
        <div className="season-time-last">
          <AiOutlineCalendar /> {start.format("D MMMM YYYY")} &mdash; {finish.format("D MMMM YYYY")}
        </div>
      )}
      <ReactModal 
        isOpen={isModalVisible}
        shouldCloseOnOverlayClick={true}
        onRequestClose={onCloseModal}
        contentLabel="Minimal Modal Example"
      >
        <SeasonsList
          withEmptySeason={withEmptySeason}
          onSelect={onSelectSeason} 
          closeModal={onCloseModal}
          title={"Выберите сезон"}
        />
      </ReactModal>
    </div>
  );
});
export default SeasonsSwitch;
