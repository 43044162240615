import React from "react";
import { observer } from "mobx-react";

import { IoIosClose, IoIosPodium } from "react-icons/io";

import useStores from "~/hooks";

import { ChallengesList } from "~/components/common";
import MultiSwitch from "~/components/multiSwitch";


const Challenges = observer(() => {
  const { seasonsStore } = useStores();

  const { currentSeason, currentSeasonId } = seasonsStore;

  if (!currentSeason) {
    return (
      <div className="challenges page">
        <MultiSwitch
          page="seasons" 
          id={currentSeasonId}
        />
        <div className="page-header"><IoIosPodium /> Вызовы</div>
        <div className="page-warning"><IoIosClose /> Не выбран сезон</div>
      </div>
    );
  }
  const { challengesStore } = currentSeason;

  return (
    <div className="challenges page">
      <MultiSwitch
        page="seasons" 
        id={currentSeasonId}
      />
      <ChallengesList store={challengesStore} currentSeasonId={currentSeasonId} />
    </div>
  );
});

export default Challenges;
