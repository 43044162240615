import React from "react";
import { observer } from "mobx-react";

import useStores from "~/hooks";

import Club from "./Club";

const ClubsList = observer(({ onSelect, closeModal, isVisible }) => {
  const { accountStore, rootStore } = useStores();
  const { type } = rootStore;
  const { canSwitch, clubList } = accountStore;
  const clubs = [];

  const onSelectClub = (club) => {
    if (canSwitch) {
      onSelect(club);
      closeModal();
    }
  };

  clubList.forEach((club, i) => {
    clubs.push(
      <Club
        key={`${i}`}
        club={club}
        type={type}
        showTimeLast={true} 
        onSelect={onSelectClub} 
      />
    );
  });
  return (
    <div className={`clubs ${isVisible ? "visible" : ""} ${type}`}>
      {clubs}
    </div>
  );
});
export default ClubsList;
