import React, { useCallback, useLayoutEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import Select from "react-select";
import moment from "moment";
import DatePicker from "react-date-picker";
import TimePicker from "react-99h-time-picker";
import ReactModal from "react-modal";

import useStores from "~/hooks";
import MultiSwitch from "../components/multiSwitch/MultiSwitch";
import { Preloader } from "~/components/common";
import KindCard from "../components/multiSwitch/KindCard";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";

const ActivityForm = observer(() => {
  const { rootStore, seasonsStore, accountStore } = useStores();
  const { type, manualActivityGap, isGapPending } = rootStore;
  const { currentSeasonId } = seasonsStore;


  useLayoutEffect(() => {
    rootStore.getManualActivityUploadGap();
  }, [rootStore]);

  const [isPending, setPending] = useState(false);
  const [isSucess, setSucess] = useState(false);
  const [kind, setKind] = useState();
  const [date, setDate] = useState("");
  const [start, setStart] = useState();
  const [finish, setFinish] = useState();
  const [isModalVisible, setModalVisible] = useState();
  const [distance, setDistance] = useState("");
  const [elevationGain, setElevationGain] = useState("");
  const [photo, setPhoto] = useState([]);
  // timezone - строка - зона пользователя из браузера (думаю достану сам из хедера запроса от пользователя)

  const photoSrc = useMemo(() => {
    const srcs = [];
    photo.forEach((file) => {
      if (file && file.value) {
        srcs.push(URL.createObjectURL(file.value));
      }
    });
    return srcs;
  }, [photo]);

  const kindMatch = {
    run: {
      type:  "Run",
      isGPS: true,
    },
    walk: {
      type:  "Walk",
      isGPS: true,
    },
    treadmill: {
      type:  "Run",
      isGPS: false,
    },
    ride: {
      type:  "Ride",
      isGPS: true,
    },
    various: {
      type:  "Various",
      isGPS: true,
    },
    bikestation: {
      type:  "VirtualRide",
      isGPS: true,
    },
    exercycle: {
      type:  "VirtualRide",
      isGPS: false,
    },
    skating: {
      type:  "Skating",
      isGPS: true,
    },
    ski: {
      type:  "NordicSki",
      isGPS: true,
    },
    swim: {
      type:  "Swim",
      isGPS: true,
    },
    pool: {
      type:  "Swim",
      isGPS: false,
    },
    mountainbike: {
      type:  "MountainBiking",
      isGPS: true,
    },
  };

  const handleInputChange = (value) => {
    setKind(value);
  };

  const onDistanceChange = (e) => {
    let value = e.target.value;
    if (value !== undefined && value.replace) {
      value = value.replace(/,|\.|-/g, "");
      setDistance(value);
    }
  };

  const onElevationGainChange = useCallback(
    (e) => {
      setElevationGain(e.target.value);
    },
    [setElevationGain]
  );

  const canSend = useMemo(() => {
    return (
      !!kind &&
      !!date &&
      !!start &&
      !!finish &&
      finish !== "00:00:00" &&
      !!distance &&
      distance > 0 &&
      elevationGain &&
      elevationGain >= 0 &&
      photo &&
      photo.length > 1
    );
  }, [kind, date, start, finish, distance, elevationGain, photo]);

  const onShowModal = useCallback(() => {
    if (canSend) {
      setModalVisible(true);
    }
  }, [canSend]);

  const onHideModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const onSend = async() => {
    if (canSend) {
      setPending(true);
      onHideModal();
      const kindObject = kindMatch[kind.value];
      const photoArray = [];
      photo.forEach((item) => {
        if (item.value) {
          photoArray.push(item.value);
        }
      });
      const data = {
        kind:             kindObject.type,
        date:             moment(date).format("DD.MM.YYYY"),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone, // eslint-disable-line
        start,
        duration:         finish,
        distance,
        elevationGain,
        isGPSTrackExists: kindObject.isGPS,
        photo:            photoArray,
      };
      try {
        await accountStore.sendActivity(data);
        setSucess(true);
        setKind();
        setDate("");
        setDistance("");
        setStart("");
        setFinish("00:00:00");
        setElevationGain("");
        setPhoto([]);
      } catch (error) {
        onShowModal();
        console.warn(error);
      }
      setPending(false);
    }
  };

  const onImageChange = (e) => {
    e.preventDefault();
    const newPhotos = [...photo];
    let found = false;
    const newItem = {
      name:  e.target.name,
      value: e.target.files[0],
    };
    newPhotos.forEach((item, i) => {
      if (item.name === e.target.name) {
        newPhotos[i] = newItem;
        found = true;
      }
    });
    if (!found) {
      newPhotos.push(newItem);
    }
    setPhoto(newPhotos);
  };

  const onDummie = useCallback(() => {}, []);

  const onDismiss = useCallback(() => {
    setSucess(false);
  }, []);

  if (isGapPending) {
    return <Preloader />;
  }

  return (
    <div className={`activity-form page ${type}`}>
      <MultiSwitch
        page="seasons"
        linkPrefix={"/"}
        id={currentSeasonId}
        noSport={true}
        noSeason={true}
        noMonth={true}
      />
      <div className="form-holder">
        <div className={`form-title ${type} gradient`}>
          {isSucess ? "Успешно" : "Внесите данные тренировки"}
        </div>
        {isPending && <Preloader />}
        {isSucess && (
          <div className="form-content">
            <div
              className={`color ${type}`}
              style={{ fontSize: "5rem", textAlign: "center" }}
            >
              <IoIosCheckmarkCircleOutline />
            </div>
            <div
              className="form-text"
              style={{ marginBottom: "1rem", textAlign: "center" }}
            >
              Тренировка добавлена!
            </div>
            <div className="fields-holder">
              <div onClick={onDismiss} className={`button gradient ${type}`}>
                Добавить еще
              </div>
            </div>
          </div>
        )}
        {!isPending && !isSucess && (
          <div className="form-content">
            <div className="form-text">
              Пожалуйста введите следующие данные:
            </div>
            <div className="fields-holder">
              <div className="field-holder">
                <div className="title">Дисциплина</div>
                <Select
                  placeholder="Дисциплина"
                  menuShouldScrollIntoView={false}
                  menuPosition="fixed"
                  captureMenuScroll={true}
                  isSearchable={false}
                  value={kind}
                  classNamePrefix={"kind-select"}
                  options={[
                    {
                      value: "run",
                      label: <KindCard onCardPress={onDummie} kind="run" />,
                    },
                    {
                      value: "walk",
                      label: <KindCard onCardPress={onDummie} kind="walk" />,
                    },
                    {
                      value: "treadmill",
                      label: (
                        <KindCard onCardPress={onDummie} kind="treadmill" />
                      ),
                    },
                    {
                      value: "various",
                      label: <KindCard onCardPress={onDummie} kind="various" />,
                    },
                    {
                      value: "ride",
                      label: <KindCard onCardPress={onDummie} kind="ride" />,
                    },
                    {
                      value: "bikestation",
                      label: (
                        <KindCard onCardPress={onDummie} kind="bikestation" />
                      ),
                    },
                    {
                      value: "exercycle",
                      label: (
                        <KindCard onCardPress={onDummie} kind="exercycle" />
                      ),
                    },
                    {
                      value: "skating",
                      label: <KindCard onCardPress={onDummie} kind="skating" />,
                    },
                    {
                      value: "ski",
                      label: <KindCard onCardPress={onDummie} kind="ski" />,
                    },
                    {
                      value: "swim",
                      label: <KindCard onCardPress={onDummie} kind="swim" />,
                    },
                    {
                      value: "pool",
                      label: <KindCard onCardPress={onDummie} kind="pool" />,
                    },
                    {
                      value: "mountainbike",
                      label: (
                        <KindCard onCardPress={onDummie} kind="mountainbike" />
                      ),
                    },
                  ]}
                  onChange={handleInputChange}
                />
              </div>
              <div className="field-holder">
                <div className="title">Дата</div>
                <DatePicker
                  format="dd.MM.yyyy"
                  locale="ru-RU"
                  // disabled={true}
                  // disableCalendar={true}
                  clearIcon={null}
                  onChange={setDate}
                  value={date}
                  minDate={new Date(new Date().setDate(new Date().getDate() - manualActivityGap))}
                  maxDate={new Date()}
                  className="field"
                  required={true}
                />
              </div>
              <div className="field-holder">
                <div className="title">Время начала тренировки</div>
                <TimePicker
                  secondAriaLabel="Second"
                  maxDetail={"second"}
                  format="HH:mm:ss"
                  locale="ru-RU"
                  onChange={setStart}
                  value={start}
                  className="field"
                  renderSecondHand={true}
                  required={true}
                  disableClock={true}
                />
              </div>
              <div className="field-holder">
                <div className="title">Продолжительность тренировки</div>
                <TimePicker
                  secondAriaLabel="Second"
                  format="NN:mm:ss"
                  locale="ru-RU"
                  maxDetail={"second"}
                  onChange={setFinish}
                  value={finish}
                  className="field"
                  required={true}
                  disableClock={true}
                />
              </div>
              <div className="field-holder">
                <div className="title">Расстояние в метрах</div>
                <input
                  id="distance"
                  name="distance"
                  placeholder="Введите расстояние"
                  onChange={onDistanceChange}
                  value={distance}
                  min={0}
                  type="number"
                  inputMode="numeric"
                  className="field"
                />
              </div>
              <div className="field-holder">
                <div className="title">Набор высоты в метрах</div>
                <input
                  id="elevationGain"
                  name="elevationGain"
                  placeholder="Введите высоту"
                  onChange={onElevationGainChange}
                  value={elevationGain}
                  min={0}
                  type="number"
                  inputMode="decimal"
                  className="field"
                />
              </div>
              <div className="field-holder">
                <div className="title">
                  <b>
                    Для подтверждения тренировки необходимо приложить 2
                    изображения:
                  </b>
                </div>
                <div className="title">
                  <i>
                    Для дисциплин с GPS (бег, ходьба, лыжи, велосипед,
                    велостанок, плавание в открытой воде)
                  </i>
                </div>
                <div className="title">
                  ПЕРВОЕ ИЗОБРАЖЕНИЕ (скриншот приложения провайдера, которым
                  пользуется Атлет) должно содержать расстояние и время движения
                </div>
                <div className="title">
                  ВТОРОЕ ИЗОБРАЖЕНИЕ (скриншот приложения провайдера, которым
                  пользуется Атлет) должно содержать GPS трек данной тренировки.
                </div>
                <div className="title">
                  В случае, если одно изображение объединяет все эти данные,
                  необходимо дважды загрузить это изображение.
                </div>
                <div className="title">
                  <i>
                    Для дисциплин без GPS (тренировки в зале: беговая дорожка,
                    бассейн, велотренажер) вам необходимо:
                  </i>
                </div>
                <div className="title">
                  Приложить ПЕРВОЕ ИЗОБРАЖЕНИЕ - фото часов или иного
                  устройства, подтверждающего тренировку
                </div>
                <div className="title">
                  ВТОРОЕ ИЗОБРАЖЕНИЕ - селфи в тренировочном зале
                </div>
                <div className="title">
                  В случае подозрительной активности, Атлет обязан предоставить
                  прямую ссылку на активность на сайте провайдера.
                </div>
                <div className="title">
                  <b>ТРЕНИРУЕМСЯ ЧЕСТНО!</b>
                </div>
              </div>
              <div className="field-holder">
                <div className="title">Фото</div>
                <input
                  placeholder="Добавьте файлы"
                  name="photo0"
                  onChange={onImageChange}
                  type="file"
                  accept="image/*"
                  className="field"
                />
                <input
                  placeholder="Добавьте файлы"
                  name="photo1"
                  onChange={onImageChange}
                  type="file"
                  accept="image/*"
                  className="field"
                />
                <input
                  placeholder="Добавьте файлы"
                  name="photo2"
                  onChange={onImageChange}
                  type="file"
                  accept="image/*"
                  className="field"
                />
                <input
                  placeholder="Добавьте файлы"
                  name="photo3"
                  onChange={onImageChange}
                  type="file"
                  accept="image/*"
                  className="field"
                />
              </div>
              <div
                onClick={onShowModal}
                className={`button gradient ${canSend && type}`}
              >
                Сохранить
              </div>
            </div>
            <ReactModal
              isOpen={isModalVisible}
              shouldCloseOnOverlayClick={true}
              onRequestClose={onHideModal}
              contentLabel="User select modal"
            >
              <div className={`activity-form main seasons-list ${type}`}>
                <div className={`modal-title gradient ${type}`}>
                  Проверка данных
                </div>
                <div className="form-holder">
                  <div className="form-content">
                    <div className={"modal-body"}>
                      <div className="field-holder">
                        <div className="title">Дисциплина</div>
                        {kind && (
                          <div className="kind-select__control">
                            <KindCard
                              onCardPress={onDummie}
                              kind={kind.value}
                            />
                          </div>
                        )}
                      </div>
                      <div className="field-holder">
                        <div className="title">Дата</div>
                        <DatePicker
                          format="dd.MM.yyyy"
                          locale="ru-RU"
                          disabled={true}
                          disableCalendar={true}
                          clearIcon={null}
                          onChange={setDate}
                          value={date}
                          minDate={new Date("2022-03-12")}
                          maxDate={new Date()}
                          className="field"
                          required={true}
                        />
                      </div>
                      <div className="field-holder">
                        <div className="title">Время начала тренировки</div>
                        <TimePicker
                          secondAriaLabel="Second"
                          maxDetail={"second"}
                          format="HH:mm:ss"
                          disabled={true}
                          locale="ru-RU"
                          onChange={setStart}
                          value={start}
                          className="field"
                          renderSecondHand={true}
                          required={true}
                          disableClock={true}
                        />
                      </div>
                      <div className="field-holder">
                        <div className="title">
                          Продолжительность тренировки
                        </div>
                        <TimePicker
                          secondAriaLabel="Second"
                          format="HH:mm:ss"
                          disabled={true}
                          locale="ru-RU"
                          maxDetail={"second"}
                          onChange={setFinish}
                          value={finish}
                          className="field"
                          required={true}
                          disableClock={true}
                        />
                      </div>
                      <div className="field-holder">
                        <div className="title">Расстояние в метрах</div>
                        <input
                          id="distance"
                          name="distance"
                          disabled={true}
                          placeholder="Введите расстояние"
                          onChange={onDistanceChange}
                          value={distance}
                          min={0}
                          type="number"
                          inputMode="decimal"
                          className="field"
                        />
                      </div>
                      <div className="field-holder">
                        <div className="title">Набор высоты в метрах</div>
                        <input
                          id="elevationGain"
                          name="elevationGain"
                          disabled={true}
                          placeholder="Введите высоту"
                          onChange={onElevationGainChange}
                          value={elevationGain}
                          min={0}
                          type="number"
                          inputMode="decimal"
                          className="field"
                        />
                      </div>
                      <div className="field-holder">
                        {photoSrc.map((src, i) => {
                          return (
                            <img
                              src={src}
                              key={`preview-${i}`}
                              alt="your image"
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="buttons">
                  <div className={"button gradient run"} onClick={onSend}>
                    Подтвердить
                  </div>
                  <div className={"button gradient ride"} onClick={onHideModal}>
                    Закрыть
                  </div>
                </div>
              </div>
            </ReactModal>
          </div>
        )}
      </div>
    </div>
  );
});

export default ActivityForm;
