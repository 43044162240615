import { observable, action, computed } from "mobx";
import Company from "~/models/Company";

export default class CompaniesStore {
  @observable
  companies = new Map();

  @observable
  rootStore = null;

  @observable
  pending = false;

  @observable
  companyForTop = null;

  constructor(store) {
    this.rootStore = store;
    this.api = this.rootStore.api;
  }

  @action
  async init() {
    this.setPending(true);
    const companies = await this.api.getCompanies();
    companies.forEach((companyData) => {
      this.createCompany(companyData);
    });
    this.setPending();
  }


  @action
  getCompany(id) {
    let company = this.companies.get(`${id}`);
    if (!company) {
      company = this.createCompany({ id });
      company.getInfo();
    }
    return company;
  }

  @action
  async fetchCompany(id) {
    const result = await this.api.getCompany(id);
    return result;
  }

  @action
  async fetchCompanyAthletes(id, season, sex) {
    const result = await this.api.fetchCompanyAthletes(id, season, sex[0].toUpperCase());
    return result;
  }

  @action
  async fetchCompanyAthletesForChallenge(companyId, challengeId) {
    const result = await this.api.fetchCompanyAthletesForChallenge(companyId, challengeId);
    return result;
  }

  @action
  createCompany(companyData) {
    let company = this.companies.get(`${companyData.id}`);
    if (company) {
      company.setInfo(companyData);
    } else {
      company = new Company(companyData, this);
      this.companies.set(`${company.id}`, company);  
      company = this.companies.get(`${company.id}`);
    }
    return company;
  }

  @action
  setPending(pending = false) {
    this.pending = pending;
  }

  @action
  setTopCompany(companyId = null) {
    this.companyForTop = this.companies.get(`${companyId}`) || null;
  }

  @computed
  get isPending() {
    return this.pending;
  }

  @computed
  get topCompany() {
    return this.companyForTop;
  }

  @computed
  get topCompanyId() {
    return (this.topCompany && this.topCompany.id) || null;
  }

  @computed
  get companiesList() {
    const companies = [];
    this.companies.forEach((company) => {
      if (company.isPublic) {
        companies.push(company);
      }
    });
    return companies;
  }

  @computed
  get fullCompaniesList() {
    const companies = [];
    this.companies.forEach((company) => {
      companies.push(company);
    });
    return companies;
  }
}
