import React from "react";
import RootStore from "../stores/RootStore";

const rootStore = new RootStore();
const context = React.createContext({
  rootStore,
  accountStore:    rootStore.accountStore,
  athletesStore:   rootStore.athletesStore,
  seasonsStore:    rootStore.seasonsStore,
  challengesStore: rootStore.challengesStore,
  companiesStore:  rootStore.companiesStore,
  newsStore:       rootStore.newsStore,
});

export default context;
export { context };
