import React, { useEffect } from "react";
import { observer } from "mobx-react";

import { IoIosCloseCircle, IoIosPeople } from "react-icons/io";

import useStores from "../hooks";

import MultiSwitch from "~/components/multiSwitch";
import { Top } from "~/components/common";


const Team = observer(({ id }) => {
  const { rootStore, companiesStore, seasonsStore } = useStores();
  const { currentSeasonId } = seasonsStore;
  const company = companiesStore.getCompany(`${id}`);
  const { type, sex } = rootStore;

  if (!company) {
    return (
      <div className={`team page ${type}`}>
        <div className="page-header"><IoIosCloseCircle /> Нет команды</div>
      </div>
    );  
  }

  useEffect(() => {
    company.getAthletes(currentSeasonId, sex);
  }, [currentSeasonId, sex]);

  const { isPendingAthletes, athleteIds } = company;

  return (
    <div className={`team page ${type}`}>
      <MultiSwitch
        page="seasons" 
        linkPrefix={"/"}
        id={currentSeasonId}
        noSport={true}
        noMonth={true}
        companyId={id}
      />
      <div className="page-header"><IoIosPeople />{company.name}</div>
      <Top
        ids={athleteIds}
        noNumbers={true}
        starsOnly={true}
        linkPrefix="/"
        isPending={isPendingAthletes} 
        isPagePending={false} 
      />
    </div>
  );
});

export default Team;
