import { observable, action, computed } from "mobx";

export default class Club {
  @observable
  store = null;
  @observable
  intId = null;

  @observable
  logo = null;
  @observable
  name = null;
  @observable
  cssUrl = null;

  constructor(data, store) {
    this.intId = data.id;
    this.setInfo(data);

    this.store = store;
  }

  @action
  setInfo(data) {
    if (data.logo) {
      this.logo = data.logo;
    }
    this.name = data.name;
    this.cssUrl = data.cssUrl;
  }

  @computed
  get id() {
    return `${this.intId}`;
  }

  @computed
  get isCurrent() {
    return this.id === this.store.currentClubId;
  }

  @computed
  get title() {
    return this.name;
  }
}
