import React, { useCallback, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
} from "react-image-crop";

import useStores from "~/hooks";

import "react-image-crop/dist/ReactCrop.css";
import { IoMdImage } from "react-icons/io";
import { Preloader } from "~/components/common";

const EditPhoto = observer(({ onClose }) => {
  const {
    accountStore,
    rootStore,
  } = useStores();  
  const { type } = rootStore;
  const { isPendingPhoto, meId } = accountStore;

  const [imgSrc, setImgSrc] = useState("");
  const imgRef = useRef(null);
  const inputRef = useRef(null);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();

  const canvasPreview = (
    image,
    canvas,
    crop,
    scale = 1,
  ) => {
    const ctx = canvas.getContext("2d");
  
    if (!ctx) {
      throw new Error("No 2d context");
    }
  
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    // devicePixelRatio slightly increases sharpness on retina devices
    // at the expense of slightly slower render times and needing to
    // size the image back down if you want to download/upload and be
    // true to the images natural size.

    // const pixelRatio = window.devicePixelRatio;
    const pixelRatio = 1;
  
    canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio);
  
    ctx.scale(pixelRatio, pixelRatio);
    ctx.imageSmoothingQuality = "high";
  
    const cropX = crop.x * scaleX;
    const cropY = crop.y * scaleY;
  
    const centerX = image.naturalWidth / 2;
    const centerY = image.naturalHeight / 2;
  
    ctx.save();
  
    // 5) Move the crop origin to the canvas origin (0,0)
    ctx.translate(-cropX, -cropY);
    // 4) Move the origin to the center of the original position
    ctx.translate(centerX, centerY);
    // 2) Scale the image
    ctx.scale(scale, scale);
    // 1) Move the center of the image to the origin (0,0)
    ctx.translate(-centerX, -centerY);
    ctx.drawImage(
      image,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight,
    );
  
    ctx.restore();
  };
  
  const canvas = useRef(document.createElement("canvas"));

  useEffect(() => {
    if (imgRef.current && canvas.current && completedCrop) {
      canvasPreview(imgRef.current, canvas.current, completedCrop);
    }
  }, [imgRef && imgRef.current, canvas.current, completedCrop]);
  // // Returns an image source you should set to state and pass
  // // `{previewSrc && <img alt="Crop preview" src={previewSrc} />}`
  const onOk = async() => {
    if (completedCrop) {
      // document.getElementById("upload-edit").appendChild(canvas.current);
      try {
        const blob = await new Promise((resolve) => {
          return canvas.current.toBlob(resolve, "image/webp", 0.75);
        });
        
        
        const file = new File([blob], `avatar${meId}}.webp`, { type: "image/webp" });
        
        await accountStore.sendPhoto({ photo: file });
        onClose();
      } catch (error) {
        console.warn(error);
      }
    }
  };
  
  const onFile = useCallback(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  }, [inputRef && inputRef.current]);

  const onSelectFile = useCallback((e) => {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        return setImgSrc(reader.result?.toString() || "");
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  }, [setImgSrc]);

  const onImageLoad = useCallback((e) => {
    const { naturalWidth, naturalHeight } = e.currentTarget;
    const crop = centerCrop(
      makeAspectCrop(
        {
          unit:  "%",
          width: 90,
        },
        1,
        naturalWidth,
        naturalHeight
      ),
      naturalWidth,
      naturalHeight
    );
  
    setCrop(crop);
  }, [setCrop]);

  if (isPendingPhoto) {
    return (
      <div id="upload-edit" className={"photo-upload-edit"}>
        <Preloader />
      </div>

    );
  }

  return (
    <div id="upload-edit" className={"photo-upload-edit"}>
      <div className="file-input-holder">
        <input
          ref={inputRef} 
          type="file" 
          accept="image/*"
          onChange={onSelectFile}
        />
      </div>
      <div className="photo-edit-holder">
        {!!imgSrc && (
          <ReactCrop
            style={{ maxHeight: "70%" }}
            crop={crop}
            circularCrop={true}
            onChange={(crop) => {
              return setCrop(crop);
            }}
            onComplete={(c) => {
              return setCompletedCrop(c);
            }}
            aspect={1}
          >
            <img
              ref={imgRef}
              alt="Crop me"
              src={imgSrc}
              onLoad={onImageLoad}
            />
          </ReactCrop>
        )}
        {!imgSrc && (
          <div onClick={onFile} className={`input-toggle color ${type}`}>
            <IoMdImage />
          </div>
        )}
      </div>
      <div className="buttons">
        <div className={`button gradient ${completedCrop ? "run" : ""}`} onClick={onOk}>
          Сохранить
        </div>
        <div className={"button gradient ride"} onClick={onClose}>
          Отмена
        </div>
      </div>
    </div>
  );
});

export default EditPhoto;
