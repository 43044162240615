import React from "react";

const Competitions = () => {
  return (
    <div className="competitions page">
      Competitions
    </div>
  );
};

export default Competitions;
