import React, { useEffect } from "react";
import { observer } from "mobx-react";
import Select from "react-select";

import { IoIosClose } from "react-icons/io";

import useStores from "~/hooks";

import { Preloader, Top } from "~/components/common";
import MultiSwitch from "~/components/multiSwitch";
import { NewsWidget } from "~/components/news";

const TopPage = observer(() => {
  const { rootStore, seasonsStore } = useStores();
  const { kind, sex } = rootStore;

  const { currentSeason, currentSeasonId } = seasonsStore;
  const { currentMonth } = currentSeason;
  
  if (!currentSeason) {
    return (
      <div className="challenges page">
        <MultiSwitch
          page="seasons" 
          id={currentSeasonId}
        />
        <div className="page-warning"><IoIosClose /> Нет доступных сезонов</div>
      </div>
    );
  }

  const { 
    fullCompaniesList, 
    topCompany, 
    topCompanyId, 
    isCompaniesPending, 
  } = currentSeason;

  useEffect(() => {
    const from = currentMonth.from;
    const to = currentMonth.to;
    seasonsStore.getFullTops(sex, from, to);
  }, [kind, currentSeasonId, topCompanyId, sex, currentMonth]);

  const { currentFullTop, isPending, isPagePending, hasNextPage } = currentSeason;

  if (isPending) {
    return (
      <div className="challenges page">
        <MultiSwitch
          page="seasons" 
          id={currentSeasonId}
        />
        <Preloader />
      </div>
    );
  }

  if (!currentFullTop) {
    return (
      <div className="challenges page">
        <MultiSwitch
          page="seasons" 
          id={currentSeasonId}
        />
        <div className="page-warning"><IoIosClose /> Нет информации о рейтингах</div>
      </div>
    );
  }

  const getNextPage = () => {
    const from = currentMonth.from;
    const to = currentMonth.to;
    seasonsStore.getFullTopsNextPage(sex, from, to);
  };

  const onChange = (value) => {
    currentSeason.setTopCompany(value && value.id);
  };

  const ids = currentFullTop[sex];
  return (
    <div className="top-page page">
      <MultiSwitch
        page="seasons"
        linkPrefix={"/"}
        id={currentSeasonId}
      />
      <div className="select-holder">
        <Select
          isLoading={isCompaniesPending}
          className="select-comp"
          classNamePrefix="select-comp"
          value={topCompany} 
          onChange={onChange} 
          isClearable={true}
          placeholder="Выберите компанию"
          options={fullCompaniesList}
        />
      </div>
      <NewsWidget limit={3} />
      <Top 
        starsOnly={true}
        linkPrefix="/"
        ids={ids} 
        isPending={isPending} 
        isPagePending={isPagePending} 
        getNextPage={getNextPage}
        hasNextPage={hasNextPage}
      />
    </div>
  );
});

export default TopPage;
