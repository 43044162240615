import { computed, observable } from "mobx";
import moment from "moment";

export default class Archievement {
  @observable
  id = null;
  @observable
  athlete = null;
  @observable
  rootStore = null;
  @observable
  challenge = null;
  @observable
  datetime = null;
  @observable
  icon = null;
  @observable
  registrationId = null;

  constructor(data, athlete) {
    this.id = data.id;
    this.athlete = athlete;
    this.rootStore = this.athlete.rootStore;
    this.challenge = data.challenge;
    this.datetime = data.datetime;
    this.icon = data.icon;
    this.registrationId = data.registrationId;
  }

  @computed
  get challengeId() {
    return this.challenge.id;
  }
  @computed
  get athleteId() {
    return this.athlete.id;
  }
  @computed
  get kind() {
    return this.challenge.discipline;
  }

  @computed
  get type() {
    return this.rootStore.getTypeByKind(this.kind);
  }

  @computed
  get imageURL() {
    return this.icon || null;
  }

  @computed
  get date() {
    return moment(this.datetime);
  }
}
